@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif !important;
  /* font-family: "Poppins", SemiBold; */
}

:root {
  --white: #fff;
  --white1: #ffffff;
  --black: #000;
  --black1: #00000029;
  --black2: #191919;
  --green-shade: #9cd947;
  --green1: #4b9a24;
  --green2: #479723;
  --green3: #64ad2f;
  --light-gray: #dcdcdc;
  --light-gray1: #ececec;
  --light-gray2: #edf2f4;
  --themeclr: #5ba62b;
  --dark-blue1: #1c263a;
  --bink: #dd3d4c;
  --dark-red: #ff1717;
  --lite-blue: #00a2e8;
}

.lh-30 {
  line-height: 30px;
}

.lh-29 {
  line-height: 29px;
}

.lh-28 {
  line-height: 28px;
}

.f-11 {
  font-size: 11px;
}

.f-12 {
  font-size: 12px;
}

.f-13 {
  font-size: 13px;
}

.f-14 {
  font-size: 14px;
}

.f-15 {
  font-size: 15px;
}

.f-16 {
  font-size: 16px;
}

.fw-400 {
  font-weight: 400;
}

.fw-700 {
  font-weight: 700;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-800 {
  font-weight: 800;
}

.mw_200 {
  min-width: 200px;
}

.cursor-pointer {
  cursor: pointer;
}
.showNow_btn {
  background-color: var(--white) !important;
  border-radius: 4px !important;
  color: var(--black) !important;
  border: 1px solid var(--white) !important;
  outline: none;
  font-weight: 500 !important;
  box-shadow: none !important;
  font-family: "Oswald", sans-serif !important;
}

.showNow_btn:hover {
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgb(197, 193, 193, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #fff;
  background-size: 200% 500%;
  background-position-x: 180%;
  animation: 0.8s loading ease-in-out;
}

@keyframes loading {
  to {
    background-position-x: -30%;
  }
}

.cmn-linearBtn {
  background: transparent
    linear-gradient(286deg, var(--green1) 0%, var(--green-shade) 100%) 0% 0%
    no-repeat padding-box;
  color: var(--white) !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 4px !important;
}

.cmn-linearBtn:hover {
  background-color: transparent !important;
  background: transparent
    linear-gradient(286deg, var(--green-shade) 0%, var(--green1) 100%) 0% 0%
    no-repeat padding-box;
  color: var(--white) !important;
}

.cmn_linearBtn {
  background: transparent
    linear-gradient(83deg, var(--green2) 0%, var(--green-shade) 100%) 0% 0%
    no-repeat padding-box;
  color: var(--white);
  outline: none;
  box-shadow: none;
  border: 1px solid var(--green-shade) !important;
}

.cmn_linearBtn:hover {
  background: transparent
    linear-gradient(83deg, var(--green-shade) 0%, var(--green2) 100%);
  border: 1px solid var(--green-shade) !important;
}

.cmn_grayBtn {
  background: var(--light-gray1) !important;
  color: var(--black) !important;
  box-shadow: none;
  border: 1px solid var(--light-gray1) !important;
}

.cmn_grayBtn:hover {
  background: #e9dada !important;
  border: 1px solid #e9e9e9 !important;
}

.cmn_greenBtn {
  background: var(--green2) !important;
  color: var(--white) !important;
  box-shadow: none;
  border: 1px solid var(--green2) !important;
}

.cmn_greenBtn:hover {
  background: var(--green-shade) !important;
  border: 1px solid var(--green-shade) !important;
}

.cmn_liteBlueBtn {
  background-color: var(--lite-blue) !important;
  color: var(--white) !important;
  border: 1px solid var(--lite-blue) !important;
  box-shadow: none;
  outline: none;
}

.cmn_liteBlueBtn:hover {
  background-color: #2fb0e8 !important;
  border-color: #2fb0e8 !important;
}

.cmn_Green_btn {
  background: var(--themeclr) !important;
  color: var(--white) !important;
  box-shadow: none;
  outline: none;
  border: 1px solid var(--themeclr) !important;
}

.cmn_Green_btn:hover {
  background: #61c223 !important;
  border-color: #61c223 !important;
}

/* .cmn_GrayBtn {
  background: var(--grayClr) no-repeat padding-box;
  color: var(--black);
  outline: none;
  box-shadow: none;
  border: 1px solid var(--grayClr) !important;
  width: 150px;
} */

/* .addresspage .cmn_cancel:hover {
  background: var(--black) !important;
  border: 1px solid var(--black) !important;
  color: var(--white);
} */

/* header */

.header {
  padding-bottom: 43px;
}

.header .custom-nav {
  padding: 0px;
  flex-direction: column;
}

.header .logo {
  height: 30px;
}

.header .search_sec {
  background-color: var(--light-gray2);
  border-radius: 20px;
  height: 35px;
  width: 500px;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.header .header_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.allProduct_listsDrop button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0px;
  box-shadow: 0px 0px 8px var(--black1);
  background-color: var(--white) !important;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  margin-top: 1px;
}

.allProduct_listsDrop button img {
  height: 15px;
  width: 15px;
}

.allProduct_listsDrop button::after {
  display: none;
}

.allProduct_listsDrop .dropdown-menu {
  background-color: var(--light-gray2) !important;
  border-radius: 15px;
}

.allProduct_listsDrop .dropdown-item {
  color: var(--dark-blue1);
  font-size: 13px;
}

.allProduct_listsDrop .dropdown-item:first-child:hover {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.allProduct_listsDrop .dropdown-item:active {
  background-color: #f8f9fa !important;
  color: var(--dark-blue1);
}

.header .search_sec .all-txt {
  color: var(--dark-blue1);
  font-size: 13px;
  padding-left: 15px;
  padding-right: 15px;
}

.search_product.form-control {
  padding: 0px;
  padding-left: 10px;
  width: 100%;
  background-color: transparent !important;
  border: none;
  outline: none;
  font-size: 13px;
  color: #7b848b;
  box-shadow: none !important;
  height: 100%;
}

.search_product.form-control::placeholder {
  color: #7b848b;
}

.header .searchBtn {
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0px;
  box-shadow: 0px 0px 8px var(--black1);
  background-color: var(--black2) !important;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
  margin-top: 1px;
}

.header .searchBtn img {
  height: 15px;
  width: 15px;
}

.header .nav_customContainer {
  max-width: 100%;
  align-items: start;
  flex-direction: column;
  padding: 0px;
}

.connectType_sec {
  display: flex;
  align-items: center;
  gap: 10px;
}

.connectType_sec .network_btn {
  border: none;
  outline: none;
  border-radius: 20px !important;
  padding: 4px 8px !important;
  width: 85px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.connectType_sec .icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.connectType_sec .name {
  font-size: 12px;
}

.wallet_icon_hvr .walletIcon {
  width: 25px;
  height: 25px;
}

.wallet_icon_hvr {
  position: relative;
  cursor: pointer;
}

.wallet_dropContent {
  display: none;
  position: absolute;
  background-color: var(--white1);
  min-width: 200px;
  box-shadow: 0px 0px 2px var(--black1);
  padding: 12px 16px;
  z-index: 1;
  border-radius: 5px;
  right: 0px;
  top: 36px;
}

.wallet_dropContent .uparrow {
  position: absolute;
  top: -10px;
  right: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.wallet_dropContent .uparrow img {
  width: 21px;
  height: 10px;
  margin-right: 5px;
}

.wallet_icon_hvr:hover .wallet_dropContent {
  display: block;
}

.wallet_icon_hvr .sign-InUp-Btn {
  background: transparent !important;
  border: none;
  box-shadow: none !important;
  outline: none;
  border-radius: 4px;
  padding: 6px 8px !important;
  width: 100%;
  text-align: center;
  font-size: 13px !important;
  color: #4c9a24;
}

.wallet_icon_hvr .sign-InUp-Btn:hover {
  background: transparent
    linear-gradient(286deg, var(--green1) 0%, var(--green-shade) 100%) 0% 0%
    no-repeat padding-box !important;
  color: var(--white);
}

.wallet_icon_hvr .sign-InUp-Btn.active {
  background: transparent
    linear-gradient(286deg, var(--green1) 0%, var(--green-shade) 100%) 0% 0%
    no-repeat padding-box !important;
  color: var(--white);
}

.wallet_icon_hvr .dashboard_btn {
  border-radius: 4px;
  padding: 6px 8px !important;
  width: 100%;
  text-align: center;
  font-size: 13px !important;
  border: none !important;
}

.wallet_icon_hvr .my-info .icons {
  width: 17px;
}

.wallet_icon_hvr .my-info .txt {
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.wallet_icon_hvr .my-info .txt:hover {
  color: var(--green3);
}

.shop_icon_hvr {
  position: relative;
  cursor: pointer;
}

.shop_icon_hvr .dropContent {
  display: none;
  position: absolute;
  background-color: var(--white1);
  min-width: 280px;
  box-shadow: 0px 0px 2px var(--black1);
  padding: 12px 16px;
  z-index: 1;
  border-radius: 5px;
  right: 0px;
  top: 36px;
}

.shop_icon_hvr .dropContent .uparrow {
  position: absolute;
  top: -10px;
  right: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.shop_icon_hvr .dropContent .uparrow img {
  width: 21px;
  height: 10px;
  margin-right: 5px;
}

.shop_icon_hvr:hover > .dropContent {
  display: block;
}

.shop_lottie svg {
  height: 28px !important;
  transform: unset !important;
}

.shop_icon {
  width: 25px;
  height: 25px;
}

.navShop_details .cus_hr {
  background-color: var(--black1);
  margin: 10px 0px;
}

.navShop_details .total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
}

.navShop_details .total .txt {
  color: var(--black);
}

.navShop_details .total .val {
  color: var(--bink);
}

.navShop_details .viewcart_btn {
  background-color: #f0f1f3 !important;
  color: var(--black) !important;
  font-size: 13px;
  padding: 7px 20px !important;
  width: 100%;
  border: 1px solid #f0f1f3;
  outline: none;
  box-shadow: none;
  border-radius: 4px !important;
}

.navShop_details .viewcart_btn:hover {
  border: 1px solid #f0f1f3;
  background-color: transparent !important;
}

.navShop_details .checkout_btn {
  background: transparent
    linear-gradient(180deg, var(--green2) 0%, var(--green-shade) 100%) 0% 0%
    no-repeat padding-box;
  color: var(--white) !important;
  font-size: 13px;
  padding: 7px 20px !important;
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 4px !important;
}

.navShop_details .checkout_btn:hover {
  background: transparent
    linear-gradient(180deg, var(--green-shade) 0%, var(--green2) 100%) 0% 0%
    no-repeat padding-box;
}

.navShop_details .watch_img {
  height: 40px;
}

.navShop_details .products_list {
  display: flex;
  align-items: start;
  margin-bottom: 15px;
  justify-content: space-between;
}

.navShop_details .products_list .prod_name {
  color: var(--black);
  font-size: 14px;
  font-weight: 500;
}

.navShop_details .products_list .prod_price {
  color: var(--black);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.navShop_details .products_list .prod_img {
  height: 18px;
  margin-left: 4px;
  margin-right: 4px;
}

.navShop_details .products_list .close_icon {
  color: #768b9e;
  font-size: 17px;
  cursor: pointer;
  transition: 0.2s all;
}

.navShop_details .products_list .close_icon:hover {
  transform: scale(1.2);
}

.navShop_details .products_list .sign_img {
  height: 5px;
  margin-left: 6px;
  margin-right: 6px;
}

.navShop_details .allProd {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

.header .navbar-toggler-icon {
  filter: brightness(0) invert(1);
}

.header .fst_nav {
  width: 100%;
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: var(--black2);
  z-index: 2;
  border-radius: 0px;
  transition: 1s all ease-in-out;
}

.header .fst_nav.radius {
  border-radius: 0px 0px 25px 25px;
  /* transition: 1s all ease-in-out;  */
}

.header .snd_nav {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 100%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  background-color: var(--black2);
  border-radius: 0px 0px 25px 25px;
  border-top: 1px solid #7f7070;
  z-index: 1;
}

.header .snd_nav.show {
  margin-top: 0px;
  transition: 1s all ease-in-out;
}

.header .snd_nav.hide {
  margin-top: -50px;
  transition: 1s all ease-in-out;
}

.header .snd_nav .fst_sec {
  display: flex;
  align-items: center;
  gap: 13px;
}

.header .nav_hr {
  margin: 0px;
  background-color: #7f7070;
  height: 2px;
  width: 100%;
}

.header .categoryBtn {
  background: transparent
    linear-gradient(106deg, #4a9a25 0%, var(--green3) 84%, #80c33b 100%) 0% 0%
    no-repeat padding-box;
  border: none;
  box-shadow: none !important;
  outline: none;
  border-radius: 50px;
  font-size: 11px;
  padding: 4px 13px !important;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
}

.header .categoryBtn .categoryFilter_img {
  height: 13px;
}

.header .hotDealzBtn {
  background-color: var(--dark-red) !important;
  border: none;
  box-shadow: none !important;
  outline: none;
  border-radius: 50px;
  font-size: 11px;
  padding: 4px 13px !important;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
}

.header .hotDealzBtn .hotDealz_img {
  height: 13px;
}

.cmnAll_dropdown .dropdown-menu {
  background-color: var(--light-gray2) !important;
  border-radius: 15px;
}

.cmnAll_dropdown .dropdown-item {
  color: var(--dark-blue1);
  font-size: 13px;
}

.cmnAll_dropdown .dropdown-item:first-child:hover {
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
  border-radius: 8px;
}

.cmnAll_dropdown .dropdown-item:active {
  background-color: #f8f9fa !important;
  color: var(--dark-blue1);
}

.sell_crypto_drop button {
  padding: 0px;
  box-shadow: none;
  background-color: transparent !important;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  gap: 5px;
}

.sell_crypto_drop button::after {
  display: none;
}

.sell_crypto_drop button svg {
  font-size: 13px;
}

.services_drop button {
  padding: 0px;
  box-shadow: none;
  background-color: transparent !important;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  gap: 5px;
}

.services_drop button::after {
  display: none;
}

.services_drop button svg {
  font-size: 13px;
}

.header .snd_nav .customNavlink {
  color: var(--white);
  font-size: 12px;
  text-decoration: none;
  text-wrap: nowrap;
}

.header .snd_nav .customNavlink:hover {
  color: var(--green3);
}

.header .snd_nav .snd_sec {
  display: flex;
  align-items: center;
  gap: 20px;
}

.langInr_drop_hvr {
  position: relative;
  cursor: pointer;
}

.langInr_drop_hvr .langInr_drop_content {
  display: none;
  position: absolute;
  background-color: var(--white1);
  min-width: 260px;
  box-shadow: 0px 0px 2px var(--black1);
  padding: 12px 16px;
  z-index: 1;
  border-radius: 5px;
  right: 0px;
  padding: 0px;
  outline: none;
  top: 35px;
  /* margin-top: 15px; */
  /* max-height: 500px; */
  /* overflow-y: auto; */
}

.langInr_drop_hvr .langInr_drop_content .uparrow {
  position: absolute;
  top: -10px;
  right: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.langInr_drop_hvr .langInr_drop_content .uparrow img {
  width: 21px;
  height: 10px;
  margin-right: 5px;
}

.langInr_drop_hvr:hover .langInr_drop_content {
  display: block;
}

.langInr_drop_hvr .heading_txt {
  color: var(--white);
  font-size: 12px;
  font-weight: 400;
}

.langInr_drop_hvr .title_txt {
  color: var(--black);
  font-size: 14px;
  font-weight: 500;
}

.language_drop button {
  padding: 5px 13px;
  box-shadow: none;
  background-color: transparent !important;
  border: 1px solid #dcdcdd !important;
  border-radius: 6px;
  width: 100%;
  color: var(--black) !important;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  gap: 5px;
}

.language_drop .dropdown-menu {
  width: 100%;
}

.language_drop button:after {
  display: none;
}

.langInr_drop_hvr .sec {
  padding: 14px 14px 0px 14px;
}

.langInr_drop_hvr .lang_dropHr {
  margin: 10px 0px 0px;
}

.langInr_drop_hvr .network_btn {
  border-radius: 20px !important;
  padding: 4px 8px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.langInr_drop_hvr .icon {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.langInr_drop_hvr .name {
  font-size: 10px;
}

.langInr_drop_hvr .down_arrow {
  font-size: 12px;
}

.chooseCurrency_Filter {
  border: 1px solid var(--light-gray);
  border-radius: 5px;
}

.choose_allCurrency {
  max-height: 350px;
  overflow-y: auto;
}

.chooseCurrency_Filter .title_txt {
  color: var(--white);
  font-size: 10px;
  background-color: var(--black2);
  padding: 8px 20px;
  text-align: center;
  border-radius: 4px;
}

.chooseCurrency_Filter .content {
  padding: 13px 10px;
}

.chooseCurrency_Filter .custom_search {
  position: relative;
}

.chooseCurrency_Filter .custom_search input {
  background-color: var(--light-gray1);
  color: #8d8d8d;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 7px 15px 7px 33px;
  border-radius: 20px;
  font-size: 10px;
  width: 100%;
}

.chooseCurrency_Filter .custom_search .search_iocn {
  position: absolute;
  top: 5px;
  left: 8px;
  font-size: 19px;
  color: #565353;
}

.chooseCurrency_Filter .search_items {
  padding-top: 15px;
  max-height: 100px;
  overflow-y: auto;
  /* padding-bottom: 15px; */
}

.chooseCurrency_Filter .search_items_list {
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
}

.chooseCurrency_Filter .search_items_list:last-child {
  padding-bottom: 0px;
}

.chooseCurrency_Filter .search_items_list .search_logos {
  height: 17px;
  width: 22px;
  object-fit: contain;
}

.chooseCurrency_Filter .search_items_list .search_name {
  font-size: 10px;
  font-weight: 500;
}

.chooseCurrency_Filter .search_items_list .logoName {
  display: flex;
  align-items: center;
  gap: 12px;
}

.chooseCurrency_Filter .search_items_list .search_checked {
  height: 15px;
}

.header .snd_nav .trd_sec {
  display: flex;
  align-items: center;
}

.header .snd_nav .shipTo_Btn {
  border: none;
  background-color: transparent !important;
  box-shadow: none !important;
  outline: none;
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  gap: 8px;
}

.header .snd_nav .vertical_bar2 {
  margin-right: 12px;
  margin-left: 12px;
  height: 18px;
}

.header .snd_nav .shipTo_Btn .countryLogo {
  height: 14px;
}

.header .snd_nav .shipTo_Btn .downArrow {
  font-size: 16px;
}

.header .snd_nav .navHide_lottie svg {
  width: 35px !important;
  height: 25px !important;
}

.header .snd_nav .navHide_lottie {
  cursor: pointer;
}

.navShowHide_loader {
  cursor: pointer;
}

.navShowHide_loader svg {
  width: 40px !important;
  height: 35px !important;
  margin-top: 6px;
  position: fixed;
  top: 64px;
  left: 50%;
  z-index: 5;
  transform: translateX(-50%) !important;
}

.categories_hvr_drop {
  position: relative;
  cursor: pointer;
}

.categories_drop_content {
  display: none;
  position: absolute;
  background-color: var(--white1);
  min-width: 300px;
  box-shadow: 0px 0px 2px var(--black1);
  padding: 0px;
  z-index: 1;
  border-radius: 5px;
  left: -30px;
  padding-left: 25px;
  top: 35px;
  /* margin-top: 5px; */
}

.categories_drop_content .uparrow {
  position: absolute;
  top: -10px;
  right: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.categories_drop_content .uparrow img {
  width: 21px;
  height: 10px;
  margin-left: 70px;
}

.categories_hvr_drop:hover .categories_drop_content {
  display: block;
}

.categories_drop_content .title_txt {
  color: var(--black);
  font-weight: 500;
  font-size: 14px;
  padding: 15px 15px 10px;
}

.categories_drop_content .categories_list .item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.categories_drop_content .categories_list .item:last-child {
  margin-bottom: 0px;
}

.categories_drop_content .categories_list .cat_name {
  color: var(--black);
  font-weight: 500;
  font-size: 12px;
}

.categories_list .item .cat_img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.categories_drop_content .categories_list .item:hover {
  background-color: #e8f0e3;
}

.categories_drop_content .top_categories .categories_list .item::before {
  content: "";
  width: 4px;
  height: 42px;
  background-color: transparent;
}

.categories_drop_content .top_categories .categories_list .item:hover::before {
  content: "";
  width: 4px;
  height: 42px;
  background-color: #5da82c;
}

.categories_drop_content .more_categories .categories_list .item::before {
  content: "";
  width: 4px;
  height: 30px;
  background-color: transparent;
}

.categories_drop_content .more_categories .categories_list .item:hover::before {
  content: "";
  width: 4px;
  height: 30px;
  background-color: #5da82c;
}

.categories_drop_content .trending_search {
  width: 17px;
  height: 17px;
}

.top_categories .categories_list .items_List {
  max-height: 300px;
  overflow-y: auto;
}

.more_categories .categories_list {
  max-height: 200px;
  overflow-y: auto;
}

.categories_list {
  position: relative;
}

.top_categories .cat_list_items {
  /* position: relative; */
  cursor: pointer;
}

.top_categories .categories_subContent_hvr {
  display: none;
  position: absolute;
  background-color: var(--white1);
  /* min-width: 100%; */
  box-shadow: 0px 0px 2px var(--black1);
  padding: 0px;
  z-index: 1;
  border-radius: 5px;
  left: 275px;
  top: -35px;
  padding: 20px;

  width: 900px;
}

.top_categories .cat_list_items:hover .categories_subContent_hvr {
  display: block;
}

.categories_subContent_hvr .title {
  color: var(--black);
  font-weight: 500;
  font-size: 14px;
  /* padding: 15px 15px 10px; */
}

.categories_subContent_hvr .sub_prod {
  font-size: 12px;
}

.categories_subContent_hvr .see-all {
  color: var(--green2);
  font-size: 13px;
  text-decoration: none;
}

.categories_subContent_hvr .see-all:hover {
  text-decoration: underline;
}

.top_categories .categories_subContent_hvr .details {
  display: flex;
  gap: 35px;
  flex-wrap: wrap;
}

/* Footer */

.footer {
  /* padding-top: 40px; */
  /* padding-bottom: 40px; */
  background-color: var(--black);
  color: var(--white);
}

.footer .pre_footer {
  padding-top: 40px;
  padding-bottom: 40px;
}

.footer .content_sec .heading_txt {
  font-size: 17px;
  color: var(--green2);
  font-weight: 500;
}

.footer .content_sec .sub_content {
  margin-top: 20px;
}

.footer .content_sec .sub_content .txt {
  font-size: 15px;
  color: var(--white);
  text-decoration: none;
  display: block;
  margin-bottom: 15px !important;
}

.footer .content_sec .sub_content .txt:last-child {
  margin-bottom: 0px !important;
}

.footer .content_sec .sub_content .txt:hover {
  color: var(--green2);
}

.footer .content_sec {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  justify-content: space-between;
}

.footer .cus_hr {
  background-color: #585858;
  margin: 0px;
}

.footer .post_footer .sub_contents {
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer .content_secs .sub_contents .txt {
  font-size: 15px;
  color: var(--white);
  text-decoration: none;
  display: block;
}

.footer .content_secs .sub_contents .txt:hover {
  color: var(--green2);
}

.footer .post_footer {
  padding-top: 25px;
  padding-bottom: 25px;
}

.footer .post_footer .copyRight {
  color: var(--green2);
}

.footer .social_icons {
  display: flex;
  align-items: center;
  gap: 13px;
}

.footer .social_icons .icons {
  height: 20px;
}

.footer .post_footer .content_secs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer .social_icons a:hover {
  transform: translateY(-3px);
  transition: 0.2s ease-in-out;
}

/* Banner */

.home_banner .container {
  max-width: 100%;
  padding: 0px;
}

.home_banner .banner_slide .slick-arrow {
  display: none !important;
}

.home_banner .banner_img {
  height: 340px;
  width: 100%;
}

.banner_slide .slick-dots li button:before {
  width: 26px;
  height: 4px;
  background-color: #6b6b6b;
  border-radius: 3px;
  content: "";
}

.banner_slide .slick-dots li.slick-active button:before {
  background-color: var(--white);
  width: 35px;
}

.banner_slide .slick-dots li {
  width: 26px;
  height: unset;
  /* margin: 0px; */
}

.banner_slide .slick-dots li.slick-active {
  width: 35px;
}

.banner_slide .slick-dots li button {
  width: 100%;
  padding: 0px;
  height: unset;
}

.banner_slide .slick-slider {
  position: relative;
}

.banner_slide .slick-dots {
  bottom: 15px;
}

.home_banner .banner_slide {
  outline: none !important;
}

.home_banner .slick-list {
  outline: none !important;
}

.banner_slide .slick-dots {
  outline: none;
}

.banner_slide .slick-track {
  outline: none;
}

.banner_slide .slick-slide a {
  outline: none;
}

.homepage {
  background-color: #f8f8f8;
}

.cmnHeading_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cmnHeading_sec .heading_txt {
  color: var(--black);
  font-weight: 600;
  font-size: 20px;
}

.cmnHeading_sec .see-all {
  color: var(--black);
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  text-wrap: nowrap;
}

.cmnHeading_sec .see-all:hover {
  color: var(--green1);
}

/* Today Dealz */

.todayDealz_container {
  max-width: 96% !important;
}

.today_dealz {
  padding: 30px 0px;
}

.todayDelaz_card {
  margin-top: 25px;
}

/* common product card 1 start */
.product_card1 {
  background-color: var(--white1);
  border: 1px solid #e6e6e6;
  /* box-shadow: 0px 0px 3px 3px #e6e6e6; */
  padding: 7px;
  position: relative;

  /* transition: 1s all ease-in-out; */
  /* transition: 0.5s all ease-in-out; */
}

.product_card1:hover .productImg img {
  transform: scale(1.05);
}

.product_card1 .img_sec {
  background-color: #f8f8f8;
  /* height: 260px; */
  width: 100%;
  position: relative;
}

.product_card1 .img_sec .productImg {
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
}

.product_card1 .img_sec .productImg img {
  width: unset !important;
  transition: 0.5s all ease-in-out;
}

/* .product_card1 .img_sec .productImg img:hover {
  transform: scale(1.05);
} */

.product_card1 .img_sec .prod_status {
  padding: 6px 15px;
  width: fit-content;
  display: flex;
  color: var(--white);
  align-items: center;
  justify-content: center;
  font-size: 13px;
}

.product_card1 .img_sec .prod_status.minus {
  background-color: #f73232;
}

.product_card1 .img_sec .prod_status.new {
  background-color: #3267f7;
}

.product_card1 .img_sec .prod_status.trending {
  background-color: #f76f32;
}

.product_card1 .product_details {
  padding: 10px 3px;
  background-color: transparent;
}

.product_card1 .product_details .sml_title {
  color: #768b9e;
  font-size: 10px;
}

.product_card1 .product_details .prod_name {
  color: var(--black);
  font-size: 15px;
  font-weight: 500;
}

.product_card1 .product_details .prod_ratings {
  display: flex;
  align-items: center;
  gap: 1px;
}

.product_card1 .product_details .rating_img {
  width: 15px;
  height: 15px;
}

.product_card1 .prod_price {
  color: var(--black);
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.product_card1 .prod_price .prod_img {
  height: 17px;
  width: 17px;
  margin-left: 4px;
  margin-right: 4px;
}

.product_card1 .prod_price .sign_img {
  width: 15px;
  margin-left: 6px;
  margin-right: 6px;
}

.product_card1 .hidden-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 10px; */
  position: absolute;
  top: 56px;
  right: 0px;
  transform: translateX(0px);
  opacity: 0;
  transition: 0.5s all ease-in-out;
  display: none;
}

.search_products .hidden-details {
  display: block;
}

.product_card1:hover .hidden-details {
  opacity: 1;
  transform: translateX(-15px);
}

.product_card1 .hidden-details .item {
  background-color: var(--white1);
  box-shadow: 0px 0px 2px var(--black1);
  border: 1px solid #5ba62b00;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-bottom: 10px;
  cursor: pointer;
}

.product_card1 .hidden-details .item img {
  width: 18px !important;
  height: 18px !important;
}

.product_card1 .hidden-details .item:hover {
  background-color: var(--black);
}

.product_card1 .hidden-details .item:hover > img {
  filter: brightness(0) invert(1);
}

/* common product card 1 end */

.todayDelaz_owlCarousal .owl-dots .owl-dot span {
  background-color: transparent !important;
  border: 1px solid #bcbcbc;
  width: 13px;
  height: 13px;
}

.todayDelaz_owlCarousal .owl-dots .owl-dot.active span {
  background: transparent
    linear-gradient(180deg, var(--green2) 0%, var(--green-shade) 100%) 0% 0%
    no-repeat padding-box !important;
}

.todayDelaz_owlCarousal .owl-dots .owl-dot:hover span {
  background: transparent
    linear-gradient(180deg, var(--green2) 0%, var(--green-shade) 100%) 0% 0%
    no-repeat padding-box !important;
}

/* Best dealz */

.best_dealz {
  padding: 30px 0px;
}

.bestDealz_container {
  max-width: 96% !important;
}

.best_dealz_card {
  margin-top: 25px;
}

.best_dealz_card .container {
  max-width: 100% !important;
  padding-left: 38px;
  padding-right: 0px;
}

.bestdealz_card {
  /* background: url("./Assets//Images/Best_Dealz/banner1.png") no-repeat; */
  /* background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center; */
  width: 100%;
  height: 220px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.bestdealz_card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
}

.bestdealz_card:hover img {
  /* background-size: 110% 110%; */
  transform: scale(1.05);
}

.bestdealz_card .description {
  position: absolute;
  top: 54%;
  left: 13%;
  transform: translate(-13%, -54%);
}

.bestdealz_card .description .name_txt {
  color: var(--white);
  font-size: 24px;
  font-family: "Oswald", sans-serif !important;
  line-height: 30px;
}

.bestdealz_card .description .shopBtn {
  font-size: 14px;
  padding: 3px 18px !important;
  margin-top: 17px;
}

.bestdealz_card .description .shopBtn span {
  position: relative;
  top: -1px;
}

/* Most Popular */

.most_popular {
  padding: 30px 0px;
}

.mostPopular_container {
  max-width: 96% !important;
}

.mostPopularCard {
  margin-top: 25px;
}

.mostPopularCard .container {
  max-width: 100% !important;
  padding-left: 38px;
  padding-right: 0px;
}

.mostPopular_card {
  background-color: #e7eff8;
  border: 1px solid #e6e6e6;
  padding: 15px;
  border-radius: 3px;
}

.mostPopular_card .header_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mostPopular_card .header_sec .title_txt {
  color: var(--black);
  font-weight: 600;
  font-size: 16px;
}

.mostPopular_card .header_sec .see-all {
  color: var(--white);
  background-color: var(--green2);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mostPopular_card .productItems_list {
  margin-top: 15px;
}

.mostPopular_card .productItems_list .items {
  background-color: var(--white1);
  border: 1px solid #e6e6e6;
}

.mostPopular_card .productItems_list .items:hover .productImg img {
  transform: scale(1.05);
}

.mostPopular_card .items .status {
  padding: 4px 9px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  color: var(--white);
  align-items: center;
  justify-content: center;
  font-size: 11px;
}

.mostPopular_card .items .status.minus {
  background-color: #f73232;
}

.mostPopular_card .items .status.new {
  background-color: #3267f7;
}

.mostPopular_card .items .status.trending {
  background-color: #f76f32;
}

.mostPopular_card .items .productImg {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 100%;
}

.mostPopular_card .items .productImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: 0.5s all ease-in-out;
}

/* .mostPopular_card .items .productImg img:hover {
  transform: scale(1.05);
} */

.mostPopular_card .items .des {
  padding: 15px;
  /* margin-top: 15px; */
}

.mostPopular_card .items .des .title_1 {
  color: #768b9e;
  font-size: 9px;
}

.mostPopular_card .items .des .title_2 {
  color: var(--black);
  font-size: 13px;
  font-weight: 600;
}

/* .mostPopular_card .productItems_list .row {
  margin: 0px !important;
} */
.mostPopular_card .productItems_list .row .col {
  margin-bottom: 15px;
  padding-right: 5px;
  padding-left: 5px;
}

.mostPopular_card .productItems_list .row .col:nth-child(3) {
  margin-bottom: 0px;
}

.mostPopular_card .productItems_list .row .col:nth-child(4) {
  margin-bottom: 0px;
}

/* Premium Product */
.premiumProduct.container {
  max-width: 100% !important;
  padding: 0px;
}

.premiumProduct .product {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 220px;
  position: relative;
  transition: 0.5s ease-in-out;
}

/* .premiumProduct .product:hover {
  background-size: 102% 110%;
} */
.premiumProduct .des {
  position: absolute;
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -46%);
}

.premiumProduct .des .name {
  color: var(--white);
  font-size: 38px;
  font-weight: 500;
  font-family: "Oswald", sans-serif !important;
  line-height: 30px;
}

.des .showNow_btn {
  background-color: var(--white);
  border-radius: 4px !important;
  color: var(--black) !important;
  border: 1px solid var(--white);
  font-size: 16px;
  outline: none;
  font-weight: 500 !important;
  box-shadow: none !important;
  font-family: "Oswald", sans-serif !important;
  padding: 8px 20px !important;
  margin-top: 40px;
}

.premiumProduct .premiumProduct_Img img {
  width: 100%;
  height: 220px;
}

/* Popular Sales */

.popular_sales {
  padding: 30px 0px;
}

.popularSales_container {
  max-width: 96% !important;
}

.popularSalesCard {
  margin-top: 25px;
}

.popularSales_card {
  background-color: var(--white1);
  border: 1px solid #eaebed;
  padding: 15px;
  border-radius: 5px;
}

.popularSales_card:hover .img_sec img {
  transform: scale(1.05);
}

.popularSales_card .sale_percentage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popularSales_card .sale_percentage .txt {
  background-color: #ef2018;
  border-radius: 5px;
  padding: 5px 13px;
  font-size: 11px;
  color: var(--white);
  font-weight: 500;
}

.popularSales_card .sale_percentage .value {
  background-color: #fce7e7;
  border-radius: 5px;
  padding: 5px 13px;
  font-size: 11px;
  color: var(--dark-red);
  font-weight: 500;
}

.popularSales_card .img_sec {
  padding: 10px 0px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 100%;
}

.popularSales_card .img_sec img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: 0.5s all ease-in-out;
}

/* .popularSales_card .img_sec:hover img {
  transform: scale(1.05);
} */

.popularSales_card .prod_details .type {
  color: #768b9e;
  font-size: 15px;
  font-weight: 500;
}

.popularSales_card .prod_details .prod-modal {
  color: var(--green2);
  font-size: 16px;
  font-weight: 500;
}

.popularSales_card .prod_details .features {
  margin-top: 10px;
}

.popularSales_card .features .list {
  display: flex;
  align-items: center;
  gap: 7px;
}

.popularSales_card .features .feature_txt {
  color: #2f3130;
  font-size: 13px;
  font-weight: 500;
}

.popularSales_card .features .checkImg {
  height: 8px;
}

.popularSales_card .AddToCart {
  margin-top: 20px;
  width: 100%;
  padding: 8px 20px !important;
  border-radius: 5px !important;
  border: none !important;
  font-size: 14px;
  font-weight: 600;
}

.popularSalesCard .row .col {
  margin-bottom: 20px;
}

.popularSalesCard .row .col:last-child {
  margin-bottom: 0px;
}

/* New released */

.new-released {
  padding: 30px 0px;
}

.newReleased_container {
  max-width: 96% !important;
}

.new-released .lft_bigImg {
  height: 360px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.new-released .lft_bigImg img {
  height: 100%;
  width: 100%;
  transition: 0.5s all ease-in-out;
}

.new-released .lft_bigImg:hover img {
  transform: scale(1.1);
}

.new-released .ctr_smlImg {
  height: 180px;
  width: 100%;
  overflow: hidden;
}

.new-released .ctr_smlImg img {
  height: 100%;
  width: 100%;
  transition: 0.5s all ease-in-out;
}

.new-released .ctr_smlImg:hover img {
  transform: scale(1.1);
}

.new-released .ryt_halfImg {
  height: 360px;
  width: 100%;
  overflow: hidden;
}

.new-released .ryt_halfImg img {
  height: 100%;
  width: 100%;
  transition: 0.5s all ease-in-out;
}

.new-released .ryt_halfImg:hover img {
  transform: scale(1.1);
}

.new-released .row .col {
  padding: 0px !important;
}

/* shop by categories */

.shop_categories {
  padding: 30px 0px;
}

.shopCategories_container {
  max-width: 96% !important;
}

.shop_categories .brand_logo {
  background-color: var(--white);
  width: 100%;
  height: 90px;
  padding: 25px;
  box-shadow: 0px 0px 2px var(--black1);
  border-radius: 3px;
  /* transition: 0.5s all ease-in-out; */
}

.shop_categories .brand_logo:hover img {
  transform: scale(1.05);
}

.shop_categories .brand_logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: 0.5s all ease-in-out;
}

.shop_categories .brand_details {
  margin-top: 25px;
}

.shop_categories .brand_details .row .col {
  margin-bottom: 25px;
}

.shop_categories .brand_details .row .col:nth-child(7),
.shop_categories .brand_details .row .col:nth-child(8),
.shop_categories .brand_details .row .col:nth-child(9),
.shop_categories .brand_details .row .col:nth-child(10),
.shop_categories .brand_details .row .col:nth-child(11),
.shop_categories .brand_details .row .col:nth-child(12) {
  margin-bottom: 0px;
}

/* Feature Products  */

.featured_product {
  padding: 30px 0px;
}

.featuredProduct_container {
  max-width: 96% !important;
}

.featured_product .card_sec {
  margin-top: 25px;
}

.feature_product_card {
  background: var(--white1);
  box-shadow: 0px 0px 2px var(--black1);
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 17px;
}

.feature_product_card:hover .prod_img img {
  transform: scale(1.05);
}

.feature_product_card .prod_img {
  width: 50px;
  height: 50px;
}

.feature_product_card .prod_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: 0.5s all ease-in-out;
}

/* .feature_product_card .prod_img img:hover {
  transform: scale(1.05);
} */

.feature_product_card .prod_details .name {
  color: var(--black);
  font-weight: 500;
  font-size: 12px;
  font-weight: 600;
}

.feature_product_card .prod_price {
  color: var(--black);
  font-size: 15px;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.feature_product_card .prod_price .prod_img {
  height: 17px;
  width: 17px;
  margin-left: 4px;
  margin-right: 4px;
}

.feature_product_card .prod_price .sign_img {
  width: 15px;
  margin-left: 6px;
  margin-right: 6px;
}

.featured_product .card_sec .row .col {
  margin-bottom: 15px;
}

.featured_product .card_sec .row .col:last-child {
  margin-bottom: 0px;
}

/* Home All Products */

.homeAll_products {
  padding: 30px 0px;
}

.homeAll_products .container {
  max-width: 96% !important;
}

.homeAll_products .latest_products {
  background-color: #e7eff8;
  height: 368px;
}
.homeAll_products .latest_products:hover .img_sec img {
  transform: scale(1.05);
}

.homeAll_products .latest_products .name {
  color: var(--black);
  font-weight: 600;
  text-align: center;
  padding: 10px;
}

.homeAll_products .prod_type.latest {
  color: var(--white);
  font-size: 12px;
  font-weight: 500;
  padding: 7px 20px;
  display: inline-block;
}

.homeAll_products .latest_products.latest .prod_type {
  background-color: #5eaaff;
}

.cmn_col3_sec {
  background-color: #fee1ee;
  height: 180px;
  width: 100%;
}

.cmn_col3_sec.trending {
  background-color: #ffebd6;
}
.cmn_col3_sec:hover .img_sec img {
  transform: scale(1.05);
}

.cmn_col3_sec.new {
  background-color: #e1ebff;
}

.cmn_col3_sec.popular {
  background-color: #d0f3d5;
}

.homeAll_products .cmn_col3_sec .prod_type {
  color: var(--white);
  font-size: 9px;
  font-weight: 500;
  padding: 5px 10px;
  display: inline-block;

  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.homeAll_products .cmn_col3_sec.trending .prod_type {
  background-color: #ff9c34;
}

.homeAll_products .cmn_col3_sec.new .prod_type {
  background-color: #6b9dff;
}

.homeAll_products .cmn_col3_sec.popular .prod_type {
  background-color: #56955f;
}

.homeAll_products .cmn_col3_sec.transparent .prod_type {
  background-color: transparent;
}

.homeAll_products .cmn_col3_sec .img_sec {
  width: 80px;
  height: 80px;
}

.homeAll_products .cmn_col3_sec .img_sec img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: 0.5s all ease-in-out;
}

/* .homeAll_products .cmn_col3_sec .img_sec img:hover {
  transform: scale(1.05);
} */

.homeAll_products .cmn_col3_sec .name {
  color: var(--black);
  font-weight: 600;
  text-align: center;
  font-size: 12px;
  padding: 10px 10px 10px 10px;
}

.homeAll_products .cmn_col3_sec.cmn_col6_sec .img_sec {
  width: 105px;
  height: 105px;
}

.homeAll_products .cmn_col6_sec .prod_type {
  display: none;
}

.homeAll_products .latest_products .img_sec {
  width: 250px;
  height: 250px;
}

.homeAll_products .latest_products .img_sec img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: 0.5s all ease-in-out;
}
/* 
.homeAll_products .latest_products .img_sec img:hover {
  transform: scale(1.05);
} */

/* Discover Now */

.discover_now {
  padding: 30px 0px;
}

.discover_now .container {
  max-width: 96% !important;
}

.discover_now .img-sec {
  height: 170px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  /* transition: 0.5s all ease-in-out; */
}

.discover_now .img-sec img {
  height: 100%;
  width: 100%;
  transition: 0.5s all ease-in-out;
}

.discover_now .img-sec:hover img {
  transform: scale(1.05);
}

.discover_now .fst-col {
  padding-right: 0px;
}

.discover_now .snd-col {
  padding-left: 0px;
}

/* Special Products */

.specialProducts {
  padding: 30px 0px;
}

.specialProducts .container {
  max-width: 96% !important;
}

.specialProduct_card {
  background: var(--white1);
  box-shadow: 0px 0px 6px var(--black1);
  padding: 15px;
  /* transition: 0.5s all ease-in-out; */
}

.specialProduct_card:hover .img-sec img {
  transform: scale(1.05);
}

.specialProduct_card .img-sec {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.specialProduct_card .img-sec img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: 0.5s all ease-in-out;
}

/* .specialProduct_card .img-sec img:hover {
  transform: scale(1.05);
} */

.specialProduct_card .type {
  color: #768b9e;
  font-weight: 500;
  font-size: 12px;
}

.specialProduct_card .name {
  color: var(--green2);
  font-weight: 500;
  font-size: 15px;
}

.specialProduct_card .original_amt {
  color: #768b9e;
  font-weight: 500;
  font-size: 13px;
  text-decoration: line-through;
}

.specialProduct_card .discount_amt {
  color: #dd6e3d;
  font-weight: 500;
  font-size: 15px;

  display: flex;
  align-items: center;
}

.specialProduct_card .discount_amt .prod_img {
  height: 15px;
  width: 15px;
  margin-left: 4px;
  margin-right: 4px;
}

.specialProduct_card .shopping_img {
  width: 33px;
  height: 33px;
}

.specialProduct_card .shopping_img img {
  width: 100%;
  height: 100%;
}

.specialProducts .header_sec {
  border-bottom: 2px solid #5aa52b;
  display: flex;
  align-items: center;
  justify-content: center;
}

.specialProducts .header_sec .txt {
  background: transparent
    linear-gradient(280deg, var(--green1) 0%, var(--green-shade) 100%) 0% 0%
    no-repeat padding-box;
  display: inline-block;
  padding: 8px 30px;
  color: var(--white);
  font-size: 17px;
  font-weight: 500;
  font-family: Oswald, Medium;
}

.specialProducts .SplProd_Tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  position: relative;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
}

.specialProducts .SplProd_Tabs:after {
  content: "";
  background-image: url("./Assets//Images/bg_after.png");
  width: 100%;
  height: 1px;
  background-size: 100% 100%;
  display: inline-block;
  position: absolute;
  bottom: 5px;
}

.specialProducts .SplProd_Tabs .values_list {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow-x: auto;
  scrollbar-width: none;
}

.specialProducts .SplProd_Tabs .items {
  font-size: 15px;
  font-weight: 500;
  padding: 10px 20px 0px 20px;
  background-color: transparent;
  color: var(--black2);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.specialProducts .SplProd_Tabs .items:first-child {
  padding-left: 0px;
}

.specialProducts .SplProd_Tabs .items:last-child {
  padding-right: 0px;
}

.specialProducts .SplProd_Tabs .items.active {
  color: #58a42a;
}

.specialProducts .SplProd_Tabs .items.active:after {
  content: "";
  width: 100%;
  height: 8px;
  background: url("./Assets/Images/productList_after.svg") no-repeat;
  display: inline-block;
  background-size: 100% 100%;
  background-position: center;
}

.specialProducts .SplProd_Tabs .items:after {
  content: "";
  width: 100%;
  height: 8px;
  background: transparent;
  display: inline-block;
  background-size: 100% 100%;
  background-position: center;
}

.specialProducts .SplProd_Tabs .items:hover {
  color: #58a42a;
}

.specialProducts .SplProd_Tabs .items:hover:after {
  content: "";
  width: 100%;
  height: 8px;
  background: url("./Assets/Images/productList_after.svg") no-repeat;
  display: inline-block;
  background-size: 100% 100%;
  background-position: center;
}

.specialProducts .SplProd_Tabs .items span {
  padding-bottom: 6px;
  text-wrap: nowrap;
}

/* Trending Shoes */
.trendingShoes {
  padding: 30px 0px;
}

.trendingShoes .container {
  max-width: 96% !important;
}

.trendingShoes_card {
  margin-top: 20px;
}

.trendingShoes .lft_img_sec {
  background: url("./Assets/Images/trendingShoes_big.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes result_box_appearance {
  0% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

.trendingShoes .lft_img_sec .name {
  font-size: 35px;
  font-family: "Oswald", sans-serif !important;
  font-weight: 500;
  color: var(--white);
}

.trendingShoes .lft_img_sec .showNow_btn {
  background-color: var(--white);
  border-radius: 4px !important;
  color: var(--black) !important;
  border: 1px solid var(--white);
  font-size: 14px;
  outline: none;
  font-weight: 500 !important;
  box-shadow: none !important;
  font-family: "Oswald", sans-serif !important;
  padding: 3px 18px !important;
  margin-top: 10px;
}

/* .trendingShoes .lft_img_big {
  width: 100%;
  height: 500px;
}

.trendingShoes .lft_img_big img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  background-color: #e5d4ba;
  overflow: hidden;
}

.trendingShoes .lft_img_big:hover img {
  transform: scaleY(30px);
} */

.trendingShoes .ryt_img_sec.fst {
  /* background: url("./Assets/Images/trending_show1.png"); */
  /* background-repeat: no-repeat !important; */
  /* background-size: 100% 100% !important; */
  width: 100%;
  height: 250px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.trendingShoes .ryt_img_sec.fst img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s ease-in-out;
}

.trendingShoes .ryt_img_sec.fst:hover img {
  /* background-size: 105% 105% !important; */
  transform: scale(1.05);
}

.trendingShoes .ryt_img_sec.snd {
  /* background: url("./Assets/Images/trending_show2.png"); */
  /* background-repeat: no-repeat !important;
  background-size: 100% 100% !important; */
  width: 100%;
  height: 250px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.trendingShoes .ryt_img_sec.snd img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s ease-in-out;
}

.trendingShoes .ryt_img_sec.snd:hover img {
  /* background-size: 105% 105% !important; */
  transform: scale(1.05);
}

.trendingShoes .ryt_img_sec .content .offer {
  background-color: var(--black);
  color: var(--white);
  width: fit-content;
  font-size: 10px;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 4px;
  display: inline-block;
}

.trendingShoes .ryt_img_sec .content .name {
  font-size: 23px;
  font-family: "Oswald", sans-serif !important;
  font-weight: 500;
  color: var(--white);
  line-height: 30px;
}

.trendingShoes .ryt_img_sec .content .shopBtn {
  font-size: 13px;
  padding: 3px 18px !important;
  margin-top: 10px;
}

.trendingShoes .ryt_img_sec.fst .content {
  position: absolute;
  top: 50%;
  left: 16%;
  transform: translate(-16%, -50%);
}

.trendingShoes .ryt_img_sec.snd .content {
  position: absolute;
  position: absolute;
  top: 50%;
  right: 15%;
  transform: translate(15%, -50%);
}

.trendingShoes .lft-col {
  padding-right: 0px;
}

.trendingShoes .ryt-col {
  padding-left: 0px;
}

/* Booking Collections */

.bookCollections {
  padding: 30px 0px;
}

.bookCollections .container {
  max-width: 96% !important;
}

.bookCollection_card {
  background-color: var(--white);
}

.bookCollection_card .book-details {
  background: url("./Assets/Images/books/book1_bg.png");
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100% 100%;
  height: 250px;
  padding-top: 20px;
}

.bookCollection_card .type {
  color: var(--white);
  font-size: 15px;
  font-weight: 500;
}

.bookCollection_card .total-prod {
  color: var(--white);
  font-size: 12px;
  font-weight: 500;
}

.bookCollection_card .ratings {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
}

.bookCollection_card .rating-img {
  width: 10px !important;
  height: 10px;
}

.bookCollection_card .book-img {
  height: 240px;
  width: 170px;
  margin-top: -160px;
}

.bookCollection_card .book-img img {
  height: 100%;
  width: 100%;
}

.bookCollection_card .name {
  font-size: 13px;
  color: var(--dark-blue1);
  font-weight: 500;
}

.bookCollection_card .prod_price {
  color: var(--black);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.bookCollection_card .prod_price .prod_img {
  height: 15px;
  width: 15px;
  margin-left: 4px;
  margin-right: 4px;
}

.bookCollection_card .prod_price .sign_img {
  width: 14px;
  margin-left: 6px;
  margin-right: 6px;
}
.bookCollections_slide .slick-track .slick-slide {
  padding: 0px 5px 0px 5px;
}
.bookCollections_slide .slick-track .slick-slide.slick-current {
  padding: 0px 5px 0px 0px;
}
.bookCollections_slide .slick-track .slick-slide:last-child {
  padding: 0px 0px 0px 5px;
}

.bookCollections_slide .slick-dots {
  display: none !important;
}

.bookCollections_slide .slick-arrow {
  /* display: none !important; */
}

.bookCollections_slide .slick-next:before {
  content: "" !important;
  background: url("./Assets/Images/nextArrowBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 25px;
  height: 50px;
  display: inline-block;
  opacity: 1;
}

.bookCollections_slide .slick-next {
  right: 5px;
  top: 40%;
}

.bookCollections_slide .slick-prev:before {
  content: "" !important;
  background: url("./Assets/Images/prevArrowBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 25px;
  height: 50px;
  display: inline-block;
  opacity: 1;
}

.bookCollections_slide .slick-prev {
  left: 0px;
  z-index: 1;
  top: 40%;
}

.bookCollections_slide .slick-arrow {
  display: none;
}

.bookCollections_slide_owl {
}

.bookCollections_slide_owl .owl-nav {
  position: absolute;
  top: 40%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-40%);
}

.bookCollections_slide_owl .owl-nav button.owl-prev,
.bookCollections_slide_owl .owl-nav button.owl-next {
  background: none;
  border: none;
  color: #000;
  font-size: 2em;
  cursor: pointer;
}

.bookCollections_slide_owl .owl-nav button.owl-prev {
  position: absolute;
  left: 0;
  height: 47px;
  width: 25px;
  border-radius: 0px 200px 200px 0px;
  background-color: var(--black) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white) !important;
  font-size: 11px !important;
}

.bookCollections_slide_owl .owl-nav button.owl-next {
  position: absolute;
  right: 0;
  height: 47px;
  width: 25px;
  border-radius: 200px 0px 0px 200px;
  background-color: var(--black) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white) !important;
  font-size: 11px !important;
}

/* Bitdealz Marketplace Modal */
.common_Modal_Popup .modal-content {
  border-radius: 9px;
}

.common_Modal_Popup .close_btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  background-color: transparent !important;
  border: none !important;
  outline: none;
  box-shadow: none;
  transition: 0.2s all ease-in-out;
}

.common_Modal_Popup .close_btn:hover {
  transform: scale(1.05);
}

.common_Modal_Popup .modal-body {
  position: relative;
}

.common_Modal_Popup .close_btn {
  position: absolute;
  top: -10px;
  right: -10px;
}

.bitdealz_marketplace_modal .modal-body {
  padding: 50px;
}

.bitdealz_marketplace_modal .logo {
  height: 60px;
  width: 60px;
  object-fit: contain;
}

.bitdealz_marketplace_modal .name {
  font-weight: 500;
}

.bitdealz_marketplace_modal .type {
  margin-top: 30px;
}

.bitdealz_marketplace_modal .type .account {
  background-color: var(--white1);
  box-shadow: 0px 2px 8px var(--black1);
  padding: 10px;
  width: 100%;
  border: 1px solid var(--white);
  color: var(--black2) !important;
  display: flex;
  align-items: center;
  gap: 18px;
  transform: none;
  font-family: Poppins, Regular !important;
}

.bitdealz_marketplace_modal .type .account.active {
  background: #5ba62b1a;
  border: 1px solid var(--green2);
  font-family: Poppins, Medium !important;
  font-weight: 500;
}

.bitdealz_marketplace_modal .type .account:hover {
  background: #5ba62b1a;
  border: 1px solid var(--green2);
  font-family: Poppins, Medium !important;
  font-weight: 500;
}

.bitdealz_marketplace_modal .account .acc-img {
  display: inline-block;
  width: 38px;
  height: 38px;
  border-radius: 4px;
  background-color: var(--green2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.bitdealz_marketplace_modal .account .acc-img img {
  width: 23px;
  height: 23px;
}

/* .cmn_checkbox {
  display: flex;
  align-items: center;
} */

.cmn_checkbox label {
  color: var(--black2);
  font-size: 14px;
  padding-left: 10px;
  position: relative;
  top: 2px;
}

.cmn_checkbox label a {
  color: var(--black2);
}

.cmn_checkbox label a:hover {
  color: var(--green2);
}

.cmn_checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cmn_checkbox .form-check-input {
  width: 20px;
  height: 20px;
  box-shadow: none !important;
  border: 1px solid var(--black2) !important;
  font-weight: 500;
}

.cmn_checkbox .form-check-input:checked {
  background-color: var(--green2);
  border: 1px solid transparent !important;
}

.bitdealz_marketplace_modal .signupBtn {
  margin-top: 40px;
  background: transparent
    linear-gradient(104deg, #4a9a25 0%, var(--green3) 84%, #80c33b 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 7px;
  color: var(--white);
  padding: 7px 55px;
  border: 1px solid var(--green3) !important;
  box-shadow: none;
  outline: none;
  font-size: 15px;
  font-weight: 500;
}

.bitdealz_marketplace_modal .signupBtn:hover {
  background: transparent
    linear-gradient(104deg, #80c33b 100%, var(--green3) 84%, #4a9a25 0%) 0% 0%
    no-repeat padding-box;
  border: 1px solid #80c33b !important;
}

/* Signup modal */

.signUp_modal .modal-body {
  /* padding: 30px; */
  padding: 40px 65px;
}

.signUp_modal .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}

.signUp_modal .head_sec {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.signUp_modal .name {
  color: var(--black);
  font-weight: 600;
  font-size: 20px;
}

.signUp_modal .des {
  color: var(--black);
  font-size: 14px;
}

.signUp_modal .signUpWallet {
  width: 120px;
  height: 120px;
}

.signUp_modal .wallet {
  background: var(--light-gray2) !important;
  border-radius: 50px;
  color: var(--black) !important;
  padding: 6px 14px;
  border: 1px solid #cecece !important;
  box-shadow: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
}

.signUp_modal .wallet .wallet-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: contain;
}

.signUp_modal .wallet:hover {
  background-color: #80c33b !important;
  color: var(--white) !important;
  border: 1px solid #80c33b;
}

.signUp_modal .wallet_lists {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.signUp_modal .already-acc {
  color: var(--black2);
  font-weight: 500;
}

.signUp_modal .already-acc button {
  color: var(--green2);
  background-color: transparent !important;
  padding: 0px;
}

.signUp_modal .already-acc button:hover {
  color: var(--black2);
}

.signUp_modal .bitdealz-txt {
  color: var(--black);
}

/* Signup Passkey */
.signUp_modal .passkey_protect {
  color: black;
  font-size: 15px;
  font-weight: 500;
}

.signUp_modal .create-passkey {
  color: black;
  font-size: 13px;
}

.signUp_modal .passkey_inp {
  display: flex;
  align-items: center;
  justify-content: center;
}

.signUp_modal .passkey_inp input {
  background-color: #f0f0f0 !important;
  border: 1px solid #d9d9d9 !important;
  width: 35px !important;
  height: 35px !important;
  border-radius: 50%;
  font-size: 20px;
  color: transparent;
  outline: none;
  text-align: center;
  font-size: 1px;
}

.signUp_modal .passkey_inp input:not([value=""]) {
  background: transparent
    linear-gradient(46deg, var(--green2) 0%, var(--green-shade) 100%) !important;
}

.signUp_modal .passkey_inp span {
  display: none;
}

.signUp_modal .passkey_inp > div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.signUp_modal .confirmBtn {
  border-radius: 50px;
  padding: 10px 14px;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
}

.signUp_modal .modal-content {
  /* width: 90%; */
}

.signUp_modal .reset-txt {
  text-decoration: underline;
  color: black;
  cursor: pointer;
  width: fit-content;
}

.signUp_modal .reset-txt:hover {
  color: var(--green2);
}

.signUp_modal .btns {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.signUp_modal .btns .homeBtn {
  border-radius: 50px;
  padding: 10px 14px;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  outline: none;
  box-shadow: none;
  background: transparent linear-gradient(83deg, #6b63ed 0%, #9984ed 100%);
  box-shadow: 0px 3px 6px var(--black1);
  border: 1px solid #9984ed !important;
}

.signUp_modal .btns .homeBtn:hover {
  background: transparent linear-gradient(83deg, #9984ed 0%, #6b63ed 100%);
}

.signUp_modal .btns .hotDealz {
  border-radius: 50px;
  padding: 10px 14px;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  outline: none;
  box-shadow: none;
  background: transparent linear-gradient(83deg, #e3488a 0%, #c4509b 100%);
  box-shadow: 0px 3px 6px var(--black1);
  border: 1px solid #c4509b !important;
}

.signUp_modal .btns .hotDealz:hover {
  background: transparent linear-gradient(83deg, #c4509b 0%, #e3488a 100%);
}

.signUp_modal .btns .manageAccBtn {
  border-radius: 50px;
  padding: 10px 14px;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  outline: none;
  box-shadow: none;
  background: transparent linear-gradient(83deg, #6647bf 0%, #5a3ab5 100%);
  box-shadow: 0px 3px 6px var(--black1);
  border: 1px solid #6647bf !important;
}

.signUp_modal .btns .manageAccBtn:hover {
  background: transparent linear-gradient(83deg, #5a3ab5 0%, #6647bf 100%);
}

.lft_img_big {
  height: 500px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.lft_img_big img {
  width: 100%;
  height: 700px;
  transition: transform 1s ease;
  transform: translateY(-8px) scale(1.05);
  position: relative;
  z-index: 1;
}

.lft_img_big:hover img {
  transform: translateY(-200px);
}

.trendingShoes .lft_img_big:hover::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.trendingShoes .lft_img_big .center_cnt {
  z-index: 3;
  /* display: none;  */
  /* visibility: inherit; */
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 1s all ease-in-out;
}

.trendingShoes .lft_img_big:hover .center_cnt {
  /* display: block; */
  opacity: 1;
}

.trendingShoes .lft_img_big .name {
  font-size: 45px;
  font-family: "Oswald", sans-serif !important;
  font-weight: 500;
  color: var(--white);
  text-wrap: nowrap;
}

.trendingShoes .lft_img_big .showNow_btn {
  background-color: var(--white);
  border-radius: 4px !important;
  color: black !important;
  border: 1px solid var(--white);
  font-size: 14px;
  outline: none;
  font-weight: 500 !important;
  box-shadow: none !important;
  font-family: "Oswald", sans-serif !important;
  padding: 3px 18px !important;
  margin-top: 10px;
}

/* Choose Network Modal */

.choose_network_modal .modal-body {
  padding: 40px;
}

.choose_network_modal .type {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.choose_network_modal .network_btn .network_img {
  width: 37px;
  height: 37px;
}

.choose_network_modal .network_btn .network_img img {
  width: 100%;
  height: 100%;
}

.choose_network_modal .network_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--black2) !important;
  font-weight: 500;
  gap: 8px;
  background: #f8f8f8 !important;
  border: 1px solid var(--light-gray);
  padding: 16px 30px;
  border-radius: 10px;
}

.choose_network_modal .network_btn:hover {
  background-color: #80c33b !important;
  color: var(--white) !important;
  border: 1px solid #80c33b !important;
}

.choose_network_modal .content .name {
  font-weight: 600;
  font-size: 17px;
}

.choose_network_modal .logo {
  height: 60px;
  width: 60px;
  object-fit: contain;
}

.signUp_modal .reset_link button {
  color: #ff5353;
  background-color: transparent !important;
  padding: 0px;
}

.signUp_modal .reset_link button:hover {
  color: var(--green-shade);
}

.signUp_modal .create_newKey button {
  padding: 0px;
  color: var(--black);
  background-color: transparent !important;
}

.signUp_modal .create_newKey button:hover {
  color: var(--green-shade);
}

.signUp_modal.login_modal .login_sec {
  margin-top: 90px;
}

/* Shiplocation Modal */

.shiplocation_modal .modal-body {
  padding: 0px;
}

.shiplocation_modal .header_sec {
  background-color: #f0f2f2;
  color: var(--black);
  font-weight: 600;
  padding: 15px;
  border-radius: 10px 10px 0px 0px;
}

.common_Modal_Popup.shiplocation_modal .modal-content {
  border-radius: 10px;
}

.shiplocation_modal .address_sec {
  padding: 10px 15px;
  background: var(--white1);
  border: 1px solid #d297d3;
  border-radius: 5px;
  cursor: pointer;
}

.shiplocation_modal .address_sec .address {
  color: var(--black);
  font-size: 13px;
}

.shiplocation_modal .address_sec .default_add {
  color: var(--green2);
  font-size: 13px;
  font-weight: 500;
  display: none;
  transition: 2s all ease-in-out;
}

.shiplocation_modal .address_sec.active > .default_add {
  display: block;
}

.shiplocation_modal .address_sec.active {
  border: 1px solid var(--green2);
}

.shiplocation_modal .address_sec:hover {
  border: 1px solid var(--green2);
}

.shiplocation_modal .addressList {
  padding: 15px;
  max-height: 350px;
  overflow-y: auto;
}

.shiplocation_modal .apply_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
}

.shiplocation_modal .apply_btn button {
  border-radius: 50px;
  padding: 10px 14px;
  font-size: 15px;
  font-weight: 500;
  width: 50%;
}

/* Todays Deal Page */

.todaysDeal_pg {
  padding: 25px 0px 40px;
}

.todaysDeal_pg .heading_txt {
  color: black;
  font-weight: 600;
}

.todaysDeal_pg .SplProd_Tabs {
  justify-content: start;
}

.todaysDeal_pg .related-products {
  padding-top: 40px;
}

.relatedProductsCard {
  margin-top: 35px;
}

.relatedProductsCard .row .col {
  margin-bottom: 20px;
}

.relatedProductsCard .row .col:last-child {
  margin-bottom: 0px;
}

/* Search Products */

.search_products .container {
  max-width: 96% !important;
}

.search_products {
  padding: 25px 0px 40px 0px;
  background-color: #f8f8f8;
}

.search_products .product_list .col {
  margin-bottom: 20px;
}

.search_products .product_list .col:last-child {
  margin-bottom: 0px;
}

.searchProduct_filter .heading {
  background-color: #2e2e2e;
  color: var(--white);
  font-size: 14px;
  padding: 10px 15px;
}

.searchProduct_filter {
  background-color: var(--white);
  position: sticky;
  top: 68px;
  /* padding: 0px 10px 15px; */
}

.searchProduct_filter .filter_list .sec {
  padding: 15px;
  border-bottom: 1px solid #e9e9e9;
}

.searchProduct_filter .filter_list .sec.brand {
  border-bottom: none !important;
}

.searchProduct_filter .sec .drop-heading {
  color: var(--black2);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.commonRadio_btn .form-check-input {
  border: 1px solid #2e2e2e;
  width: 15px;
  height: 15px;
  box-shadow: none !important;
}

.commonRadio_btn .form-check-input:checked {
  border: 1px solid var(--green2) !important;
  background-color: var(--green2) !important;
}

.form-check-input:checked[type="radio"] {
  /* background-image: unset !important; */
  /* background-color: var(--green2) !important; */
  /* --bs-form-check-bg-image:var(--green2) !important */
}

.drop_items .form-check-label {
  color: #2e2e2e;
  font-size: 13px;
}

.searchProduct_filter .drop_items {
  margin-bottom: 8px;
}

.searchProduct_filter .drop_items:last-child {
  margin-bottom: 0px;
}

.searchProduct_filter .price-range {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price-range .valInp {
  border: 1px solid #7b7b7b;
  border-radius: 8px;
  color: #2e2e2e;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  font-size: 11px;
  width: 75px;
  height: 30px;
  padding: 0px 13px;
}

.price-range .valInp::placeholder {
  color: #2e2e2e;
  text-align: center;
}

.price-range .valInp::-webkit-inner-spin-button,
.price-range .valInp::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.price-range .goBtn {
  background: linear-gradient(
    180deg,
    var(--green2) 0%,
    var(--green-shade) 100%
  );
  border: 1px solid var(--green-shade) !important;
  color: var(--white);
  outline: none;
  box-shadow: none;
  height: 32px;
  width: 39px;
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
}

.price-range .goBtn:hover {
  background: linear-gradient(
    180deg,
    var(--green-shade) 0%,
    var(--green2) 100%
  );
}

/* Product details */

.product_details {
  padding: 25px 0px 40px 0px;
  background-color: #f8f8f8;
}

.product_details .container {
  max-width: 96% !important;
}

.product_details .prodDetail {
  border: 1px solid var(--light-gray);
  border-radius: 7px;
}

.prodDetail .alert_heading {
  background-color: var(--green2);
  color: var(--white);
  font-size: 11px;
  padding: 8px;
  font-weight: 500;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.prodDetail .section1 {
  padding: 12px;
  border-bottom: 1px solid var(--light-gray);
}

.prodDetail .section1 .prod-name {
  color: var(--black);
  font-size: 12px;
  font-weight: 500;
}

.prodDetail .bell_lottie svg {
  width: 17px !important;
  height: 17px !important;
  transform: unset !important;
}

.prodDetail .amount {
  display: flex;
  align-items: center;
}

.prodDetail .amount .coin {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  font-size: 14px;
}

.prodDetail .amount .coinImg {
  width: 20px;
  height: 20px;
}

.prodDetail .amount .signImg {
  height: 5px;
  margin-left: 5px;
  margin-right: 3px;
}

.prodDetail .amount .old-amt {
  font-size: 12px;
  font-weight: 500;
  text-decoration: line-through;
}

.prodDetail .amount .new-amt {
  font-size: 13px;
  font-weight: 500;
  color: #de7445;
}

.prodDetail .amount .reloadImg {
  height: 13px;
  cursor: pointer;
}

.prodDetail .reviewRatings {
  display: flex;
  align-items: center;
}

.prodDetail .reviewRatings .rating-icons {
  display: flex;
  align-items: center;
  gap: 4px;
}

.prodDetail .reviewRatings .rating-icons img {
  height: 14px;
}

.prodDetail .reviewRatings .rating-icons .ratVal {
  color: var(--black);
  font-size: 12px;
  font-weight: 500;
  /* position: relative; */
  /* top: 1.5px; */
}

.prodDetail .reviewRatings .verticalBar {
  color: #a8a8a8;
}

.prodDetail .reviewRatings .review,
.prodDetail .reviewRatings .sold {
  color: var(--black2);
  font-size: 12px;
  font-weight: 500;
}

.prodDetail .soldBy {
  font-size: 12px;
  color: #0e1b28;
}

.prodDetail .soldBy a {
  color: #1bacea;
}

.prodDetail .section2 {
  padding: 12px;
  border-bottom: 1px solid var(--light-gray);
}

.prodDetail .section2 .heading_txt {
  color: var(--black);
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.prodDetail .section2 .heading_txt .arrowBtn {
  width: 13px;
  height: 13px;
  background-color: #0e1b28 !important;
  color: var(--white) !important;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  outline: none;
  box-shadow: none;
  border-radius: 50%;
}

.prodDetail .item-info .txt {
  color: #2e2e2e;
  font-size: 11px;
  /* font-weight: 500; */
}

.prodDetail .section2 .closeBtn {
  background-color: transparent !important;
  color: var(--bink) !important;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border: none !important;
  outline: none;
  box-shadow: none;
  font-size: 11px;
}

.prodDetail .section3 {
  padding: 12px;
  border-bottom: 1px solid var(--light-gray);
}

.prodDetail .section3 .header-txt {
  font-size: 12px;
  font-weight: 600;
}

.prodDetail .section3 .block_icons {
  display: flex;
  align-items: center;
  gap: 6px;
}

.prodDetail .section3 .block_icons img {
  height: 17px;
}

.prodDetail .section4 {
  padding: 12px;
}

.prodDetail .section4 .header-txt {
  font-size: 12px;
}

.prodDetail .productPhotos {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.prodDetail .productPhotos .photo {
  width: 18%;
}

.prodDetail .productPhotos .img_sec {
  width: 100%;
  height: 80px;
  border-radius: 13px;
  border: 2px solid transparent;
  cursor: pointer;
}

.prodDetail .productPhotos .img_sec.active {
  border: 2px solid var(--green2);
}

.prodDetail .productPhotos .img_sec img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 11px;
}

.prodDetail .section4 .info {
  background-color: #efefef;
  padding: 10px;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #787f85;
}

.prodDetail .section4 .info .value {
  color: #81b95d;
}

.product_details .shipAddress {
  border: 1px solid var(--light-gray);
  border-radius: 7px;
  position: sticky;
  top: 65px;
}

.product_details .shipAddress .shipHeading {
  background-color: var(--green2);
  color: var(--white);
  font-size: 11px;
  padding: 8px 15px;
  font-weight: 500;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.product_details .shipAddress .locationBtn {
  background: transparent !important;
  color: var(--white) !important;
  outline: none;
  box-shadow: none;
  border: none !important;
  font-size: 11px;
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 0px;
}

.product_details .shipAddress .section1 {
  padding: 12px;
  border-bottom: 1px solid var(--light-gray);
}

.shipAddress .section1 .delivery {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
}

.shipAddress .section1 .amount .coin {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  font-size: 12px;
  background-color: var(--black);
  color: var(--white);
  border-radius: 50px;
  padding: 2px 5px;
  width: fit-content;
}

.shipAddress .section1 .amount .coinImg {
  width: 15px;
  height: 15px;
}

.shipAddress .section1 .amount .checked {
  width: 15px;
  height: 15px;
}

.shipAddress .section1 .amount {
  display: flex;
  align-items: center;
  gap: 6px;
}

.shipAddress .section1 .delivery_lottie svg {
  width: 30px !important;
  height: 30px !important;
  transform: unset !important;
}

.shipAddress .section1 .amount .signImg {
  height: 4.5px;
}

.shipAddress .section1 .amount .amt-txt {
  color: var(--black2);
  font-size: 12px;
  font-weight: 500;
}

.shipAddress .section1 .delivery-date {
  color: #0e1b28;
  font-size: 12px;
  font-weight: 600;
}

.shipAddress .section2 {
  padding: 12px;
  border-bottom: 1px solid var(--light-gray);
}

.shipAddress .section2 .ship-from {
  color: #0e1b28;
  font-size: 12px;
  font-weight: 500;
}

.shipAddress .section2 .ship-from .country {
  color: var(--green2);
}

.shipAddress .section3 {
  padding: 12px;
  border-bottom: 1px solid var(--light-gray);
}

.shipAddress .section3 .cus_protection {
  position: relative;
}

.cus_protection .header-txt {
  color: var(--bink);
  font-size: 12px;
  font-weight: 500;
}

.cus_protection .lock_lottie {
  width: 40px !important;
  height: 40px !important;
}

.cus_protection .escrow_lock {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.cus_protection .escrow_lock .a {
  color: #0e1b28;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
}

.cus_protection .escrow_lock .a:hover {
  color: var(--green2);
}

.cus_protection .escrow_lock .ques {
  width: 13px;
  height: 13px;
  background-color: #0e1b28 !important;
  color: var(--white) !important;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  outline: none;
  box-shadow: none;
  border-radius: 50%;
}

/* .cus_protection .hidden_info {
  background-color: var(--white1);
  border-radius: 4px;
  box-shadow: 0px 0px 6px var(--black1);
  padding: 7px 12px;
  display: none;
} */
.cus_protection .header-txt:hover .cus_protection .hidden_info {
  background-color: red !important;
}

.cus_protection .lock_dropdown {
  /* position: relative; */
  /* display: inline-block; */
}

.cus_protection .dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--white1);
  border-radius: 4px;
  box-shadow: 0px 0px 6px var(--black1);
  padding: 7px 12px;
  min-width: 160px;
  z-index: 1;
}

.lock_dropdown:hover .hidden_info {
  display: block !important;
}

.cus_protection .hidden_info .heading_txt {
  color: #0e1b28;
  font-size: 12px;
  font-weight: 500;
}

.cus_protection .hidden_info .info_details {
  font-size: 10px;
  font-weight: 500;
}

.cus_protection .hidden_info .more-info {
  color: var(--bink);
  font-size: 11px;
  font-weight: 500;
}

.cus_protection .hidden_info .more-info a {
  color: var(--bink);
}

.shipAddress .section4 {
  padding: 12px;
  border-bottom: 1px solid var(--light-gray);
}

.shipAddress .section4 .inc_dec_btn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  color: var(--black);
  background-color: #f0b90b !important;
  border: none;
  outline: none;
  box-shadow: none;
}

.shipAddress .section4 .inc_dec_btn:hover {
  color: var(--white);
}

.shipAddress .section4 .inc_dec_btn svg {
  height: 9px;
}

.shipAddress .section4 .count {
  font-size: 12px;
  font-weight: 500;
}

.shipAddress .section4 .header-txt {
  color: var(--bink);
  font-size: 12px;
  font-weight: 500;
}

.shipAddress .section5 {
  padding: 12px;
  border-bottom: 1px solid var(--light-gray);
}

.shipAddress .section5 .coupons_heading {
  color: #0e1b28;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.shipAddress .section5 .couponImg {
  height: 25px;
}

.shipAddress .section6 {
  padding: 12px;
  border-bottom: 1px solid var(--light-gray);
}

.couponsList .item {
  /* background-image: url("./Assets/Images/coupons/couponBg1.png"); */
  background-repeat: no-repeat;
  width: 100%;
  /* height: 120px; */
  background-size: 100% 100%;
  background-position: center;
  padding: 12px;
}

.couponsList .item .des {
  color: var(--white);
  font-size: 11px;
  font-weight: 500;
}

.couponsList .item .amount {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 10px;
}

.couponsList .item .amount .coin {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  font-size: 12px;
  background-color: var(--white);
  color: var(--black);
  border-radius: 50px;
  padding: 2px 5px;
  width: -moz-fit-content;
  width: fit-content;
}

.couponsList .item .amount .checked {
  width: 15px;
  height: 15px;
}

.couponsList .item .amount .signImg {
  height: 4.5px;
  filter: invert();
}

.couponsList .item .amount .amt-txt {
  color: var(--white);
  font-size: 12px;
  font-weight: 500;
}

.couponsList .item .amount .coinImg {
  width: 15px;
  height: 15px;
}

.couponsList .item .coupon-id {
  background-color: var(--white);
  width: 100%;
  height: 30px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 6px;
  color: var(--black);
  font-size: 11px;
  font-weight: 500;
  padding: 0px 5px 0px 8px;
  margin-top: 10px;
}

.couponsList .item .coupon-id .apply-btn {
  font-size: 11px;
  font-weight: 500;
  border-radius: 50px;
  padding: 3px 17px;
  position: relative;
  top: 1px;
}

.couponsList .item .coupon-id .address {
  position: relative;
  top: 2px;
}

.couponsList .item {
  margin-bottom: 10px;
}

.couponsList .item:last-child {
  margin-bottom: 0px;
}

.shipAddress .section7 {
  padding: 12px;
}

.section7 .total_price .header-txt {
  color: var(--bink);
  font-size: 12px;
  font-weight: 500;
}

.section7 .amount {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 10px;
}

.section7 .amount .coin {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  font-size: 12px;
  background-color: var(--black);
  color: var(--white);
  border-radius: 50px;
  padding: 2px 5px;
  width: -moz-fit-content;
  width: fit-content;
}

.section7 .amount .signImg {
  height: 4.5px;
}

.section7 .amount .amt-txt {
  color: var(--black);
  font-size: 12px;
  font-weight: 500;
}

.section7 .amount .coinImg {
  width: 15px;
  height: 15px;
}

.shipAddress .section8 {
  padding: 12px;
}

.section8 .total_price .header-txt {
  color: var(--bink);
  font-size: 12px;
  font-weight: 500;
}

.section8 .chain-list .list {
  display: flex;
  align-items: center;
  color: var(--black);
  font-weight: 500;
  font-size: 12px;
  gap: 7px;
}

.section8 .chain-list .list img {
  width: 14px;
  height: 14px;
}

.shipAddress .bottom_content {
  background: var(--white1);
  border: 1px solid var(--light-gray);
  padding: 14px 25px;
  border-radius: 7px;
}

.shipAddress .bottom_content .BuyNow_btn {
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 7px 20px;
  border-radius: 50px;
  background: var(--black);
  border: 1px solid var(--black);
  color: var(--white);
}

.shipAddress .bottom_content .BuyNow_btn img {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
}

.shipAddress .bottom_content .AddtoCart_btn {
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 7px 20px;
  border-radius: 50px;
  margin: 10px 0px;
}

.shipAddress .bottom_content .AddtoCart_btn img {
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
}

.shipAddress .share_btn {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 7px 35px;
  border-radius: 50px;
  background: transparent !important;
  border: 1px solid var(--light-gray) !important;
  color: #0e1b28 !important;
}

.shipAddress .share_btn:hover {
  background-color: #f1f1f1 !important;
}

.shipAddress .wish_btn {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 7px 21px;
  border-radius: 50px;
  background: transparent !important;
  border: 1px solid var(--light-gray) !important;
  color: #0e1b28 !important;
  /* transition: 3s all ease-in-out; */
}

.shipAddress .wish_btn:hover {
  background-color: #f1f1f1 !important;
}

.shipAddress .shareWish_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shipAddress .wish_btn .wished_product {
  color: var(--green2);
  transform: scale(0.9);
}

.shipAddress .cmnScroll {
  height: 330px;
  overflow-y: auto;
  scrollbar-width: none;
}

.shipTo_modal .header-txt {
  font-size: 20px;
  font-weight: 600;
}

/* Ship to modal */

.shipTo_modal .country_nameBtn {
  padding: 8px 13px;
  box-shadow: none;
  background-color: transparent !important;
  border: 1px solid #dcdcdd !important;
  border-radius: 6px;
  width: 100%;
  color: var(--black2) !important;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  gap: 5px;
  font-weight: 500;
}

.shipTo_modal .applyBtn {
  width: 100%;
  border-radius: 50px;
  font-weight: 500;
  font-size: 14px;
  padding: 7px 20px;
}

.shipTo_modal .country_list {
  background: var(--white1);
  box-shadow: 0px 2px 8px var(--black1);
  border-radius: 6px;
  /* padding: 10px; */
  margin-top: 13px;
  border: none;
  position: relative !important;
  inset: unset !important;
  transform: unset !important;
}

.shipTo_modal .custom_search {
  position: relative;
  padding: 10px;
}

.shipTo_modal .custom_search input {
  background-color: var(--light-gray1);
  color: #8d8d8d;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 8px 15px 8px 36px;
  border-radius: 7px;
  font-size: 12px;
  width: 100%;
}

.shipTo_modal .custom_search .search_iocn {
  position: absolute;
  top: 16px;
  left: 17px;
  font-size: 22px;
  color: #565353;
}

.shipTo_modal .search_items {
  padding: 0px 10px 10px;
  max-height: 170px;
  overflow-y: auto;
  padding-bottom: 15px;
}

.shipTo_modal .search_items_list {
  margin-bottom: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 5px;
}

.shipTo_modal .search_items_list:hover {
  background-color: var(--light-gray1);
}

.shipTo_modal .search_items_list:last-child {
  margin-bottom: 0px;
}

.shipTo_modal .search_items_list .logoName {
  display: flex;
  align-items: center;
  gap: 12px;
}

.shipTo_modal .search_items_list .search_logos {
  height: 20px;
  width: 25px;
  object-fit: contain;
}

.shipTo_modal .search_items_list .search_name {
  font-size: 12px;
  font-weight: 500;
}

.shipTo_modal .search_items .recommended {
  font-size: 14px;
  font-weight: 600;
  padding: 0px 10px 0px;
}

.choosenCountry_modal .header-section {
  display: flex;
  align-items: start;
  gap: 10px;
}

.choosenCountry_modal .btn_sec {
  display: flex;
  align-items: center;
  gap: 20px;
}

.choosenCountry_modal .btn_sec .try_anotherBtn {
  border-radius: 4px;
  padding: 6px 20px;
  font-size: 13px;
  font-weight: 500;
}

.choosenCountry_modal .header-section .txt {
  font-size: 13px;
  font-weight: 500;
}

.choosenCountry_modal .header-section .warningImg {
  height: 25px;
}

.choosenCountry_modal .btn_sec .cancelBtn {
  border-radius: 4px;
  padding: 6px 20px;
  font-size: 13px;
  font-weight: 500;
}

.shareProduct_modal .header-txt {
  font-size: 20px;
  font-weight: 600;
}

.shareProduct_modal .socialMedia_list {
  display: flex;
  align-items: center;
  gap: 17px;
  width: 100%;
  flex-wrap: wrap;
}

.shareProduct_modal .socialMedia_list a {
  width: 30px;
  height: 30px;
}

.shareProduct_modal .socialMedia_list a .socialImages {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product_description .header_tabsec {
  display: flex;
  align-items: center;
  gap: 5px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 6px;
  padding-bottom: 6px;
  scrollbar-width: none;
  flex-wrap: nowrap;
  /* border-bottom: 1px solid var(--light-gray); */
  justify-content: space-between;
  position: sticky;
  z-index: 2;
  top: 62px;
  /* top: 100px; */
  background-color: #f8f8f8;
}

.product_description .header_tabsec .tab_items {
  font-weight: 500;
  text-wrap: nowrap;
  padding-bottom: 10px;
  position: relative;
  color: var(--black2);
  font-size: 14px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
}

.product_description .header_tabsec .tab_items.active {
  border-bottom: 2px solid #f77c27;
  color: #f77c27;
}

.product_description .header_tabsec .tab_items:hover {
  border-bottom: 2px solid #f77c27;
  color: #f77c27;
}

.product_description .header_tabsec .tab_items::after {
  content: "";
  width: 12px;
  display: inline-block;
  height: 5px;
  background-color: transparent;
  border-radius: 0px 0px 50% 50%;
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%, 4px);
}

.product_description .header_tabsec .tab_items.active::after {
  content: "";
  width: 12px;
  display: inline-block;
  height: 5px;
  background-color: #f77c27;
  border-radius: 0px 0px 50% 50%;
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%, 4px);
}

.product_description .header_tabsec .tab_items:hover::after {
  content: "";
  width: 12px;
  display: inline-block;
  height: 5px;
  background-color: #f77c27;
  border-radius: 0px 0px 50% 50%;
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%, 4px);
}

.product_description .tabs_content .header-txt {
  color: var(--black);
  font-size: 21px;
  font-weight: 600;
}

.product_description .tabs_content .big_img img {
  width: 100%;
}

.product_description .product_det .head_txt {
  color: var(--black);
  font-size: 18px;
  font-weight: 500;
}

.product_det .all_details .name {
  color: var(--black);
  font-size: 14px;
}

.product_det .all_details .value {
  font-weight: 500;
  font-size: 14px;
}

.product_description .specification .spec-details {
  border: 1px solid #e5e5e5;
}

.specification .spec-details .item {
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--black);
  font-size: 14px;
  border-bottom: 1px solid #e5e5e5;
}

.specification .spec-details .spec-name {
  background-color: #eeeeee;
  width: 30%;
  padding: 10px 20px;
  font-weight: 500;
}

.specification .spec-details .spec-value {
  width: 70%;
  padding: 0px 20px;
}

.product_description .reviews .all-reviews {
  border: 1px solid var(--light-gray);
  border-radius: 4px;
  padding: 20px 15px 20px 15px;
}

.reviews .overall-review .txt {
  color: #0e1b28;
  font-size: 15px;
}

.reviews .overall-review .val {
  color: #0e1b28;
  font-size: 30px;
  font-weight: 700;
}

.reviews .overall-review .ratingImg {
  height: 13px;
}

.reviews .reviews_tabs .tab_header {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: nowrap;
  font-size: 15px;
}

.reviews .reviews_tabs .tab_header .txt {
  color: #0e1b28;
  padding-bottom: 4px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.reviews .reviews_tabs .tab_header .txt.active {
  font-weight: 500;
  border-bottom: 2px solid #404b54;
}

.reviews .reviews_tabs .tab_header .txt:hover {
  font-weight: 500;
  border-bottom: 2px solid #404b54;
}

.reviews .review_filter {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 7px;
  scrollbar-width: none;
}

.reviews .review_filter button {
  background-color: #e8f0e3;
  font-size: 13px;
  padding: 5px 18px;
  border-radius: 50px;
  color: var(--green2);
  border: none;
  outline: none;
  box-shadow: none;
  text-wrap: nowrap;
}

.reviews .review_filter button:hover {
  background-color: var(--green2);
  color: var(--white);
}

.reviews .review_filter button.active {
  background-color: var(--green2);
  color: var(--white);
}

.reviews .user-review .profile {
  background-color: #5784ff;
  min-width: 35px;
  min-height: 35px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reviews .user-review .profile img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.reviews .user-review .personal-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.reviews .user-review .personal-info .name {
  color: #0e1b28;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.reviews .user-review .personal-info .date {
  color: #959595;
  font-size: 12px;
  font-weight: 500;
}

.reviews .user-review .ratingImg {
  height: 13px;
}

.reviews .user-review .review_txt {
  font-size: 13px;
  font-weight: 500;
}

.reviews .user-review .reviewImages {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.reviews .user-review .reviewImages img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 4px;
}

.reviews .user-review div:last-child {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.reviews .user-review {
  max-height: 430px;
  overflow-y: auto;
  scrollbar-width: none;
}

.review-pagination {
  display: flex;
  align-items: center;
  gap: 10px;
}

.review-pagination .arrow-btn {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border: none;
  outline: none;
  box-shadow: none;
  background-color: var(--black2) !important;
  color: var(--white) !important;
}

.review-pagination .arrow-btn:disabled {
  background-color: #d7d7d7 !important;
  color: #7b7b7b !important;
}

.review-pagination .pageNo {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 14px;
}

.review-pagination .pageNo span {
  color: var(--black);
}

.review-pagination .pageNo span.active {
  color: var(--green2);
}

.storePolicy .policy-type .name {
  color: var(--black);
  font-size: 18px;
  font-weight: 500;
}

.storePolicy .policy-type .description {
  color: var(--black);
  font-size: 13px;
}

.storePolicy .policy-type .checkBtn {
  color: #0075a7 !important;
  font-size: 14px;
  background-color: transparent !important;
  padding: 0px;
  box-shadow: none;
  outline: none;
}

.storePolicy .policy-type .checkBtn:hover {
  color: #56955f !important;
}

.userChart .user_questionBox {
  position: relative;
}

.userChart .user_questionBox textarea {
  background-color: #eeeeee;
  color: var(--black2);
  font-size: 15px;
  height: 130px;
  padding: 10px 15px;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 4px;
}

.userChart .user_questionBox .postQuesBtn {
  background-color: var(--green2);
  border: 1px solid var(--green2);
  font-size: 11px;
  padding: 6px 15px;
  border-radius: 5px;
  color: var(--white);
  font-weight: 500;
  text-wrap: nowrap;
  position: absolute;
  bottom: 16px;
  right: 11px;
}

.userChart .user_questionBox .postQuesBtn:hover {
  background-color: var(--green-shade);
  border: 1px solid var(--green-shade);
  color: var(--white);
}

.userChart .title-txt {
  color: var(--black);
  font-size: 17px;
  font-weight: 500;
}

.userChart .Ques_Ans hr {
  margin-top: 10px;
  margin-bottom: 7px;
  background-color: var(--light-gray);
}

.userChart .Ques_Ans .name {
  font-size: 15px;
  font-weight: 600;
}

.userChart .Ques_Ans .value {
  font-size: 13px;
}

.userChart .Ques_Ans .likes {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

.userChart .Ques_Ans .likes span {
  font-size: 13px;
}

.userChart .Ques_Ans .like {
  color: var(--green2);
}

.userChart .Ques_Ans .dislike {
  color: #9a9a9a;
}

/* Product Img Zoom start */

.productImg_preview .allImage_list .images_sec {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.productImg_preview .allImage_list .images_sec img {
  width: 100%;
  height: 60px;
  border-radius: 8px;
}

.productImg_preview .arrow-btn {
  margin: 0px;
  padding: 0px;
  width: 100%;
  background: var(--black);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  outline: none;
  border: none;
  height: 18px;
}

.productImg_preview .left {
  display: flex;
  width: 100%;
  gap: 10px;
  /* justify-content: space-between; */
}

.productImg_preview .left_2 {
  z-index: 3;
  position: relative;
}

.productImg_preview .left_1 .allImgs {
  height: 255px;
  overflow-y: auto;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 50px;
}

.productImg_preview .img_wrap {
  width: 50px;
  height: 50px;
  border: 1px solid #eee;
  cursor: pointer;
}

.productImg_preview .img_wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.productImg_preview .left_2 > div {
  width: 290px !important;
  height: 290px !important;
  /* height: 390px !important; */
}

.productImg_preview .left_2 img {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.productImg_preview .active {
  border: 2px solid var(--green2);
}

.productImg_preview .magnify-container {
  width: 270% !important;
  height: 180% !important;
  border: none !important;
}

/* Product Img Zoom end */

/* recently viewed products */

.recentViewed_productCard {
  background-color: var(--white1);
  border: 1px solid #e6e6e6;
  padding: 7px;
  position: relative;
  transition: 1s all ease-in-out;
  position: relative;
  box-shadow: 0px 3px 6px var(--black1);
  border-radius: 3px;
}

.recentViewed_productCard:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: #292929;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  transition: 0.3s all ease-in-out;
  z-index: 1;
}

.recentViewed_productCard:hover:after {
  opacity: 94%;
}

.recentViewed_productCard .img_sec {
  background-color: #f8f8f8;
  width: 100%;
  position: relative;
}

.recentViewed_productCard .img_sec .prod_status {
  padding: 7px 10px;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  color: var(--white);
  align-items: center;
  justify-content: center;
  font-size: 10px;
  background-color: #3267f7;
  font-weight: 500;
  border-radius: 0px 6px 6px 0px;
}

.recentViewed_productCard .img_sec .prod_status img {
  height: 13px !important;
  width: 13px !important;
  object-fit: contain;
}

.recentViewed_productCard .img_sec .productImg {
  padding: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.recentViewed_productCard .img_sec .productImg img {
  width: unset !important;
  transition: 0.2s all ease-in-out;
}

.recentViewed_productCard .product_details {
  padding: 10px 3px 5px;
  background-color: transparent;
}

.recentViewed_productCard .product_details .prod_name {
  color: var(--black);
  font-size: 15px;
  font-weight: 500;
}

.recentViewed_productCard .product_details .prod_ratings {
  display: flex;
  align-items: center;
  gap: 1px;
}

.recentViewed_productCard .product_details .rating_img {
  width: 15px;
  height: 15px;
}

.recentViewed_productCard .prod_price {
  color: var(--black);
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.recentViewed_productCard .prod_price .prod_img {
  height: 17px;
  width: 17px;
  margin-left: 4px;
  margin-right: 4px;
}

.recentViewed_productCard .prod_price .sign_img {
  width: 15px;
  margin-left: 6px;
  margin-right: 6px;
}

.recentViewed_productCard .soldBy {
  color: #0e1b28;
  font-size: 13px;
  font-weight: 500;
}

.recentViewed_productCard .soldBy button {
  color: #1bacea;
  font-size: 13px;
  padding: 0px;
  background-color: transparent !important;
}

.recentViewed_productCard .delivered {
  color: var(--black);
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.recentViewed_productCard .block-chains p {
  color: #0e1b28;
  font-size: 14px;
  font-weight: 600;
}

.recentViewed_productCard .block-chains img {
  height: 18px !important;
  width: 18px !important;
}

.recentViewed_productCard .shopImg {
  filter: brightness() invert();
  height: 17px !important;
  width: 17px !important;
}

.recentViewed_productCard .hvr-details .hide_btns {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  gap: 10px;
}

.recentViewed_productCard .hvr-details button {
  background-color: var(--green2);
  border: 1px solid var(--green2);
  font-size: 12px;
  padding: 8px 15px;
  border-radius: 5px;
  color: var(--white);
  font-weight: 500;
  text-wrap: nowrap;
  /* position: absolute;
  bottom: 16px;
  right: 11px; */
}

.recentViewed_productCard .hvr-details button:hover {
  background-color: var(--green-shade);
  border: 1px solid var(--green-shade);
  color: var(--white);
}

.recentViewed_productCard .hvr-details {
  width: 70%;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, -15px);
  z-index: 2;
  opacity: 0;
  transition: 0.3s all ease-in-out;
}

.recentViewed_productCard:hover .hvr-details {
  opacity: 1;
}

.all_quesAns > div {
  margin-bottom: 10px;
}

.all_quesAns > div:last-child {
  margin-bottom: 0px;
}

.filter-slider {
  background-color: var(--white);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0px 4px 4px 0px;
  padding: 6px 13px;
  width: -moz-fit-content;
  width: fit-content;

  position: fixed;
  top: 68px;
  left: 0px;
  z-index: 2;
}

.selectFilter-offcanvas {
  width: 270px !important;
}

.selectFilter-offcanvas .offcanvas-body {
  padding: 0px;
  scrollbar-width: none;
}

.select-filter-sec-mbl {
  display: none;
}

.select-filter-sec-web {
  display: block;
  position: sticky;
  top: 68px;
}

.searchProduct_filter .close_offCanvas {
  display: none;
}

.selectFilter-offcanvas .searchProduct_filter .close_offCanvas {
  display: block;
}

.searchProduct_filter .close_offCanvas {
  background-color: transparent !important;
  border: none !important;
  outline: none;
  padding: 0px !important;
}

.searchProduct_filter .close_offCanvas img {
  width: 25px;
  height: 25px;
}

/* Cart header */
.cart_header {
  background: #f8f8f8;
  box-shadow: 0px 0px 6px var(--black1);
  margin-top: 20px;
  color: #0e1b28;
  font-weight: 500;
  font-size: 15px;
  padding: 14px;
  display: flex;
  align-items: center;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.cart_header .breadcrump_list {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cart_header .breadcrump_list span {
  text-wrap: nowrap;
}

.cart_header .breadcrump_list svg {
  min-width: 15px;
  min-height: 15px;
}

.cart_header .breadcrump_list .current-txt {
  color: var(--green2);
}

.cart_header .share_btn {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0px !important;
  border-radius: 0px;
  background: transparent !important;
  border: none !important;
  color: #0e1b28 !important;
}

.shopping_Cart {
  background: var(--white1);
  border-radius: 8px;
  box-shadow: 0px 0px 6px 0px var(--light-gray);
}

.shopping_Cart .shipHeading {
  background-color: #2e2e2e;
  color: var(--white);
  font-size: 15px;
  padding: 8px 15px;
  font-weight: 500;
  border-radius: 7px 7px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.shopping_Cart .shipHeading .locationBtn {
  background: transparent !important;
  color: var(--white) !important;
  outline: none;
  box-shadow: none;
  border: none !important;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 0px;
}

.shopping_Cart .shipHeading .locationBtn:hover {
  color: var(--green2) !important;
}

.shoppingcart_product {
  display: flex;
  gap: 20px;
  width: 100%;
  border: 1px solid var(--light-gray);
  padding: 15px;
  border-radius: 3px;
}

.shoppingcart_product .Img {
  /* width: 13%; */
}

.shoppingcart_product .prod_img {
  width: 85px;
  height: 85px;
  object-fit: contain;
}

.shoppingcart_product .details {
  width: 100%;
}

.shoppingcart_product .details .name {
  color: var(--black);
  font-size: 12px;
  font-weight: 600;
}

.shoppingcart_product .details .amount {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
}

.shoppingcart_product .details .amount .coin {
  display: flex;
  align-items: center;
  gap: 4px;
}

.shoppingcart_product .coinImg {
  width: 17px;
  height: 17px;
}

.shoppingcart_product .amount .signImg {
  height: 5px;
  margin-left: 5px;
  margin-right: 3px;
}

.shoppingcart_product .quantity button {
  background-color: var(--light-gray2) !important;
  font-size: 10px !important;
  color: #0e1b28 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 4px;
  padding: 3px 6px;
  font-weight: 500 !important;
}

.shoppingcart_product .quantity .dropdown-menu {
  width: 50px;
  min-width: 50px;
  padding: 4px 0px;
}

.shoppingcart_product .quantity .dropdown-item {
  padding: 0px;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
}

.shoppingcart_product .quantity .dropdown-item:hover {
  background-color: var(--light-gray2) !important;
}

.shoppingcart_product .prod_subInfo {
  display: flex;
  align-items: center;
  gap: 15px;
  /* font-weight: 500; */
  flex-wrap: wrap;
}

.shoppingcart_product .prod_subInfo .delete {
  color: #0e1b28;
  font-size: 11px;
  font-weight: 500;
  cursor: pointer;
  background-color: transparent !important;
  padding: 0px;
  text-decoration: none;
}

.shoppingcart_product .prod_subInfo .delete:hover {
  color: var(--green2);
}

.shoppingcart_product .prod_subInfo .saveForlater {
  color: #0e1b28;
  font-size: 11px;
  font-weight: 500;
  cursor: pointer;
  background-color: transparent !important;
  padding: 0px;
  text-decoration: none;
}

.shoppingcart_product .prod_subInfo .saveForlater:hover {
  color: var(--green2);
}

.shoppingcart_product .prod_subInfo .escrow_lock {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.shoppingcart_product .prod_subInfo .lock_lottie {
  width: 32px !important;
  height: 32px !important;
}

.shoppingcart_product .prod_subInfo .a {
  color: #0e1b28;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
  text-wrap: nowrap;
}

.shoppingcart_product .prod_subInfo .dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--white1);
  border-radius: 4px;
  box-shadow: 0px 0px 6px var(--black1);
  padding: 7px 12px;
  min-width: 200px;
  z-index: 1;
}

.shoppingcart_product .prod_subInfo .heading_txt {
  color: #0e1b28;
  font-size: 12px;
  font-weight: 500;
}

.shoppingcart_product .prod_subInfo .info_details {
  font-size: 10px;
  font-weight: 500;
}

.shoppingcart_product .prod_subInfo .more-info {
  color: var(--bink);
  font-size: 11px;
  font-weight: 500;
}

.shoppingcart_product .prod_subInfo .more-info a {
  color: var(--bink);
}

.shoppingcart_product .prod_subInfo .escrow_lock .ques {
  width: 13px;
  height: 13px;
  background-color: #0e1b28 !important;
  color: var(--white) !important;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  outline: none;
  box-shadow: none;
  border-radius: 50%;
}

.shoppingcart_product .prod_subInfo .soldBy {
  color: #0e1b28;
  font-size: 11px;
  font-weight: 600;
}

.shoppingcart_product .prod_subInfo .currentStore {
  color: #0391c7;
  font-size: 11px;
  font-weight: 500;
  background-color: transparent !important;
  padding: 0px;
}

.shoppingcart_product .prod_subInfo .currentStore:hover {
  color: var(--green2);
}

.shoppingcart_product .delivery-date {
  color: #0e1b28;
  font-weight: 600;
  font-size: 12px;
}

.shopping_Cart .subTotal {
  background-color: var(--black);
  padding: 10px;
  color: var(--white1);
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
}

.subTotal .amount {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
}

.subTotal .details .amount .coin {
  display: flex;
  align-items: center;
  gap: 4px;
}

.subTotal .coinImg {
  width: 17px;
  height: 17px;
}

.subTotal .amount .signImg {
  height: 5px;
  margin-left: 5px;
  margin-right: 3px;
  filter: brightness(0.5) invert(1);
}

.shopping_Cart .promocode {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.shopping_Cart .promocode .promocodeInp {
  border: 1px solid #e8e8e8;
  padding: 8px 20px;
  font-size: 10px;
  border-radius: 8px;
  width: 100%;
  outline: none;
}

.shopping_Cart .promocode .discountBtn {
  border-radius: 8px;
  font-size: 13px !important;
  font-weight: 500;
  padding: 6px 20px !important;
  text-wrap: nowrap;
}

.shoppingCart_row .col {
  margin-bottom: 15px;
}

.shoppingCart_row .col:last-child {
  margin-bottom: 0px;
}

.shoppingCart_row {
  max-height: 336px;
  overflow-y: auto;
}

.product_summary {
}

.product_summary {
  background: var(--white1);
  border-radius: 8px;
  box-shadow: 0px 0px 6px 0px var(--light-gray);
}

.product_summary .heading_txt {
  background-color: #2e2e2e;
  color: var(--white);
  font-size: 15px;
  padding: 8px 15px;
  font-weight: 500;
  border-radius: 7px 7px 0px 0px;
}

.product_summary .cmnTxt {
  color: var(--black);
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.product_summary .cmnTxt .arrow {
  width: 14px;
  height: 14px;
  background-color: #0e1b28 !important;
  color: var(--white) !important;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  outline: none;
  box-shadow: none;
  border-radius: 50%;
}

.product_summary .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  flex-wrap: wrap;
  gap: 7px;
}

.product_summary .info .amount {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  /* width: 50%; */
  justify-content: end;
}

.product_summary .info .amount .coin {
  display: flex;
  align-items: center;
  gap: 4px;
}

.product_summary .info .coinImg {
  width: 17px;
  height: 17px;
}

.product_summary .info .amount .signImg {
  height: 5px;
  margin-left: 5px;
  margin-right: 3px;
}

.product_summary .info .cmnTxt {
  /* width: 50%; */
}

.productPrice_summary {
  display: flex;
  gap: 7px;
  width: 100%;
}

.productPrice_summary .prod_img {
  width: 43px;
  height: 43px;
  object-fit: contain;
}

.productPrice_summary .details {
  width: 100%;
}

.productPrice_summary .details .name {
  color: var(--black);
  font-size: 9px;
  font-weight: 600;
}

.productPrice_summary .details .amount {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 9px;
}

.productPrice_summary .details .amount .coin {
  display: flex;
  align-items: center;
  gap: 4px;
}

.productPrice_summary .coinImg {
  width: 17px;
  height: 17px;
}

.productPrice_summary .details .amount .new-amt {
  color: #dd6e3d;
  font-size: 11px !important;
}

.productPrice_summary .prod_subInfo {
  display: flex;
  align-items: center;
  gap: 7px;
}

.productPrice_summary .quantity button {
  background-color: var(--light-gray2) !important;
  font-size: 9px !important;
  color: #0e1b28 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 4px;
  padding: 3px 6px;
  font-weight: 500 !important;
}

.productPrice_summary .quantity .dropdown-menu {
  width: 45px;
  min-width: 45px;
  padding: 4px 0px;
}

.productPrice_summary .quantity .dropdown-item {
  padding: 0px;
  font-size: 9px;
  font-weight: 500;
  text-align: center;
}

.productPrice_summary .quantity .dropdown-item:hover {
  background-color: var(--light-gray2) !important;
  color: var(--black) !important;
}

.productPrice_summary .prod_subInfo .delete {
  color: #0e1b28;
  font-size: 9px;
  font-weight: 500;
  cursor: pointer;
  background-color: transparent !important;
  padding: 0px;
  text-decoration: none;
}

.productPrice_summary .prod_subInfo .delete:hover {
  color: var(--green2);
}

.productPrice_summary .prod_subInfo .saveForlater {
  color: #0e1b28;
  font-size: 9px;
  font-weight: 500;
  cursor: pointer;
  background-color: transparent !important;
  padding: 0px;
  text-decoration: none;
  text-wrap: nowrap;
}

.productPrice_summary .prod_subInfo .saveForlater:hover {
  color: var(--green2);
}

.productPrice_summary .prod_subInfo .escrow_lock {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.productPrice_summary .prod_subInfo .lock_lottie {
  width: 22px !important;
  height: 22px !important;
}

.productPrice_summary .prod_subInfo .a {
  color: #0e1b28;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
  text-wrap: nowrap;
  font-size: 9px;
}

.productPrice_summary .prod_subInfo .dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--white1);
  border-radius: 4px;
  box-shadow: 0px 0px 6px var(--black1);
  padding: 7px 12px;
  min-width: 200px;
  z-index: 1;
  right: 0px;
}

.productPrice_summary .prod_subInfo .info_details {
  font-size: 9px;
  font-weight: 500;
}

.productPrice_summary .prod_subInfo .more-info {
  color: var(--bink);
  font-size: 9px;
  font-weight: 500;
}

.productPrice_summary .prod_subInfo .more-info a {
  color: var(--bink);
}

.productPrice_summary .prod_subInfo .escrow_lock .ques {
  width: 11px;
  height: 11px;
  background-color: #0e1b28 !important;
  color: var(--white) !important;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  outline: none;
  box-shadow: none;
  border-radius: 50%;
}

.productPrice_summary .prod_subInfo .header_txt {
  color: #0e1b28;
  font-size: 11px;
  font-weight: 500;
}

.product_summary .shoppingCart_row {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: auto;
}

.product_summary .shoppingCart_row .col {
  margin-bottom: 10px;
}

.product_summary .shoppingCart_row .col:last-child {
  margin-bottom: 0px;
}

.product_summary .checkedImg {
  width: 15px;
  height: 15px;
}

.discount_promo {
  padding: 0px 5px;
}

.discount_promo .header-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
}

.discount_promo .shopFavourite {
  background-color: #eef6e9;
  color: var(--green2);
  font-size: 11px;
  border-radius: 5px;
  padding: 10px 14px;
  line-height: 16px;
}

.discount_promo .promocodeInp {
  position: relative;
}

.discount_promo .promocodeInp input {
  border: 1px solid #e8e8e8;
  padding: 6px 20px;
  font-size: 13px;
  border-radius: 8px;
  width: 100%;
  outline: none;
  width: 100%;
  font-weight: 500;
}

.discount_promo .promocodeInp .applyBtn {
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 8px;
  font-size: 13px !important;
  font-weight: 500;
  padding: 6px 20px !important;
  text-wrap: nowrap;
}

/* .shopping_Cart .promocodeInp input::placeholder {
  font-size: 15px !important;
  font-weight: 500;
} */

.discount_Promo .item {
  background-repeat: no-repeat;
  width: 100%;
  /* height: 120px; */
  background-size: 100% 100%;
  background-position: center;
  padding: 12px;
  /* margin-bottom: 10px; */
}

.discount_Promo .item .des {
  color: var(--white);
  font-size: 11px;
  font-weight: 500;
}

.discount_Promo .item .amount {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 10px;
}

.discount_Promo .item .amount .coin {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  font-size: 12px;
  background-color: var(--white);
  color: var(--black);
  border-radius: 50px;
  padding: 2px 5px;
  width: -moz-fit-content;
  width: fit-content;
}

.discount_Promo .item .amount .coinImg {
  width: 15px;
  height: 15px;
}

.discount_Promo .item .amount .checked {
  width: 15px;
  height: 15px;
}

.discount_Promo .item .amount .signImg {
  height: 4.5px;
  filter: invert();
}

.discount_Promo .item .amount .amt-txt {
  color: var(--white);
  font-size: 12px;
  font-weight: 500;
}

.discount_Promo .item .coupon-id {
  background-color: var(--white);
  width: 100%;
  height: 30px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 6px;
  color: var(--black);
  font-size: 11px;
  font-weight: 500;
  padding: 0px 5px 0px 8px;
  margin-top: 10px;
}

.discount_Promo .item .coupon-id .address {
  position: relative;
  top: 2px;
}

.discount_Promo .item .coupon-id .apply-btn {
  font-size: 11px;
  font-weight: 500;
  border-radius: 50px;
  padding: 3px 17px;
  position: relative;
  top: 0.5px;
}

.shippingAddress .AddressInp {
  position: relative;
}

.shippingAddress .AddressInp input {
  border: 1px solid #e8e8e8;
  padding: 7px 20px;
  font-size: 12px;
  border-radius: 8px;
  width: 100%;
  outline: none;
  width: 100%;
  font-weight: 500;
}

.shippingAddress .AddressInp .cnfmBtn {
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 8px;
  font-size: 12px !important;
  font-weight: 500;
  padding: 7px 15px !important;
  text-wrap: nowrap;
  background-color: #2e2e2e !important;
  color: var(--white) !important;
  border: none !important;
  outline: none;
  box-shadow: none;
}

.product_summary .reloadImg {
  height: 12px;
  margin-left: 3px;
}

.product_summary .btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.product_summary .shopMore_btn {
  background-color: var(--black) !important;
  color: var(--white) !important;
  padding: 8px 35px;
  border-radius: 50px;
  border: 1px solid var(--black) !important;
  font-weight: 500;
  font-size: 13px;
  text-wrap: nowrap;
}

.product_summary .checkout_btn {
  padding: 8px 35px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 13px;
  text-wrap: nowrap;
}

.product_summary .freeDelivery_eligible {
  color: #61ab2e;
  font-size: 14px;
  text-align: center;
}

.product_summary .freeDelivery_eligible .checkedImg {
  width: 17px;
  height: 17px;
}

/* end of shipping cart */

/* shipping address */
.shippingAddress_list {
  background: var(--white1);
  border-radius: 8px;
  box-shadow: 0px 0px 6px 0px var(--light-gray);
}

.shippingAddress_list .shipHeading {
  background-color: #2e2e2e;
  color: var(--white);
  font-size: 15px;
  padding: 8px 15px;
  font-weight: 500;
  border-radius: 7px 7px 7px 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.shippingAddress_list .address_details {
  padding: 20px;
}

.shippingAddress_list .address_details .addAddress_Btn {
  color: #57a32a;
  font-weight: 500;
  font-size: 15px;
  background-color: transparent !important;
  padding: 0px;
}

.shippingAddress_list .address_details .addAddress_Btn:hover {
  color: var(--green-shade);
}

.shippingAddress_list .address_lists .item {
  margin-bottom: 10px;
  display: flex;
  align-items: start;
  gap: 15px;
}

.shippingAddress_list .address_lists .item:last-child {
  margin-bottom: 0px;
}

.shippingAddress_list .address_lists .add-headtxt {
  font-size: 15px;
  font-weight: 600;
}

.shippingAddress_list .address_lists .addDetails {
  font-size: 12px;
}

/* Frequently Asked Qus start */

.frequentlyAskQus {
  background: var(--white1);
  border-radius: 8px;
  box-shadow: 0px 0px 6px 0px var(--light-gray);
}

.frequentlyAskQus .shipHeading {
  background-color: #2e2e2e;
  color: var(--white);
  font-size: 15px;
  padding: 8px 15px;
  font-weight: 500;
  border-radius: 7px 7px 7px 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.frequentlyAskQus .allQusAns {
  padding: 20px;
}

.qusAns_accordion .accordion-button {
  background-color: transparent !important;
  color: #2e2e2e !important;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid var(--light-gray2) !important;
  box-shadow: none !important;
  padding: 12px 12px 12px 20px;
  border-radius: 8px;
  width: 100%;
}

.qusAns_accordion .accordion-button:hover {
  background-color: var(--light-gray2) !important;
}

.qusAns_accordion .accordion-item {
  border: none;
  margin-bottom: 20px;
}

.qusAns_accordion .accordion-item:last-child {
  margin-bottom: 0px;
}

.qusAns_accordion .accordion-body {
  font-size: 11px;
  font-weight: 500;
}

.qusAns_accordion .accordion-button::after {
  display: none;
}

.qusAns_accordion .accordion-button::before {
  content: "";
  display: inline-block;
  width: 7px;
  height: 100%;
  background-color: transparent;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 8px 0px 0px 8px;
}

.qusAns_accordion .accordion-button:hover:before {
  content: "";
  display: inline-block;
  width: 7px;
  height: 100%;
  background-color: var(--black2);
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 8px 0px 0px 8px;
}

.qusAns_accordion .accordion-button:not(.collapsed) {
  background-color: var(--light-gray2) !important;
}

.qusAns_accordion .accordion-button:not(.collapsed):before {
  background-color: var(--black2);
}

/* Frequently Asked Qus end */

/* Checkout Order details start */

.order_details .freeDelivery_eligible {
  padding: 15px 0px;
  border-top: 1px solid var(--light-gray);
  border-bottom: 1px solid var(--light-gray);
}

.order_details .escrow_lock {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.order_details .lock_lottie {
  width: 40px !important;
  height: 40px !important;
}
.order_details .escrow_lock .a {
  color: #0e1b28;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 6px;
}

.order_details .escrow_lock .ques {
  width: 13px;
  height: 13px;
  background-color: #0e1b28 !important;
  color: var(--white) !important;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  outline: none;
  box-shadow: none;
  border-radius: 50%;
}
.order_details .dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--white1);
  border-radius: 4px;
  box-shadow: 0px 0px 6px var(--black1);
  padding: 7px 12px;
  min-width: 160px;
  z-index: 1;
}
.order_details .hidden_info .heading_txtt {
  color: #0e1b28;
  font-size: 12px;
  font-weight: 500;
}

.order_details .hidden_info .info_details {
  font-size: 10px;
  font-weight: 500;
}

.order_details .hidden_info .more-info {
  color: var(--bink);
  font-size: 11px;
  font-weight: 500;
}

.order_details .hidden_info .more-info a {
  color: var(--bink);
}

.order_details .soldBy {
  color: #0e1b28;
  font-size: 13px;
  font-weight: 600;
  border-top: 1px solid var(--light-gray);
  /* border-bottom: 1px solid var(--light-gray); */
}

.order_details .soldBy button {
  color: #1bacea;
  font-size: 13px;
  padding: 0px;
  background-color: transparent !important;
}

.order_details .account {
  padding: 15px 15px;
  color: #0e1b28;
  font-size: 13px;
  font-weight: 600;
  border-top: 1px solid var(--light-gray);
}
.order_details .account .accImg {
  height: 25px;
}

.order_details .account .acc_Dec {
  font-size: 12px;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.order_details .account .arrowBtn {
  width: 14px;
  height: 14px;
  background-color: #0e1b28 !important;
  color: var(--white) !important;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  outline: none;
  box-shadow: none;
  border-radius: 50%;
}

.order_details .priceLocked_btn {
  background-color: var(--black) !important;
  color: var(--white) !important;
  padding: 9px 20px;
  border-radius: 50px;
  border: 1px solid var(--black) !important;
  font-weight: 500;
  font-size: 13px;
  text-wrap: nowrap;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order_details .product_summary {
  border-radius: 20px;
}

.order_details .head_sec {
  background-color: #5ea92d;
  padding: 15px 15px 30px 15px;
  text-align: center;
  border-radius: 20px 20px 0px 0px;
  margin-bottom: -20px;
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.order_details .details_sec {
  border-radius: 25px;
  background-color: var(--white);
  position: relative;
  z-index: 1;
}

.order_details .foot_sec {
  background-color: #2e2e2e;
  padding: 31px 16px 10px 16px;
  text-align: center;
  border-radius: 0px 0px 20px 20px;
  color: var(--white);
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
}

.order_details .head_sec .shoppingImg {
  height: 20px;
}

.order_details .foot_sec .amount {
  display: flex;
  align-items: center;
  justify-content: end;
}

.order_details .foot_sec .coinImg {
  width: 15px;
  height: 15px;
}
.order_details .foot_sec .amount .coin {
  display: flex;
  align-items: center;
  gap: 4px;
}

.order_details .foot_sec .amount .signImg {
  height: 5px;
  margin-left: 5px;
  margin-right: 3px;
  filter: brightness() invert();
}

.order_details .payment_completed_lottie svg {
  width: 120px !important;
  height: 120px !important;
}

.order_details .payment_completed_cnt {
  padding: 40px 0px 40px 0px;
  text-align: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.payment_completed_cnt .title-txt {
  color: #509e27;
  font-size: 20px;
  font-weight: 600;
}
.payment_completed_cnt .des {
  color: var(--black);
  font-size: 11px;
  font-weight: 500;
}

.order_details .payComp_lottie {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0px 30px 0px;
}

/* .productImg_preview .left_2 {
  width: 100%;
}
.productImg_preview .left_2 > div {
  width: 100% !important;
  height: 290px !important;
}
.productImg_preview .left_2 img {
  object-fit: unset !important;
  height: 100% !important;
}
.prodDetail .productPhotos .img_sec {
  height: 45px;
}
.productImg_preview .magnify-container {
  display: none !important;
} */

.order_details .product_summary .freeDelivery_eligible {
  font-size: 13px;
}
.order_details .payment_completed_cnt {
  width: 90%;
}
.order_details .foot_sec {
  flex-wrap: wrap;
  justify-content: center;
}

/* Checkout Order details end */

/* checkout trust message start */
.checkout .trust_message_deta {
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  gap: 10px;
  border-radius: 8px;
}

.checkout .trust_message_deta .lock_lottie svg {
  width: 30px !important;
  height: 30px !important;
}
/* checkout trust message end */

/* Add Address Modal popup start */
.addresspage .cmn_linearBtn {
  background: transparent
    linear-gradient(83deg, var(--green-shade) 0%, var(--green2) 100%) 0% 0%
    no-repeat padding-box;
  color: var(--white);
  outline: none;
  box-shadow: none;
  border: 1px solid var(--green-shade) !important;
  width: 150px;
}

.address_modal .title-txt {
  font-size: 18px;
}

.address_modal .address_info .form-label {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
}

.address_modal .address_info .form-control {
  height: 35px;
  width: 100%;
  border: 1px solid var(--light-gray);
  border-radius: 0.375rem;
  font-size: 13px;
  color: var(--black);
  box-shadow: none;
  padding: 0.375rem 1rem;
}

.address_modal .address_info .form-control::placeholder {
  color: #7d7d7d;
}

.country-name-input .ReactFlagsSelect-module_selectBtn__19wW7 {
  height: 35px;
  border: 1px solid var(--light-gray);
  font-size: 13px !important;
}

.country-name-input .ReactFlagsSelect-module_selectOptionValue__vS99- {
  font-size: 12px !important;
}

.country-mobile-input .form-control {
  padding: 0.375rem 1rem 0.375rem 48px !important;
}

.country-mobile-input .country {
  font-size: 13px;
}

.country-mobile-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}

.country-mobile-input .selected-flag {
  padding: 0 0 0 1rem !important;
}

.address_modal .address_info textarea {
  min-height: 100px;
}

.address_modal .commonRadio_btn .form-check-label {
  color: var(--green2);
  font-size: 14px;
  font-weight: 500;
}

.address_modal .btns {
  display: flex;
  align-items: center;
  gap: 30px;
}

.address_modal .btns .cancelBtn,
.address_modal .btns .cnfmBtn {
  padding: 8px 40px !important;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px !important;
}

/* Add Address Modal popup end */

/* Connect Wallet Modal start */

.connect_wallet_modal .wallet:hover {
  background-color: var(--black) !important;
}

.connect_wallet_modal .wallet {
  justify-content: space-between;
}
.connect_wallet_modal .wallet .walletInfo {
  display: flex;
  align-items: center;
  gap: 15px;
}

.connect_wallet_modal .wallet .checkImg {
  display: none;
}
.connect_wallet_modal .wallet:hover > .checkImg {
  display: block;
}
.connect_wallet_modal .wallet.active {
  background-color: var(--black) !important;
  color: var(--white) !important;
}
.connect_wallet_modal .wallet.active > .checkImg {
  display: block;
}

/* Dashboard ui */
.shipping-management {
  margin-top: 30px;
}
.shipping-management .title_txt {
  color: #191919;
}

.shippingType_tab {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shippingType_tab .shippingType_head {
  background-color: #eff1f3;
  border: 0.5px solid #cfd0d6;
  border-radius: 12px;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.shippingType_tab .shipping_val {
  border-radius: 20px;
  outline: none;
  box-shadow: none;
  font-size: 13px;
  min-width: 160px;
  padding: 6px 25px;
  background-color: #f9fbfc !important;
  border: 1px solid #c4c6c6 !important;
  color: #191919 !important;
}

.shippingType_tab .shipping_val.active {
  background: transparent linear-gradient(280deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box !important;
  color: var(--white) !important;
  border: 1px solid transparent !important;
}

.shippingType_tab .shipping_val:hover {
  background: transparent linear-gradient(280deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box !important;
  color: var(--white) !important;
  border: 1px solid transparent !important;
}

.cmn_shipping_types {
  margin-top: 30px;
  border: 1px solid #c4c6c6 !important;
  border-radius: 10px;
}

.cmn_shipping_types .shippingType_head {
  background-color: #eff1f3;
  border-bottom: 1px solid #cfd0d6;
  border-radius: 10px;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  gap: 25px;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.cmn_shipping_types .shipping_types_val {
  border-radius: 20px;
  outline: none;
  box-shadow: none;
  font-size: 13px;
  padding: 6px 25px;
  background-color: #eff1f3 !important;
  border: 1px solid #c4c6c6 !important;
  color: #191919 !important;
  min-width: 160px;
}

.cmn_shipping_types .shipping_types_val.active {
  background: transparent linear-gradient(280deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box !important;
  color: var(--white) !important;
  border: 1px solid transparent !important;
}

.cmn_shipping_types .shipping_types_val:hover {
  background: transparent linear-gradient(280deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box !important;
  color: var(--white) !important;
  border: 1px solid transparent !important;
}

.Cmn_shipping_details {
  padding: 30px 40px;
}

.shippingBy_Country .Sub_heading {
  display: flex;
  align-items: center;
  gap: 25px;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 5px;
}
.shippingBy_Country .Sub_heading .Sub_heading_heading {
  border-radius: 20px;
  outline: none;
  box-shadow: none;
  font-size: 13px;
  padding: 6px 25px;
  background-color: #eff1f3 !important;
  border: 1px solid #c4c6c6 !important;
  color: #191919 !important;
  min-width: 160px;
}
.shippingBy_Country .Sub_heading .Sub_heading_heading.active {
  background: transparent linear-gradient(280deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box !important;
  color: var(--white) !important;
  border: 1px solid transparent !important;
}

.shippingBy_Country .Sub_heading .Sub_heading_heading:hover {
  background: transparent linear-gradient(280deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box !important;
  color: var(--white) !important;
  border: 1px solid transparent !important;
}

.create_shippingcard {
  margin-top: 40px;
}

.create_shippingcard .shippingDetails_lft {
  border: 1px solid #c4c6c6 !important;
  border-radius: 10px;
  padding: 20px;
}

.create_shippingcard .shippingDetails_lft .name {
  color: #0e1b28;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.create_shippingcard .shippingDetails_lft .infoImg {
  color: #0e1b28;
  height: 16px;
  /* margin-left: 2px; */
}

.shippingDetails_lft .cmnInp_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 15px;
}

.shippingDetails_lft .cmnInp_details:last-child {
  margin-bottom: 0px;
}

.shippingDetails_lft .cmnInp_details .inpVal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.shippingDetails_lft .cmnInp_details .remove {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 5px;
}
.shippingDetails_lft .cmnInp_details .remove .rmv_txt {
  color: #191919;
  font-size: 8px;
}

.shippingDetails_lft .cmnInp_details .inpVal input {
  background-color: #eff1f3;
  width: 175px;
  border: none;
  outline: none;
  height: 35px;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  color: #191919;
  font-size: 14px;
}

.shippingDetails_lft .cmnInp_details .inpVal input::placeholder {
  color: #191919;
}

.create_shippingcard .shippingDetails_lft .removeImg {
  height: 20px;
  cursor: pointer;
}

.create_shippingcard .create_cardBtn {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create_shippingcard .create_cardBtn button {
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 18px;
}

.shippingProd_Details_ryt {
  border: 1px solid #c4c6c6 !important;
  border-radius: 10px;
  padding: 20px;
}

.shippingProd_Details_ryt .name {
  color: #0e1b28;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 7px;
}

.shippingProd_Details_ryt .infoImg {
  color: #0e1b28;
  height: 16px;
}

.shippingProd_Details_ryt .details_show {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 15px;
}
.shippingProd_Details_ryt .details_show:last-child {
  margin-bottom: 0px;
}

.shippingProd_Details_ryt .details_show .price_info {
  background-color: #eff1f3;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding: 12px;
  border-radius: 5px;
}

.shippingProd_Details_ryt .details_show .amount {
  background-color: #eff1f3;
  /* font-size: 12px; */
  padding: 7px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.shippingProd_Details_ryt .details_show .amount button {
  background-color: transparent !important;
  padding: 0px !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: #0e1b28;
  cursor: pointer;
}

.shippingProd_Details_ryt .details_show .amount button svg {
  font-size: 8px;
}

.shippingProd_Details_ryt .details_show .amount .value {
  font-size: 12px;
}

.completed_shippingCard {
  margin-top: 40px;
}

.completed_shippingCard .custom_Card {
  background-color: #000000;
  border-radius: 10px;
  width: 100%;
}

.completed_shippingCard .head_sec {
  background: transparent linear-gradient(280deg, #4b9a24 0%, #9cd947 100%) !important;
  color: var(--white) !important;
  border: none !important;
  border-radius: 8px;
  padding: 11px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.completed_shippingCard .content_sec {
  padding: 20px;
}

.completed_shippingCard .content_sec .label {
  color: #8d8d8d;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 17px !important;
}
.completed_shippingCard .content_sec .label:last-child {
  margin-bottom: 0px !important;
}
.completed_shippingCard .content_sec .label .val {
  color: #ffffff;
}

.completed_shippingCard .btns_sec {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.completed_shippingCard .btns_sec button {
  background-color: #242323;
  color: #8d8d8d;
  min-width: 90px;
  padding: 8px 20px;
  font-size: 12px;
  border: none !important;
  outline: none;
  box-shadow: none;
  border-radius: 5px;
}

.completed_shippingCard .btns_sec button:hover {
  background-color: #8d8d8d;
  color: var(--black);
}

.completed_shippingCard .col {
  margin-bottom: 20px;
}
.completed_shippingCard .col:last-child {
  margin-bottom: 20px;
}

.delete_shipping_modal {
}

.delete_shipping_modal .header-section {
  display: flex;
  align-items: start;
  gap: 14px;
}
.delete_shipping_modal .header-section .warningImg {
  height: 25px;
  position: relative;
  top: 3px;
}

.delete_shipping_modal .header-section .txt {
  font-size: 14px;
  font-weight: 500;
}

.delete_shipping_modal .btn_sec {
  display: flex;
  align-items: center;
  gap: 20px;
}

.delete_shipping_modal .btn_sec .cancelBtn {
  border-radius: 4px;
  padding: 6px 20px;
  font-size: 13px;
  font-weight: 500;
  width: 90px;
}

.delete_shipping_modal .btn_sec .try_anotherBtn {
  border-radius: 4px;
  padding: 6px 20px;
  font-size: 13px;
  font-weight: 500;
  width: 90px;
}

.deleted_shippingCard .custom_Card .head_sec {
  background: transparent linear-gradient(277deg, #dd3d4c 0%, #dd3d4c 100%) !important;
}

.shippingDetails_lft .set_unitDrop {
  background-color: #eff1f3;
  width: 175px;
  border: none;
  outline: none;
  /* height: 35px; */
  border-radius: 10px;
  padding: 5px 20px;
  color: #191919;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.shippingDetails_lft .set_unitDrop hr {
  margin: 0.5rem;
  width: 90%;
}

.shippingDetails_lft .setRules_details {
  font-size: 13px;
  padding: 12px 20px;
  background-color: #eff1f3 !important;
  border: 1px solid #c4c6c6 !important;
  /* color: #191919 !important; */
  border-radius: 10px;
}

.setRules_details .cmnRules {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.setRules_details .cmnRules .label {
  color: #0e1b28;
  font-size: 11px;
  text-wrap: nowrap;
  width: 20%;
}
.weight_rule_select {
  width: 100%;
}

/* setRule react select start */
.setRules_select__control {
  background-color: #f9fbfc !important;
  padding: 0px 3px;
  font-size: 10px;
  border-radius: 8px !important;
  min-height: 31px !important;
  max-height: 31px !important;
  border: none !important;
  outline: none !important;
  box-shadow: 0px 0px 1px 1px #cccc !important;
}

.setRules_select__single-value {
  color: #504e4e !important;
  font-size: 10px;
}
.setRules_select__indicator-separator {
  display: none;
}
.setRules_select__indicator.setRules_select__dropdown-indicator {
  padding: 0 !important;
  padding-right: 3px !important;
}

.setRules_select__indicator.setRules_select__dropdown-indicator svg {
  fill: gray !important;
  height: 15px !important;
  width: 15px !important;
}

.setRules_select__option {
  font-size: 10px !important;
}
.setRules_select__option:hover {
  background-color: #eff1f3;
  color: black;
}

.setRules_select__option--is-focused,
.setRules_select__option--is-selected {
  background-color: #eff1f3 !important;
  color: black !important;
}

/* setRule react select end */

.setRules_details .weight_amt {
  width: 80%;
}

.setRules_details .weight_amt input {
  background-color: #f9fbfc !important;
  padding: 0px 12px;
  font-size: 10px;
  border-radius: 8px !important;
  min-height: 31px !important;
  max-height: 31px !important;
  border: none !important;
  outline: none !important;
  box-shadow: 0px 0px 1px 1px #cccc !important;
  color: #504e4e;
  width: 100%;
}

.setRules_details .weight_amt input::placeholder {
  color: #504e4e;
}

.setRules_details .price_details {
  background-color: #f9fbfc !important;
  padding: 0px 12px;
  font-size: 10px;
  border-radius: 8px !important;
  min-height: 31px !important;
  max-height: 31px !important;
  border: none !important;
  outline: none !important;
  box-shadow: 0px 0px 1px 1px #cccc !important;
  color: #000;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  position: relative;
}

.setRules_details .price_details .dollor {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  width: 45px;
  height: 100%;
  border-radius: 8px;
  background-color: #eff1f3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.setRules_details .price_details .coinImg {
  height: 17px;
}

.setRules_details .price_details .coin {
  display: flex;
  align-items: center;
  gap: 5px;
}

.cmnInp_details .Price_Val {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.cmnInp_details .Price_Val .price_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 6px 15px;
  background-color: #eff1f3;
  height: 35px;
  border-radius: 20px;
  color: #191919;
  font-size: 13px;
  width: 175px;
  font-weight: 500;
  position: relative;
}

.cmnInp_details .Price_Val .price_details .coinImg {
  height: 17px;
}
.cmnInp_details .Price_Val .price_details .coin {
  display: flex;
  align-items: center;
  gap: 6px;
}

.cmnInp_details .Price_Val .price_details .dollor {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  width: 50px;
  height: 85%;
  border-radius: 15px;
  background-color: #f9fbfc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delivery_Man .send_invite_sec {
  background-color: #000000;
  border: 1px solid #f9fbfc;
  box-shadow: 0px 0px 2px #00000029;
  border-radius: 10px;
}

.delivery_Man .send_invite_sec textarea {
  width: 100%;
  min-height: 110px;
  border-radius: 10px;
  padding: 12px;
  font-size: 13px;
  box-shadow: none;
  outline: none;
}

.delivery_Man .sendInvite_btn {
  border-radius: 6px !important;
  outline: none;
  box-shadow: none;
  font-size: 13px;
  padding: 6px 25px;
  text-wrap: nowrap;
  font-weight: 500;
}

.delivery_Man .email_sec {
  padding: 0px 15px 6px 20px;
  display: flex;
  align-items: center;
}

.delivery_Man .emailInp {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  color: white;
  font-size: 12px;
}

.delivery_Man .emailInp::placeholder {
  color: white;
}

.delivery_Man .table_sec {
  margin-top: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px #00000029;
  border-radius: 18px;
  padding: 20px;
}

.delivery_Man .table_sec .tab_head {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 5px;
}

.delivery_Man .table_sec .tab_head button {
  border-radius: 20px;
  outline: none;
  box-shadow: none;
  font-size: 13px;
  padding: 6px 25px;
  background-color: #eff1f3 !important;
  border: 1px solid #c4c6c6 !important;
  color: #191919 !important;
  min-width: 160px;
}

.delivery_Man .table_sec .tab_head button.active {
  background: transparent linear-gradient(280deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box !important;
  color: var(--white) !important;
  border: 1px solid transparent !important;
}

.delivery_Man .table_sec .tab_head button:hover {
  background: transparent linear-gradient(280deg, #4b9a24 0%, #9cd947 100%) 0%
    0% no-repeat padding-box !important;
  color: var(--white) !important;
  border: 1px solid transparent !important;
}

.deliveryman_table .type_listing {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #000000;
  color: var(--white);
  padding: 6px 15px;
  border-radius: 20px;
  font-size: 10px;
  font-weight: 500;
  text-wrap: nowrap;
  min-width: 160px;
  max-width: fit-content;
  justify-content: space-between;
  margin-bottom: 4px;
}

.deliveryman_table .type_listing:last-child {
  margin-bottom: 0px;
}

.deliveryman_table .type_listing img {
  height: 16px;
}

.deliveryman_table .network_type {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #000000;
  color: var(--white);
  padding: 6px 15px;
  border-radius: 20px;
  font-size: 10px;
  font-weight: 500;
  text-wrap: nowrap;
  min-width: 90px;
  max-width: fit-content;
  justify-content: space-between;
  margin-bottom: 4px;
}

.deliveryman_table .network_type:last-child {
  margin-bottom: 0px;
}

.deliveryman_table .network_type img {
  height: 16px;
}

.deliveryman_table .delete_invitation:hover {
  color: var(--themeclr);
}

.delete_invite {
  border: 1px solid #c4c6c6 !important;
  border-radius: 10px;
  background: var(--black);
}

.delete_invite .head_sec {
  background: transparent linear-gradient(277deg, #dd3d4c 0%, #dd3d4c 100%);
  border-radius: 7px;
  padding: 10px;
  text-align: center;
  color: var(--white);
  font-weight: 500;
  font-size: 14px;
}
.delete_invite .cnt_details {
  /* background: var(--black); */
  padding: 12px 10px;
  color: var(--white);
  font-size: 13px;
  text-align: center;
}
.delete_invite .cnt_details .gray {
  color: gray;
}

.deliveryman_table .invite_delete .col {
  margin-bottom: 20px;
}

.deliveryman_table .invite_delete .col:last-child {
  margin-bottom: 20px;
}

.shipping_managementNew {
}

.shipping_managementNew .cmn_shipList {
  background-color: #fff;
  box-shadow: none !important;
  border: 1px solid #dee2e6 !important;
  font-size: 13px;
  padding: 20px;
  border-radius: 8px;
  max-height: 400px;
  overflow-y: auto;
}

.shipping_managementNew .form-control {
  box-shadow: none !important;
  border-color: #dee2e6 !important;
  font-size: 13px;
  height: 40px;
  background-color: #f9fbfc;
}

.shipping_managementNew .add_deleteCat {
  display: flex;
  align-items: end;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

/* Stores */

.store_Tbl_logo {
  width: 80px;
  height: 50px;
}

.store_Tbl_logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.store_Tbl_action .btn_list {
  display: flex;
  align-items: center;
  gap: 10px;
}

.store_Tbl_action .btn_list button {
  border-radius: 6px;
  padding: 5px 15px;
}

.AddStorepage .stores_inp {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  padding: 30px 20px;
  background-color: var(--white);
}

.AddStorepage .form-label {
  color: #0e1b28;
  font-size: 15px;
}

.AddStorepage .form-control {
  color: #0e1b28;
  font-size: 15px;
  background-color: #f9fbfc;
  border: 1px solid #e7e7e7 !important;
  border-radius: 5px;
  box-shadow: none !important;
  outline: none;
  height: 40px;
  font-size: 13px;
}

.AddStorepage .custom_ImgPick {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}

.AddStorepage .custom_ImgPick .btn {
  background-color: #f9fbfc;
  border: 1px solid #e7e7e7 !important;
  width: 100%;
  height: 130px;
  border-radius: 5px;
  padding: 0px;
}
.AddStorepage .custom_ImgPick .btn .emptyImg {
  height: 40px;
}

.AddStorepage .custom_ImgPick .btn .pickedImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.AddStorepage .custom_ImgPick input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.AddStorepage .cancel_nxt_Btn button {
  font-size: 13px;
  padding: 9px 30px;
  font-weight: 500;
}

.AddStorepage .store_ryt_tab .heading_txt {
  color: #a2a9b9;
  font-size: 15px;
  /* font-weight: 500; */
  display: flex;
  align-items: center;
  justify-content: end;
  text-align: end;
  gap: 8px;
  cursor: pointer;
}
.AddStorepage .store_ryt_tab .heading_txt.active {
  color: var(--themeclr);
  font-weight: 500;
}

.AddStorepage .store_ryt_tab .heading_txt.active .activeLine {
  display: block;
  width: 30px;
  height: 1px;
  background-color: var(--themeclr);
}

.AddStorepage textarea.form-control {
  height: unset;
}

/* react select store time start */
.storeTime_select__control {
  background-color: #eff1f3 !important;
  box-shadow: none !important;
  border: 1px solid hsl(0, 0%, 80%) !important;
}

.storeTime_select__single-value {
  color: #0e1b28;
  font-size: 12px;
  font-weight: 500;
}
.storeTime_select__option {
  color: var(--black) !important;
  font-size: 12px !important;
}
.storeTime_select__option:hover {
  background-color: #d9d9d9 !important;
}

.storeTime_select__option--is-focused {
  background-color: transparent !important;
}

.storeTime_select__indicator-separator {
  background-color: transparent !important;
}

.storeTime_select__indicator svg {
  color: var(--black) !important;
  opacity: 0.5;
}

.storeTime_select__placeholder {
  font-size: 12px !important;
}

.storeTime_select__option--is-selected {
  background-color: #d9d9d9 !important;
}

.AddStorepage .removeTime {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  /* flex-direction: column; */
  /* gap: 5px; */
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.AddStorepage .removeTime .removeIcon {
  position: absolute;
  left: 0px;
  bottom: 12px;
  height: 18px;
}

.AddStorepage .removeTime .rmv_txt {
  color: #191919;
  font-size: 8px;
}

/* react select store time end */
.cmn_checkBox {
  display: flex !important;
  align-items: center;
  gap: 10px;
  min-height: 0px !important;
  padding-bottom: 0px !important;
}
.cmn_checkBox .form-check-input {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  box-shadow: none !important;
  background-color: #eff1f3 !important;
  border: 1px solid hsl(0, 0%, 80%) !important;
  border-radius: 3px !important;
  margin-top: 0px;
}

.cmn_checkBox .form-check-label {
  font-size: 13px;
  position: relative;
  /* top: -2px;
  left: 5px; */
}
.cmn_checkBox .form-check-input:checked {
  background-color: var(--themeclr) !important;
  border: 1px solid var(--themeclr) !important;
}

.Stores_hours .days_list {
  display: flex;
  align-items: center;
  gap: 25px;
  flex-wrap: wrap;
}

.Stores_hours .add_more button {
  background: transparent !important;
  color: #0e1b28;
  border: none;
  padding: 0px;
  outline: none;
  box-shadow: none;
  font-size: 14px;
  text-decoration: underline;
  font-weight: 500;
}

.Stores_hours .add_more button:hover {
  color: var(--themeclr);
}

.stateCountry_select__control {
  background-color: #f9fbfc !important;
  box-shadow: none !important;
  border: 1px solid #e7e7e7 !important;
}

.stateCountry_select__single-value {
  color: #0e1b28;
  font-size: 12px;
}
.stateCountry_select__option {
  color: var(--black) !important;
  font-size: 12px !important;
}
.stateCountry_select__option:hover {
  background-color: #d9d9d9 !important;
}

.stateCountry_select__option--is-focused {
  background-color: transparent !important;
}

.stateCountry_select__indicator-separator {
  background-color: transparent !important;
}

.stateCountry_select__indicator svg {
  color: var(--black) !important;
  opacity: 0.5;
}

.stateCountry_select__placeholder {
  font-size: 12px !important;
}

.stateCountry_select__option--is-selected {
  background-color: #d9d9d9 !important;
}

.qa_seller .qus_ans {
  background-color: #f9fbfc;
  border: 1px solid #e8e8e8;
  border-radius: 7px;
}

.qa_seller .qus_ans .heading_txt {
  color: #191919;
  font-weight: 500;
  font-size: 15px;
  padding: 11px 20px;
  border-bottom: 1px solid #e8e8e8;
}

/* .qa_seller .qus_ans .content_sec {
  padding: 11px 20px;
} */

.qa_seller .content_sec .qus_txt {
  color: #404040;
  font-size: 14px;
}
.qa_seller .content_sec .ans_txt {
  color: #404040;
  font-size: 14px;
  font-weight: 500;
}

.qa_seller .ans_showHide {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
  margin-bottom: 10px;
}

.qa_seller .ans_showHide .hideShowBtns {
  display: flex;
  align-items: center;
  gap: 10px;
}

.qa_seller .ans_showHide .hideShowBtns button {
  border: none;
  outline: none;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0px;
  font-size: 13px;
}

.qa_seller .ans_showHide .hideShowBtns button:hover {
  text-decoration: underline;
}

.qa_seller .ans_showHide .hideShowBtns button.showBtn {
  color: var(--themeclr);
}
.qa_seller .ans_showHide .hideShowBtns button.HideBtn {
  color: #dd3d4c;
}

.qa_seller .ans_showHide .hideShowBtns .vertical_line {
  height: 15px;
  display: block;
  width: 1px;
  background-color: #cbbbbb;
}

.qa_seller .content_sec .answer_txt {
  font-size: 13px;
  color: var(--themeclr);
  text-decoration: underline;
  padding: 0px;
  border: none;
  outline: none;
  background-color: transparent !important;
}

.qa_seller .content_sec .answer_txt:hover {
  color: #70c739;
}

.qa_seller .content_sec .ans_field textarea {
  background-color: #eff1f3;
  font-size: 13px;
  box-shadow: none !important;
  border-color: #dee2e6 !important;
}

.qa_seller .content_sec .SubmitBtn {
  padding: 5px 20px;
  font-size: 13px;
}

.qa_seller .particular_qus {
  padding: 11px 20px 5px;
  /* border-bottom: 1px solid #e8e8e8; */
}

.qa_seller .particular_qus:last-child {
  border-bottom: none;
}

.store_currentLoc_map .curr_locPick {
  position: absolute;
  top: 20px;
  left: 20px;
}

.store_currentLoc_map .curr_locPick button {
  font-size: 13px;
  padding: 6px 10px;
  border-radius: 5px;
}
.loc_pick_approveBtn {
  display: flex;
  align-items: center;
  gap: 20px;
}
.loc_pick_approveBtn button {
  font-size: 12px;
  padding: 7px 20px;
  box-shadow: none;
  border-radius: 5px;
  min-width: 130px;
}

.loc_pick_approveBtn button.cancelBtn {
  border: 1px solid #5ba62b !important;
  background-color: transparent !important;
  color: #5ba62b !important;
}

.loc_pick_approveBtn button.cancelBtn:hover {
  border: 1px solid #5ba62b !important;
  background-color: #5ba62b !important;
  color: #fff !important;
}

/* products */

.product_Tbl_status .btn_list button {
  border-radius: 5px;
  padding: 5px 15px;
  border: none;
  font-size: 12px;
  box-shadow: none;
  color: var(--white);
  width: 85px;
}

.product_Tbl_status .btn_list button.active {
  background-color: #2a6d00;
}
.product_Tbl_status .btn_list button.inactive {
  background-color: #f00909;
}

.product_Tbl_status .btn_list button {
  border-radius: 5px;
  padding: 5px 15px;
  border: none;
  font-size: 12px;
  box-shadow: none;
  color: var(--white);
  width: 90px;
}

.product_Tbl_status .btn_list button.approved {
  background-color: var(--themeclr);
}
.product_Tbl_status .btn_list button.pending {
  background-color: #dd6e3d;
}
.product_Tbl_status .btn_list button.reject {
  background-color: #dd3d4c;
}
.product_Tbl_logo {
  width: 70px;
  height: 50px;
}
.product_Tbl_logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.product_Tbl_action_drop {
  text-align: center;
}
.product_Tbl_action_drop .dropdown-toggle {
  background: transparent !important;
  border: none;
  padding: 0;
  box-shadow: none;
}

.product_Tbl_action_drop .dropdown-toggle::after {
  display: none;
}

.product_Tbl_action_drop .dotImg {
  height: 4px;
}

.product_Tbl_action_drop .dropdown-item {
  font-size: 12px;
}

.product_Tbl_action_drop .dropdown-menu {
  min-width: 120px;
}

.product_Tbl_action_drop .dropdown-item:focus,
.product_Tbl_action_drop .dropdown-item:hover {
  color: var(--bs-dropdown-link-hover-color) !important;
  background-color: var(--bs-dropdown-link-hover-bg) !important;
}

/* add products */

.addProductPage .addProduct_inp {
  border: 1px solid #b9b9c3;
  border-radius: 8px;
  padding: 0px;
  background-color: #f9fbfc;
}

.addProduct_inp .header_sec {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 13px 20px;
  border: 1px solid #b9b9c3;
  border-radius: 7px;
  background-color: #eff1f3;
}

.addProduct_inp .header_sec .userLogo {
  height: 28px;
}
.addProduct_inp .header_sec .title_txt {
  font-size: 15px;
  font-weight: 500;
}

.addProduct_inp .inpContent {
  padding: 13px 20px;
  background-color: #f9fbfc;
  border-radius: 8px;
}

.addProduct_inp .form-label {
  color: #0e1b28;
  font-size: 14px;
}

.addProduct_inp .titleLabel {
  color: #0e1b28;
  font-size: 15px;
}

.addProduct_inp .category_inpGrp {
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  padding: 13px 18px;
  background-color: #fff;
}

.addProduct_inp .pricing_inpGrp {
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  padding: 13px 18px;
  background-color: #fff;
}

.addProduct_inp .inpContent .label_sm {
  font-size: 13px;
}

.addProduct_inp .form-control {
  background-color: #fff;
  box-shadow: none !important;
  border-color: #dee2e6 !important;
  font-size: 13px;
  height: 40px;
}

.addProduct_inp .category_inpGrp .form-control {
  background-color: #f9fbfc;
}
.addProduct_inp .pricing_inpGrp .form-control {
  background-color: #f9fbfc;
}

.addProduct_inp .choose_address {
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  padding: 13px 18px;
  background-color: #fff;
  max-height: 275px;
  overflow-y: auto;
}

.addProduct_inp .choose_address .address_list {
  display: flex;
  align-items: start;
  gap: 15px;
  justify-content: space-between;
  margin-bottom: 15px;
}

.addProduct_inp .choose_address .address_list:last-child {
  margin-bottom: 0px;
}

.addProduct_inp .address_list .cmn_checkBox .form-check-input {
  width: 15px;
  height: 15px;
  margin-top: 4px;
}

.addProduct_inp .address_list .addr {
  font-size: 13px;
}

.addProduct_inp .address_list .addressView {
  display: flex;
  align-items: center;
  gap: 10px;
}

.addProduct_inp .address_list .addressView button {
  border-radius: 5px;
  padding: 3px 11px;
  font-size: 11px;
}

.addProductPage .description_details {
  border: 1px solid #b9b9c3;
  border-radius: 8px;
  padding: 0px;
  background-color: #f9fbfc;
}

.addProductPage .description_details .header_sec {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  padding: 13px 20px;
  border: 1px solid #b9b9c3;
  border-radius: 7px;
  background-color: #eff1f3;
}

.addProductPage .description_details .header_sec.bdr_none {
  border: none;
}

.addProductPage .description_details .header_sec .head {
  display: flex;
  align-items: center;
  gap: 15px;
}

.addProductPage .description_details .userLogo {
  height: 25px;
}

.addProductPage .description_details .title_txt {
  font-size: 15px;
  /* font-weight: 500; */
}

.addProductPage .description_details .content_sec {
  padding: 20px 20px;
}

.addProductPage .content_sec .desc_uploadBigImg {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}

.addProductPage .content_sec .desc_uploadBigImg .btn {
  background-color: #eff1f3;
  border: 1px solid #e7e7e7 !important;
  width: 100%;
  height: 160px;
  border-radius: 8px;
  padding: 0px;
}
.addProductPage .content_sec .desc_uploadBigImg .btn .emptyImg {
  height: 40px;
}

.addProductPage .content_sec .desc_uploadBigImg .btn .pickedImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.addProductPage .content_sec .desc_uploadBigImg input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.addProductPage .content_sec .Imgupload_txt {
  font-size: 10px;
  color: #8b8d8e;
}

.addProductPage .cmn_desc_height {
  min-height: 160px;
  max-height: 160px;
}

.addProductPage .product_ryt_tab .heading_txt {
  color: #a2a9b9;
  font-size: 15px;
  /* font-weight: 500; */
  display: flex;
  align-items: center;
  justify-content: end;
  text-align: end;
  gap: 8px;
  cursor: pointer;
}
.addProductPage .product_ryt_tab .heading_txt.active {
  color: var(--themeclr);
  font-weight: 500;
}

.addProductPage .product_ryt_tab .heading_txt.active .activeLine {
  display: block;
  width: 30px;
  height: 1px;
  background-color: var(--themeclr);
}

.addProductPage .cancel_nxt_Btn button {
  font-size: 13px;
  padding: 9px 30px;
  font-weight: 500;
}

.addProductPage .description_details textarea {
  font-size: 13px;
  background-color: #eff1f3;
  box-shadow: none;
  border: transparent;
}

.addProductPage .cmn_accordion .accordion-button {
  color: #0e1b28;
  font-size: 14px;
  height: 40px;
  box-shadow: none !important;
  background-color: #f9fbfc;
}

.addProductPage .cmn_accordion .accordion-body {
  border-top: 1px solid #dee2e6 !important;
}

.addProductPage .cmn_accordion .accordion-button:not(.collapsed) {
  background-color: #f9fbfc;
}

.addProductPage .cmn_accordion .accordion-button::after {
  background-size: 80%;
}

.addProductPage .cmn_accordion .cmn_checkBox .form-check-input {
  width: 17px;
  height: 17px;
}

.addProductPage .cmn_accordion .cmn_checkBox {
  gap: 12px;
  margin-bottom: 15px;
}
.addProductPage .cmn_accordion .cmn_checkBox:last-child {
  margin-bottom: 0px;
}

.addProductPage .cmn_accordion .check_list {
  max-height: 195px;
  /* height: 195px; */
  overflow-y: auto;
  /* scrollbar-width: none; */
}

.addProductPage .cmn_accordion .add_moreBtn {
  background: transparent !important;
  color: #0e1b28 !important;
  border: none;
  padding: 0px;
  outline: none;
  box-shadow: none;
  font-size: 13px;
  text-decoration: underline;
}

.addProductPage .cmn_accordion .add_moreBtn:hover {
  color: var(--themeclr) !important;
}

.addProductPage .cmn_accordion .sub_types {
  background-color: #f9fbfc;
  border: 1px solid #dee2e6 !important;
  padding: 15px;
  border-radius: 7px;
}

.addProductPage .cmn_accordion .select_types .check_list {
  background-color: #fff;
  border: 1px solid #dee2e6 !important;
  padding: 15px;
  border-radius: 7px;
}

.addProductPage .cmn_accordion .saveBtn {
  font-size: 13px;
  padding: 5px 16px;
  font-weight: 500;
}

.prod_shippingType .check_list {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}

.prod_shippingType .cmn_shipList {
  background-color: #fff;
  box-shadow: none !important;
  border: 1px solid #dee2e6 !important;
  font-size: 13px;
  padding: 20px;
  border-radius: 8px;
  max-height: 400px;
  overflow-y: auto;
}
.prod_shippingType .cmn_shipList .row {
  margin-bottom: 5px;
}

.prod_shippingType .cmn_shipList .row:last-child {
  margin-bottom: 0px;
}

.prod_shippingType .form-control {
  box-shadow: none !important;
  border-color: #dee2e6 !important;
  font-size: 13px;
  height: 40px;
  background-color: #f9fbfc;
}

/* add coupons  */
.coupons .add_btn {
  font-size: 14px;
  padding: 7px 25px;
  border-radius: 5px;
  height: 40px;
}
.addCoupons .coupon_inp {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  padding: 30px 20px;
  background-color: var(--white);
}

.addCoupons .coupon_details .form-label {
  color: #0e1b28;
  font-size: 14px;
}

.addCoupons .coupon_details .form-control {
  color: #0e1b28;
  font-size: 15px;
  background-color: #f9fbfc;
  border: 1px solid #e7e7e7 !important;
  border-radius: 5px;
  box-shadow: none !important;
  outline: none;
  height: 40px;
  font-size: 13px;
}

.cmn_CouponSelect__control {
  background-color: #f9fbfc !important;
  box-shadow: none !important;
  border: 1px solid #e7e7e7 !important;
  height: 40px;
}

.cmn_CouponSelect__single-value {
  color: #0e1b28;
  font-size: 12px;
}
.cmn_CouponSelect__option {
  color: var(--black) !important;
  font-size: 12px !important;
}
.cmn_CouponSelect__option:hover {
  background-color: #d9d9d9 !important;
}

.cmn_CouponSelect__option--is-focused {
  background-color: transparent !important;
}

.cmn_CouponSelect__indicator-separator {
  background-color: transparent !important;
}

.cmn_CouponSelect__indicator svg {
  color: var(--black) !important;
  opacity: 0.5;
  width: 16px;
  height: 16px;
}

.cmn_CouponSelect__placeholder {
  font-size: 12px !important;
}

.cmn_CouponSelect__option--is-selected {
  background-color: #d9d9d9 !important;
}

.cmn_CouponSelect__multi-value__label {
  font-size: 12px !important;
}

.addCoupons .cmn_CouponSelect {
}

.cmn_choose_file input[type="file"] {
  max-width: 100%;
  padding: 5px;
  background: #f9fbfc;
  font-size: 12px;
  border: 1px solid #e7e7e7 !important;
  border-radius: 5px !important;
  color: #3d565f !important;
  width: 100%;
}

.cmn_choose_file input[type="file"]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: var(--themeclr);
  padding: 7px 20px;
  border-radius: 8px;
  color: var(--white);
  cursor: pointer;
  font-size: 10px;
}

.addCoupons .dateTime_picker .react-datepicker-wrapper {
  width: 100%;
  height: 40px;
}
.addCoupons .dateTime_picker .react-datepicker__input-container {
  height: 100%;
  width: 100%;
}

.addCoupons .dateTime_picker input {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  font-size: 13px;
  color: #0e1b28;
  background-color: #f9fbfc;
  border: 1px solid #e7e7e7 !important;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
}

.dateTime_picker .react-datepicker .react-datepicker__day--selected {
  background-color: var(--themeclr);
}

.dateTime_picker .react-datepicker {
  border: 1px solid #e7e7e7 !important;
}

.dateTime_picker .react-datepicker__header {
  border-bottom: 1px solid #e7e7e7 !important;
  background-color: #e7e8e8a3;
}

.dateTime_picker .react-datepicker__triangle {
  color: #e7e7e7;
}
.dateTime_picker .react-datepicker-popper .react-datepicker__triangle {
  stroke: #e7e7e7;
}
.selectdays .cmn_CouponSelect__menu-list {
  max-height: 140px;
}

.addCoupons .cancel_nxt_Btn button {
  font-size: 13px;
  padding: 9px 30px;
  font-weight: 500;
}

.addCoupons .head_sec {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 15px;
}

/* Media query */

@media (max-width: 380px) {
  .header .snd_nav .snd_sec {
    gap: 10px;
  }

  .home_banner .banner_img {
    height: 170px;
    object-fit: cover;
  }

  .banner_slide .slick-dots {
    bottom: 8px;
  }

  .banner_slide .slick-dots li {
    width: 15px;
  }

  .banner_slide .slick-dots li button:before {
    width: 15px;
  }

  .banner_slide .slick-dots li.slick-active button:before {
    width: 21px;
  }

  .top_categories .categories_subContent_hvr {
    left: 0px;
    top: 50px;
    z-index: 2;
    width: unset;
  }

  .top_categories .categories_subContent_hvr .details {
    gap: 15px;
    height: 250px;
    overflow-y: auto;
  }

  .productImg_preview .left_2 {
    width: 100%;
  }

  .productImg_preview .left_2 > div {
    width: 100% !important;
    height: 290px !important;
  }

  .productImg_preview .left_2 img {
    object-fit: unset !important;
    height: 100% !important;
  }

  .prodDetail .productPhotos .img_sec {
    height: 45px;
  }

  .productImg_preview .magnify-container {
    display: none !important;
  }

  /* .product_summary .shopMore_btn {
    padding: 6px 27px;
  } */

  /* .product_summary .checkout_btn {
    padding: 6px 27px;
  } */

  .product_summary .freeDelivery_eligible {
    font-size: 13px;
  }

  .address_modal .btns .cancelBtn,
  .address_modal .btns .cnfmBtn {
    padding: 8px 30px !important;
  }
  /* .setRules_details .cmnRules {
    flex-wrap: wrap;
  } */
  .store_currentLoc_map .curr_locPick button {
    font-size: 10px;
  }
  .store_currentLoc_map .curr_locPick {
    position: absolute;
    top: 15px;
    left: 15px;
  }
}
@media (min-width: 381px) and (max-width: 479px) {
  .home_banner .banner_img {
    height: 200px;
    object-fit: cover;
  }

  .top_categories .categories_subContent_hvr {
    left: 0px;
    top: 50px;
    z-index: 2;
    width: unset;
  }

  .top_categories .categories_subContent_hvr .details {
    gap: 15px;
    height: 250px;
    overflow-y: auto;
  }

  .productImg_preview .magnify-container {
    display: none !important;
  }
}
@media (max-width: 479px) {
  .footer .content_sec {
    grid-template-columns: auto;
    gap: 20px;
  }

  .footer .post_footer .content_secs {
    flex-direction: column;
    gap: 10px;
  }

  .best_dealz_card .container {
    padding-left: 20px;
  }

  .mostPopularCard .container {
    padding-left: 20px;
  }

  .premiumProduct .product {
    height: 100px;
    background-size: cover;
  }

  .premiumProduct .premiumProduct_Img img {
    height: 100px;
  }

  .new-released .row .col {
    padding: 0px 12px !important;
  }

  .discover_now .img-sec {
    height: 120px;
    width: 100%;
  }

  .discover_now .fst-col {
    padding-right: 12px;
  }

  .discover_now .snd-col {
    padding-left: 12px;
  }

  .trendingShoes .lft-col {
    padding-right: 12px;
  }

  .trendingShoes .ryt-col {
    padding-left: 12px;
  }

  .trendingShoes .lft_img_big .name {
    font-size: 35px;
  }

  .signUp_modal .passkey_inp input {
    width: 25px !important;
    height: 25px !important;
  }

  .signUp_modal .passkey_inp > div {
    gap: 10px;
  }

  .signUp_modal .confirmBtn {
    padding: 7px 14px;
    font-size: 14px;
  }

  .signUp_modal.login_modal .login_sec {
    margin-top: 50px;
  }

  .specification .spec-details .spec-name {
    width: 50%;
  }

  .specification .spec-details .spec-value {
    width: 50%;
  }

  .product_description .header_tabsec {
    top: 60px;
  }

  .shoppingcart_product .prod_img {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }

  .shoppingcart_product {
    gap: 10px;
  }

  .shoppingcart_product .prod_subInfo {
    gap: 10px;
  }

  .shippingType_tab .shippingType_head {
    padding: 5px 15px;
  }
  .cmn_shipping_types .shippingType_head {
    padding: 5px 15px;
  }
  .Cmn_shipping_details {
    padding: 20px 20px;
  }
  .delivery_Man .email_sec {
    align-items: start;
    flex-direction: column;
    gap: 10px;
  }
  .delivery_Man .table_sec {
    padding: 10px;
  }
  .delivery_Man .table_sec .tab_head {
    padding-bottom: 7px;
  }

  .shippingDetails_lft .cmnInp_details {
    flex-direction: column;
    align-items: start;
  }
  .cmnInp_details .Price_Val {
    flex-wrap: wrap;
  }
  .shippingDetails_lft .cmnInp_details .inpVal {
    /* flex-wrap: wrap; */
    gap: 10px;
  }
  .create_shippingcard .shippingDetails_lft {
    padding: 20px 10px;
  }
  .shippingDetails_lft .cmnInp_details .inpVal input {
    width: 80%;
  }
  .shippingDetails_lft .set_unitDrop {
    width: 80%;
  }
  .cmnInp_details .Price_Val .price_details {
    width: 100%;
  }
  .completed_shippingCard .btns_sec {
    /* flex-direction: column; */
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .shippingDetails_lft .setRules_details {
    padding: 10px 10px;
  }
  .setRules_details .cmnRules {
    flex-wrap: wrap;
  }
  .setRules_details .weight_amt {
    width: 100%;
  }
  .setRules_details .price_details {
    width: 100%;
  }
  .bookCollections_slide .slick-track .slick-slide {
    padding: 0px 0px 0px 0px !important;
  }
  /* .bookCollections_slide .slick-track .slick-slide:nth-child(1) {
    padding: 0px 0px 0px 0px;
  } */
  .addProduct_inp .choose_address .address_list {
    flex-direction: column;
    gap: 8px;
    align-items: end;
  }
  .loc_pick_approveBtn button {
    /* flex-direction: column; */
    min-width: 80px;
  }
}
@media (min-width: 480px) and (max-width: 575px) {
  .footer .content_sec {
    grid-template-columns: auto auto;
    gap: 20px;
    justify-content: normal;
  }

  .footer .post_footer .content_secs {
    flex-direction: column;
    gap: 10px;
  }

  .home_banner .banner_img {
    height: 250px;
    object-fit: cover;
  }

  .top_categories .categories_subContent_hvr {
    width: 200px;
    left: 240px;
  }

  .top_categories .categories_subContent_hvr .details {
    gap: 15px;
    height: 350px;
    overflow-y: auto;
  }

  .best_dealz_card .container {
    padding-left: 20px;
  }

  .mostPopularCard .container {
    padding-left: 20px;
  }

  .premiumProduct .product {
    height: 140px;
    background-size: cover;
  }
  .premiumProduct .premiumProduct_Img img {
    height: 140px;
  }

  .new-released .row .col {
    padding: 0px 12px !important;
  }

  .discover_now .img-sec {
    height: 120px;
    width: 100%;
  }

  .discover_now .fst-col {
    padding-right: 12px;
  }

  .discover_now .snd-col {
    padding-left: 12px;
  }

  .trendingShoes .lft-col {
    padding-right: 12px;
  }

  .trendingShoes .ryt-col {
    padding-left: 12px;
  }

  .productImg_preview .magnify-container {
    display: none !important;
  }
  .Cmn_shipping_details {
    padding: 20px 20px;
  }
  .shippingDetails_lft .cmnInp_details .inpVal input {
    width: 160px;
  }
  .shippingDetails_lft .set_unitDrop {
    width: 160px;
  }

  /* .bookCollections_slide .slick-track .slick-slide:nth-child(2) {
    padding: 0px 0px 0px 5px;
  } */
  .bookCollections_slide .slick-track .slick-slide.slick-active {
    padding: 0px 0px 0px 5px !important;
  }
  .bookCollections_slide .slick-track .slick-slide.slick-active.slick-current {
    padding: 0px 5px 0px 0px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .footer .content_sec {
    grid-template-columns: auto auto auto;
    gap: 20px;
    justify-content: normal;
  }

  .footer .post_footer .content_secs {
    flex-direction: column;
    gap: 10px;
  }

  .home_banner .banner_img {
    height: 300px;
  }

  .top_categories .categories_subContent_hvr {
    width: 300px;
    left: 240px;
  }

  .top_categories .categories_subContent_hvr .details {
    gap: 15px;
    height: 350px;
    overflow-y: auto;
  }

  .best_dealz_card .container {
    padding-left: 20px;
  }

  .mostPopularCard .container {
    padding-left: 20px;
  }

  .premiumProduct .product {
    height: 150px;
    background-size: cover;
  }
  .premiumProduct .premiumProduct_Img img {
    height: 150px;
  }

  .new-released .row .col {
    padding: 0px 0px 0px 12px !important;
  }

  .new-released .row .col:nth-child(2),
  .new-released .row .col:nth-child(4) {
    padding-left: 0px !important;
  }

  .discover_now .img-sec {
    height: 140px;
  }

  .trendingShoes .lft-col {
    padding-right: 12px;
  }

  .trendingShoes .ryt-col {
    padding-left: 12px;
  }

  .productImg_preview .magnify-container {
    display: none !important;
  }
  .Cmn_shipping_details {
    padding: 20px 20px;
  }
  /* .bookCollections_slide .slick-track .slick-slide:nth-child(2) {
    padding: 0px 0px 0px 5px;
  } */
  .bookCollections_slide .slick-track .slick-slide.slick-active {
    padding: 0px 0px 0px 5px !important;
  }
  .bookCollections_slide .slick-track .slick-slide.slick-active.slick-current {
    padding: 0px 5px 0px 0px !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .top_categories .categories_subContent_hvr {
    width: 450px;
    left: 240px;
  }

  .top_categories .categories_subContent_hvr .details {
    gap: 15px;
    height: 350px;
    overflow-y: auto;
  }

  .best_dealz_card .container {
    padding-left: 28px;
  }

  .mostPopularCard .container {
    padding-left: 28px;
  }

  .premiumProduct .product {
    height: 150px;
    background-size: cover;
  }

  .premiumProduct .premiumProduct_Img img {
    height: 150px;
  }

  .new-released .row .col {
    padding: 0px 0px 0px 12px !important;
  }

  .new-released .row .col:nth-child(2),
  .new-released .row .col:nth-child(4) {
    padding-left: 0px !important;
  }

  .shop_categories .brand_details .row .col:nth-child(10),
  .shop_categories .brand_details .row .col:nth-child(11),
  .shop_categories .brand_details .row .col:nth-child(12) {
    margin-bottom: 0px;
  }

  .trendingShoes .lft-col {
    padding-right: 12px;
  }

  .trendingShoes .ryt-col {
    padding-left: 12px;
  }

  .couponsList .item .coupon-id {
    height: 100%;
    gap: 3px;
    padding: 0px 5px 5px 8px;
    flex-direction: column;
  }

  .shipAddress .bottom_content {
    padding: 14px 7px;
  }

  .couponsList .item .amount .amt-txt {
    text-wrap: nowrap;
  }

  .productImg_preview .magnify-container {
    /* width: 120% !important;
    height: 100% !important;
    left: 300px !important; */
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .top_categories .categories_subContent_hvr {
    width: 700px;
  }

  .top_categories .categories_subContent_hvr .details {
    gap: 15px;
    height: 350px;
    overflow-y: auto;
  }

  .best_dealz_card .container {
    padding-left: 34px;
  }

  .mostPopularCard .container {
    padding-left: 34px;
  }

  .premiumProduct .product {
    height: 170px;
    background-size: cover;
  }

  .premiumProduct .premiumProduct_Img img {
    height: 170px;
  }

  .shop_categories .brand_details .row .col:nth-child(9),
  .shop_categories .brand_details .row .col:nth-child(10),
  .shop_categories .brand_details .row .col:nth-child(11),
  .shop_categories .brand_details .row .col:nth-child(12) {
    margin-bottom: 0px;
  }

  .searchProduct_filter .price-range .d-flex {
    gap: 10px !important;
  }

  .price-range .valInp {
    width: 55px;
    height: 25px;
    padding: 0px 7px;
  }

  .price-range .goBtn {
    height: 28px;
    width: 35px;
  }

  .productImg_preview .magnify-container {
    width: 120% !important;
    height: 100% !important;
    left: 300px !important;
  }

  .categories_drop_content {
    left: -24px;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .AddStorepage .store_ryt_tab .heading_txt {
    font-size: 13px;
  }
  .addProductPage .product_ryt_tab .heading_txt {
    font-size: 13px;
  }
  .new-released .ryt_halfImg {
    height: 400px;
  }
  .new-released .lft_bigImg {
    height: 400px;
  }
}
@media (min-width: 1300px) and (max-width: 1399px) {
  .productImg_preview .magnify-container {
    width: 300% !important;
    height: 210% !important;
  }
}
@media (min-width: 1400px) and (max-width: 1799px) {
  .searchProduct_filter .price-range .d-flex {
    gap: 10px !important;
  }

  .price-range .valInp {
    width: 55px;
    height: 25px;
    padding: 0px 7px;
  }

  .price-range .goBtn {
    height: 28px;
    width: 35px;
  }

  .specialProducts .row .cardCols {
    width: 19.9% !important;
  }

  .popular_sales .row .cardCols {
    width: 19.9% !important;
  }

  .productImg_preview .left {
    gap: 15px;
  }

  .productImg_preview .left_2 > div {
    width: 310px !important;
    height: 310px !important;
  }

  .productImg_preview .left_1 .allImgs {
    height: 270px;
    width: 80px;
  }

  .productImg_preview .img_wrap {
    width: 80px;
    height: 80px;
  }

  .productImg_preview .arrow-btn {
    height: 20px;
  }

  .productImg_preview .magnify-container {
    width: 240% !important;
    height: 140% !important;
    left: 325px !important;
  }

  .prodDetail .alert_heading {
    font-size: 13px;
  }

  .prodDetail .bell_lottie svg {
    width: 20px !important;
    height: 20px !important;
  }

  .prodDetail .section1 .prod-name {
    font-size: 15px;
    font-weight: 600;
  }

  .prodDetail .amount .coin {
    font-size: 18px;
    gap: 8px;
  }

  .prodDetail .amount .coinImg {
    width: 26px;
    height: 26px;
  }

  .prodDetail .amount .new-amt {
    font-size: 16px;
  }

  .prodDetail .amount .old-amt {
    font-size: 14px;
  }

  .prodDetail .amount .reloadImg {
    height: 15px;
  }

  .prodDetail .amount .signImg {
    height: 7px;
  }

  .prodDetail .reviewRatings .rating-icons img {
    height: 17px;
  }

  .prodDetail .reviewRatings .rating-icons .ratVal {
    font-size: 15px;
  }

  .prodDetail .reviewRatings .verticalBar {
    font-size: 20px;
  }

  .prodDetail .reviewRatings .review,
  .prodDetail .reviewRatings .sold {
    font-size: 14px;
  }

  .prodDetail .soldBy {
    font-size: 16px;
  }

  .prodDetail .section2 .heading_txt {
    font-size: 16px;
    gap: 8px;
  }

  .prodDetail .section2 .heading_txt .arrowBtn {
    width: 17px;
    height: 17px;
  }

  .prodDetail .section2 .heading_txt .arrowBtn svg {
    width: 8px !important;
    height: 8px !important;
  }

  .prodDetail .section3 .header-txt {
    font-size: 16px;
  }

  .prodDetail .section3 .block_icons img {
    height: 22px;
  }

  .prodDetail .section4 .header-txt {
    font-size: 16px;
  }

  .prodDetail .productPhotos .photo {
    width: 15%;
  }

  .prodDetail .productPhotos {
    gap: 11px;
  }

  .prodDetail .productPhotos .img_sec {
    height: 90px;
  }

  .prodDetail .section4 .info {
    padding: 15px;
    font-size: 16px;
  }

  .product_details .shipAddress .shipHeading {
    font-size: 15px;
  }

  .product_details .shipAddress .locationBtn {
    font-size: 15px;
  }

  .shipAddress .section1 .delivery_lottie svg {
    width: 46px !important;
    height: 45px !important;
  }

  .shipAddress .section1 .delivery {
    font-size: 16px;
    gap: 7px;
  }

  .shipAddress .section1 .amount .coin {
    gap: 7px;
    font-size: 15px;
    border-radius: 50px;
    padding: 4px 7px;
  }

  .shipAddress .section1 .amount .coinImg {
    width: 17px;
    height: 17px;
  }

  .shipAddress .section1 .amount .checked {
    width: 17px;
    height: 17px;
  }

  .shipAddress .section1 .amount .amt-txt {
    font-size: 15px;
  }

  .shipAddress .section1 .amount .signImg {
    height: 7px;
  }

  .shipAddress .section1 .delivery-date {
    font-size: 16px;
  }

  .product_details .shipAddress .section1 {
    padding: 17px;
  }

  .shipAddress .section2 {
    padding: 17px;
  }

  .shipAddress .section3 {
    padding: 17px;
  }

  .shipAddress .section4 {
    padding: 17px;
  }

  .shipAddress .section5 {
    padding: 17px;
  }

  .shipAddress .section6 {
    padding: 17px;
  }

  .shipAddress .section7 {
    padding: 17px;
  }

  .shipAddress .section8 {
    padding: 17px;
  }

  .shipAddress .section2 .ship-from {
    font-size: 16px;
  }

  .cus_protection .header-txt {
    font-size: 16px;
  }

  .cus_protection .escrow_lock {
    font-size: 16px;
  }

  .cus_protection .escrow_lock .ques {
    width: 17px;
    height: 17px;
  }

  .cus_protection .escrow_lock .ques svg {
    width: 8px !important;
    height: 8px !important;
  }

  .cus_protection .lock_lottie {
    width: 50px !important;
    height: 50px !important;
  }

  .shipAddress .section4 .header-txt {
    font-size: 16px;
  }

  .shipAddress .section4 .inc_dec_btn {
    width: 30px;
    height: 30px;
  }

  .shipAddress .section4 .inc_dec_btn svg {
    height: 12px;
  }

  .shipAddress .section4 .count {
    font-size: 15px;
    font-weight: 500;
  }

  .shipAddress .section5 .coupons_heading {
    font-size: 16px;
  }

  .shipAddress .section5 .couponImg {
    height: 30px;
  }

  .couponsList .item .des {
    font-size: 14px;
  }

  .couponsList .item .amount .coin {
    gap: 7px;
    padding: 3px 6px;
    font-size: 15px;
  }

  .couponsList .item .amount .coinImg {
    width: 17px;
    height: 17px;
  }

  .couponsList .item .amount .checked {
    width: 17px;
    height: 17px;
  }

  .couponsList .item .amount .signImg {
    height: 6px;
    filter: invert();
  }

  .couponsList .item .amount .amt-txt {
    font-size: 15px;
  }

  .couponsList .item .coupon-id {
    height: 35px;
    padding: 3px 6px;
    font-size: 13px;
    padding: 0px 5px 0px 20px;
    margin-top: 18px;
  }

  .couponsList .item .coupon-id .apply-btn {
    font-size: 15px;
  }

  .couponsList .item .amount {
    gap: 9px;
    margin-top: 18px;
  }

  .section7 .total_price .header-txt {
    font-size: 16px;
  }

  .section7 .amount .coin {
    gap: 6px;
    font-size: 14px;
  }

  .section7 .amount .coinImg {
    width: 17px;
    height: 17px;
  }

  .section7 .amount .amt-txt {
    font-size: 15px;
  }

  .section7 .amount .signImg {
    height: 6px;
  }

  .section8 .total_price .header-txt {
    font-size: 16px;
  }

  .section8 .chain-list .list img {
    width: 25px;
    height: 25px;
  }

  .section8 .chain-list .list {
    font-size: 14px;
    gap: 10px;
    margin-bottom: 10p !important;
  }

  .shipAddress .bottom_content .BuyNow_btn {
    font-size: 15px;
    gap: 10px;
    padding: 8px 20px;
  }

  .shipAddress .bottom_content .BuyNow_btn img {
    width: 19px;
    height: 19px;
  }

  .shipAddress .bottom_content .AddtoCart_btn {
    font-size: 15px;
    gap: 10px;
    padding: 8px 20px;
    margin: 18px 0px;
  }

  .shipAddress .bottom_content .AddtoCart_btn img {
    width: 19px;
    height: 19px;
  }

  .shipAddress .share_btn {
    font-size: 16px;
    padding: 8px 40px;
  }

  .shipAddress .share_btn svg {
    height: 20px;
    width: 20px;
  }

  .shipAddress .wish_btn {
    padding: 9px 20px;
  }

  .shipAddress .wish_btn svg {
    height: 18px;
    width: 18px;
  }

  .prodDetail .item-info .txt {
    font-size: 13px;
  }

  .prodDetail .section2 .closeBtn {
    font-size: 13px;
  }

  .productImg_preview .magnify-container {
    width: 290% !important;
    height: 210% !important;
  }
  .new-released .ryt_halfImg {
    height: 450px;
  }
  .new-released .lft_bigImg {
    height: 450px;
  }
}
@media (min-width: 1800px) {
  .home_banner .banner_img {
    height: 450px;
  }

  .header .logo {
    height: 50px;
    margin-right: 50px;
  }

  .header .search_sec {
    height: 37px;
    width: 600px;
  }

  .header .searchBtn {
    min-width: 33px;
    height: 33px;
  }

  .header .searchBtn img {
    height: 17px;
    width: 17px;
  }

  .search_product.form-control {
    font-size: 14px;
  }

  .header .search_sec .all-txt {
    font-size: 14px;
  }

  .allProduct_listsDrop .dropdown-item {
    font-size: 14px;
  }

  .connectType_sec .network_btn {
    padding: 8px 15px !important;
    width: 120px;
    border-radius: 50px;
  }

  .connectType_sec .icon {
    width: 21px;
    height: 21px;
  }

  .connectType_sec .name {
    font-size: 15px;
  }

  .wallet_icon_hvr .walletIcon {
    width: 37px;
    height: 37px;
  }

  .shop_lottie svg {
    height: 45px !important;
  }

  .connectType_sec {
    gap: 18px;
  }

  .header .categoryBtn {
    font-size: 14px;
    padding: 6px 15px !important;
  }

  .header .categoryBtn .categoryFilter_img {
    height: 22px;
  }

  .header .hotDealzBtn {
    font-size: 14px;
    padding: 6px 15px !important;
  }

  .header .hotDealzBtn .hotDealz_img {
    height: 22px;
  }

  .header .snd_nav .customNavlink {
    font-size: 15px;
  }

  .sell_crypto_drop button {
    font-size: 16px;
  }

  .services_drop button {
    font-size: 16px;
  }

  .cmnAll_dropdown .dropdown-item {
    font-size: 15px;
  }

  .header .snd_nav .snd_sec {
    gap: 55px;
  }

  .langInr_drop_hvr .heading_txt {
    font-size: 16px;
  }

  .header .snd_nav .shipTo_Btn {
    font-size: 16px;
  }

  .header .snd_nav .navHide_lottie svg {
    width: 45px !important;
    height: 35px !important;
  }

  .header .snd_nav .shipTo_Btn .countryLogo {
    height: 17px;
    margin-right: 3px;
    margin-left: 3px;
  }

  .header .snd_nav .shipTo_Btn .downArrow {
    font-size: 18px;
  }

  .categories_drop_content .title_txt {
    font-size: 16px;
  }

  .categories_drop_content .categories_list .cat_name {
    font-size: 14px;
  }

  .categories_list .item .cat_img {
    width: 35px;
    height: 35px;
  }

  .categories_drop_content .trending_search {
    width: 18px;
    height: 18px;
  }

  .categories_subContent_hvr .title {
    font-size: 15px;
  }

  .categories_subContent_hvr .sub_prod {
    font-size: 14px;
  }

  .categories_subContent_hvr .see-all {
    font-size: 14px;
  }

  .wallet_dropContent {
    min-width: 230px;
    padding: 22px 18px;
  }

  .wallet_icon_hvr .sign-InUp-Btn {
    padding: 8px 8px !important;
    font-size: 14px !important;
  }

  .wallet_icon_hvr .my-info .txt {
    font-size: 14px;
  }

  .wallet_icon_hvr .my-info .icons {
    width: 19px;
  }

  .shop_icon_hvr .dropContent {
    padding: 20px 16px;
    min-width: 300px;
  }

  .navShop_details .watch_img {
    height: 43px;
  }

  .navShop_details .products_list .prod_name {
    font-size: 15px;
  }

  .navShop_details .products_list .prod_price {
    font-size: 15px;
  }

  .navShop_details .products_list .sign_img {
    height: 6px;
  }

  .navShop_details .products_list .close_icon {
    font-size: 20px;
  }

  .navShop_details .total {
    font-size: 15px;
  }

  .navShop_details .viewcart_btn {
    font-size: 14px;
    padding: 11px 20px !important;
  }

  .navShop_details .checkout_btn {
    font-size: 14px;
    padding: 11px 20px !important;
  }

  .langInr_drop_hvr .sec {
    padding: 18px 14px 0px 14px;
  }

  .langInr_drop_hvr .langInr_drop_content {
    min-width: 275px;
  }

  .langInr_drop_hvr .title_txt {
    font-size: 15px;
  }

  .language_drop button {
    padding: 7px 13px;
    font-size: 14px;
  }

  .cmnAll_dropdown .dropdown-menu {
    width: 100%;
  }

  .chooseCurrency_Filter .search_items_list .search_name {
    font-size: 12px;
  }

  .chooseCurrency_Filter .search_items_list .search_logos {
    height: 20px;
    width: 25px;
  }

  .chooseCurrency_Filter .search_items_list .search_checked {
    height: 18px;
  }

  .chooseCurrency_Filter .custom_search input {
    padding: 9px 15px 9px 33px;
    font-size: 11px;
  }

  .chooseCurrency_Filter .custom_search .search_iocn {
    top: 7px;
    left: 8px;
    font-size: 21px;
  }

  .header {
    padding-bottom: 65px;
  }

  .home_banner .banner_img {
    height: 470px;
  }

  .cmnHeading_sec .heading_txt {
    font-size: 22px;
  }

  .product_card1 .img_sec .prod_status {
    padding: 8px 15px;
    font-size: 14px;
  }

  .product_card1 .img_sec .productImg {
    height: 270px;
  }

  .product_card1 .product_details .sml_title {
    font-size: 12px;
  }

  .product_card1 .product_details .prod_name {
    font-size: 18px;
  }

  .product_card1 .product_details .prod_ratings {
    gap: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .product_card1 .product_details .rating_img {
    width: 17px;
    height: 17px;
  }

  .product_card1 .prod_price {
    font-size: 16px;
  }

  .product_card1 .prod_price .prod_img {
    height: 19px;
    width: 19px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .product_card1 .prod_price .sign_img {
    width: 17px;
    margin-left: 6px;
    margin-right: 6px;
  }

  .product_card1 {
    padding: 10px;
  }

  .cmnHeading_sec .see-all {
    font-size: 16px;
  }

  .bestdealz_card {
    height: 300px;
  }

  .bestdealz_card .description .name_txt {
    font-size: 30px;
    line-height: 36px;
  }

  .bestdealz_card .description {
    left: 12%;
  }

  .bestdealz_card .description .showNow_btn {
    padding: 4px 22px !important;
    font-size: 15px;
    margin-top: 22px;
  }

  .mostPopular_card .header_sec .title_txt {
    font-size: 17px;
  }

  .mostPopular_card .items .status {
    padding: 5px 12px;
    font-size: 13px;
  }

  .mostPopular_card .items .productImg {
    height: 150px;
    padding: 0px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .mostPopular_card .items .des .title_1 {
    font-size: 11px;
  }

  .mostPopular_card .items .des .title_2 {
    font-size: 15px;
  }

  .bookCollection_card .book-details {
    height: 300px;
  }

  .bookCollection_card .type {
    font-size: 17px;
  }

  .bookCollection_card .total-prod {
    font-size: 14px;
  }

  .bookCollection_card .prod_price .sign_img {
    width: 17px;
  }

  .lft_img_big {
    height: 700px;
  }

  .lft_img_big img {
    height: 900px;
  }

  .trendingShoes .ryt_img_sec.fst {
    height: 350px;
  }

  .trendingShoes .ryt_img_sec.snd {
    height: 350px;
  }

  .trendingShoes .ryt_img_sec .content .offer {
    font-size: 13px;
    padding: 5px 18px;
  }

  .trendingShoes .ryt_img_sec .content .name {
    font-size: 38px;
    line-height: 47px;
  }

  .trendingShoes .ryt_img_sec .content .showNow_btn {
    font-size: 16px;
    padding: 4px 30px !important;
    margin-top: 14px;
  }

  .trendingShoes .ryt_img_sec.fst .content {
    left: 15%;
  }

  .specialProducts .header_sec .txt {
    padding: 11px 50px;
    font-size: 24px;
  }

  .specialProducts .SplProd_Tabs .items {
    font-size: 20px;
  }

  .specialProduct_card {
    padding: 20px 18px;
  }

  .specialProduct_card .type {
    font-size: 14px;
  }

  .specialProduct_card .name {
    font-size: 17px;
  }

  .specialProduct_card .img-sec {
    height: 200px;
  }

  .specialProduct_card .original_amt {
    font-size: 15px;
  }

  .specialProduct_card .discount_amt {
    font-size: 18px;
  }

  .specialProduct_card .shopping_img {
    width: 45px;
    height: 45px;
  }

  .discover_now .img-sec {
    height: 225px;
  }

  .homeAll_products .latest_products {
    height: 550px;
  }

  .cmn_col3_sec {
    height: 271px;
  }

  .homeAll_products .prod_type.latest {
    font-size: 14px;
    padding: 9px 33px;
  }

  .homeAll_products .cmn_col3_sec .prod_type {
    font-size: 11px;
    padding: 7px 12px;
  }

  .homeAll_products .latest_products .name {
    font-size: 25px;
  }

  .homeAll_products .cmn_col3_sec .name {
    font-size: 16px;
  }

  .homeAll_products .latest_products .img_sec {
    width: 410px;
    height: 410px;
  }

  .homeAll_p1800 .new-released .ryt_halfImg {
    height: 550px;
  }

  .popularSales_card {
    padding: 19px 19px;
  }

  .popularSales_card .sale_percentage .txt {
    padding: 5px 19px;
    font-size: 13px;
  }

  .popularSales_card .sale_percentage .value {
    padding: 5px 15px;
    font-size: 13px;
  }

  .popularSales_card .prod_details .type {
    font-size: 16px;
  }

  .popularSales_card .prod_details .prod-modal {
    font-size: 19px;
  }

  .popularSales_card .features .feature_txt {
    font-size: 14px;
  }

  .popularSales_card .features .checkImg {
    height: 11px;
  }

  .popularSales_card .AddToCart {
    font-size: 15px;
    padding: 10px 20px !important;
  }

  .premiumProduct .product {
    height: 270px;
  }
  .premiumProduct .premiumProduct_Img img {
    height: 270px;
  }

  .footer .content_sec .heading_txt {
    font-size: 19px;
  }

  .footer .content_sec .sub_content .txt {
    font-size: 16px;
    margin-bottom: 20px !important;
  }

  .footer .post_footer .copyRight {
    font-size: 19px;
  }

  .footer .content_secs .sub_contents .txt {
    font-size: 16px;
  }

  .footer .social_icons .icons {
    height: 25px;
  }

  .footer .social_icons {
    gap: 17px;
  }

  .footer .pre_footer .container {
    max-width: 85% !important;
  }

  .footer .post_footer .container {
    max-width: 85% !important;
  }

  .productImg_preview .left {
    gap: 15px;
  }

  .productImg_preview .left_2 > div {
    width: 435px !important;
    height: 435px !important;
  }

  .productImg_preview .left_1 .allImgs {
    height: 393px;
    width: 80px;
  }

  .productImg_preview .img_wrap {
    width: 80px;
    height: 80px;
  }

  .productImg_preview .arrow-btn {
    height: 20px;
  }

  /* .productImg_preview .magnify-container {
    width: 270% !important;
    height: 180% !important;
    left: 440px !important;
  }
  .magnify-container-large {
    left: 440px !important;
  } */

  .prodDetail .alert_heading {
    font-size: 17px;
  }

  .prodDetail .bell_lottie svg {
    width: 23px !important;
    height: 23px !important;
  }

  .prodDetail .section1 .prod-name {
    font-size: 18px;
    font-weight: 600;
  }

  .prodDetail .amount .coin {
    font-size: 21px;
    gap: 8px;
  }

  .prodDetail .amount .coinImg {
    width: 26px;
    height: 26px;
  }

  .prodDetail .amount .new-amt {
    font-size: 16px;
  }

  .prodDetail .amount .old-amt {
    font-size: 14px;
  }

  .prodDetail .amount .reloadImg {
    height: 15px;
  }

  .prodDetail .amount .signImg {
    height: 7px;
  }

  .prodDetail .reviewRatings .rating-icons img {
    height: 17px;
  }

  .prodDetail .reviewRatings .rating-icons .ratVal {
    font-size: 15px;
  }

  .prodDetail .reviewRatings .verticalBar {
    font-size: 20px;
  }

  .prodDetail .reviewRatings .review,
  .prodDe.top_categories .categories_subContent_hvrtail .section4 .header-txt {
    font-size: 16px;
  }

  .prodDetail .productPhotos .photo {
    width: 15%;
  }

  .prodDetail .productPhotos {
    gap: 11px;
  }

  .prodDetail .productPhotos .img_sec {
    height: 105px;
  }

  .prodDetail .section4 .info {
    padding: 15px;
    font-size: 16px;
  }

  .product_details .shipAddress .shipHeading {
    font-size: 17px;
  }

  .product_details .shipAddress .locationBtn {
    font-size: 16px;
  }

  .shipAddress .section1 .delivery_lottie svg {
    width: 46px !important;
    height: 45px !important;
  }

  .shipAddress .section1 .delivery {
    font-size: 16px;
    gap: 7px;
  }

  .shipAddress .section1 .amount .coin {
    gap: 7px;
    font-size: 15px;
    border-radius: 50px;
    padding: 4px 7px;
  }

  .shipAddress .section1 .amount .coinImg {
    width: 17px;
    height: 17px;
  }

  .shipAddress .section1 .amount .checked {
    width: 17px;
    height: 17px;
  }

  .shipAddress .section1 .amount .amt-txt {
    font-size: 15px;
  }

  .shipAddress .section1 .amount .signImg {
    height: 7px;
  }

  .shipAddress .section1 .delivery-date {
    font-size: 16px;
  }

  .product_details .shipAddress .section1 {
    padding: 17px;
  }

  .shipAddress .section2 {
    padding: 17px;
  }

  .shipAddress .section3 {
    padding: 17px;
  }

  .shipAddress .section4 {
    padding: 17px;
  }

  .shipAddress .section5 {
    padding: 17px;
  }

  .shipAddress .section6 {
    padding: 17px;
  }

  .shipAddress .section7 {
    padding: 17px;
  }

  .shipAddress .section8 {
    padding: 17px;
  }

  .shipAddress .section2 .ship-from {
    font-size: 16px;
  }

  .cus_protection .header-txt {
    font-size: 16px;
  }

  .cus_protection .escrow_lock {
    font-size: 16px;
  }

  .cus_protection .escrow_lock .ques {
    width: 17px;
    height: 17px;
  }

  .cus_protection .escrow_lock .ques svg {
    width: 8px !important;
    height: 8px !important;
  }

  .cus_protection .lock_lottie {
    width: 50px !important;
    height: 50px !important;
  }

  .shipAddress .section4 .header-txt {
    font-size: 16px;
  }

  .shipAddress .section4 .inc_dec_btn {
    width: 30px;
    height: 30px;
  }

  .shipAddress .section4 .inc_dec_btn svg {
    height: 12px;
  }

  .shipAddress .section4 .count {
    font-size: 15px;
    font-weight: 500;
  }

  .shipAddress .section5 .coupons_heading {
    font-size: 16px;
  }

  .shipAddress .section5 .couponImg {
    height: 30px;
  }

  .couponsList .item .des {
    font-size: 15px;
  }

  .couponsList .item .amount .coin {
    gap: 7px;
    padding: 3px 6px;
    font-size: 15px;
  }

  .couponsList .item .amount .coinImg {
    width: 17px;
    height: 17px;
  }

  .couponsList .item .amount .checked {
    width: 17px;
    height: 17px;
  }

  .couponsList .item .amount .signImg {
    height: 6px;
    filter: invert();
  }

  .couponsList .item .amount .amt-txt {
    font-size: 15px;
  }

  .couponsList .item .coupon-id {
    height: 40px;
    padding: 3px 6px;
    font-size: 15px;
    padding: 0px 5px 0px 20px;
    margin-top: 18px;
  }

  .couponsList .item .coupon-id .apply-btn {
    font-size: 15px;
  }

  .couponsList .item .amount {
    gap: 9px;
    margin-top: 18px;
  }

  .section7 .total_price .header-txt {
    font-size: 16px;
  }

  .section7 .amount .coin {
    gap: 6px;
    font-size: 14px;
  }

  .section7 .amount .coinImg {
    width: 17px;
    height: 17px;
  }

  .section7 .amount .amt-txt {
    font-size: 15px;
  }

  .section7 .amount .signImg {
    height: 6px;
  }

  .section8 .total_price .header-txt {
    font-size: 16px;
  }

  .section8 .chain-list .list img {
    width: 25px;
    height: 25px;
  }

  .section8 .chain-list .list {
    font-size: 14px;
    gap: 10px;
    margin-bottom: 10p !important;
  }

  .shipAddress .bottom_content .BuyNow_btn {
    font-size: 16px;
    gap: 10px;
    padding: 12px 20px;
  }

  .shipAddress .bottom_content .BuyNow_btn img {
    width: 19px;
    height: 19px;
  }

  .shipAddress .bottom_content .AddtoCart_btn {
    font-size: 16px;
    gap: 10px;
    padding: 12px 20px;
    margin: 18px 0px;
  }

  .shipAddress .bottom_content .AddtoCart_btn img {
    width: 19px;
    height: 19px;
  }

  .shipAddress .share_btn {
    font-size: 17px;
    padding: 13px 50px;
  }

  .shipAddress .share_btn svg {
    height: 20px;
    width: 20px;
  }

  .shipAddress .wish_btn {
    padding: 12px 25px;
  }

  .shipAddress .wish_btn svg {
    height: 20px;
    width: 20px;
  }

  .prodDetail .item-info .txt {
    font-size: 13px;
  }

  .prodDetail .section2 .closeBtn {
    font-size: 13px;
  }

  .top_categories .categories_subContent_hvr {
    width: 1185px;
  }

  .categories_drop_content {
    top: 45px;
    left: -47px;
  }

  .wallet_dropContent {
    top: 46px;
  }

  .shop_icon_hvr .dropContent {
    top: 48px;
  }

  .product_details .shipAddress {
    top: 90px;
  }

  .product_description .header_tabsec .tab_items {
    font-size: 19px;
  }

  .product_description .tabs_content .header-txt {
    font-size: 30px;
  }

  .product_description .product_det .head_txt {
    font-size: 21px;
  }

  .product_det .all_details .name {
    font-size: 16px;
  }

  .specification .spec-details .item {
    font-size: 17px;
  }

  .specification .spec-details .spec-name {
    padding: 14px 20px;
  }

  .reviews .overall-review .txt {
    color: #0e1b28;
    font-size: 17px;
  }

  .reviews .overall-review .val {
    font-size: 44px;
  }

  .reviews .overall-review .ratingImg {
    height: 17px;
  }

  .reviews .reviews_tabs .tab_header {
    font-size: 19px;
  }

  .reviews .review_filter button {
    font-size: 16px;
    padding: 6px 24px;
  }

  .reviews .user-review .profile {
    min-width: 45px;
    min-height: 45px;
    width: 45px;
    height: 45px;
    font-size: 20px;
  }

  .reviews .user-review .personal-info .name {
    font-size: 17px;
    line-height: 20px;
  }

  .reviews .user-review .personal-info .date {
    font-size: 14px;
  }

  .reviews .user-review .ratingImg {
    height: 17px;
  }

  .reviews .user-review .review_txt {
    font-size: 16px;
    font-weight: 500;
  }

  .reviews .user-review .reviewImages img {
    width: 100px;
    height: 100px;
  }

  .review-pagination .arrow-btn {
    width: 38px;
    height: 38px;
  }

  .review-pagination .pageNo {
    gap: 20px;
    font-size: 17px;
  }

  .storePolicy .policy-type .name {
    font-size: 20px;
  }

  .storePolicy .policy-type .description {
    font-size: 15px;
  }

  .storePolicy .policy-type .checkBtn {
    font-size: 16px;
  }

  .userChart .user_questionBox .postQuesBtn {
    font-size: 14px;
  }

  .userChart .user_questionBox textarea {
    font-size: 17px;
  }

  .userChart .title-txt {
    font-size: 20px;
  }

  .userChart .Ques_Ans .name {
    font-size: 20px;
  }

  .userChart .Ques_Ans .value {
    font-size: 15px;
    position: relative;
    top: 3px;
  }

  .navShowHide_loader svg {
    top: 90px;
  }

  .searchProduct_filter {
    top: 92px;
  }

  .product_description .header_tabsec {
    top: 85px;
  }

  .new-released .lft_bigImg {
    height: 540px;
  }

  .new-released .ctr_smlImg {
    height: 270px;
  }

  .new-released .ryt_halfImg {
    height: 540px;
  }
  .shippingAddress_list .shipHeading {
    padding: 12px 15px;
  }
  .frequentlyAskQus .shipHeading {
    padding: 12px 15px;
  }
  .shopping_Cart .shipHeading {
    padding: 12px 15px;
  }
  .product_summary .heading_txt {
    padding: 12px 15px;
  }
  .product_summary .shopMore_btn {
    padding: 8px 50px;
  }
  .product_summary .checkout_btn {
    padding: 8px 50px;
  }
}
@media (max-width: 575px) {
  .header .search_sec {
    width: 285px;
  }
  .popularSales_card .img_sec {
    height: 160px;
  }
  .bitdealz_marketplace_modal .modal-body {
    padding: 20px;
  }
  .bitdealz_marketplace_modal .type .account {
    font-size: 14px;
  }
  .bitdealz_marketplace_modal .account .acc-img {
    width: 33px;
    height: 33px;
  }
  .bitdealz_marketplace_modal .account .acc-img img {
    width: 21px;
    height: 21px;
  }
  .bitdealz_marketplace_modal .form-check-label {
    font-size: 13px;
    position: unset;
  }
  .bitdealz_marketplace_modal .signupBtn {
    margin-top: 30px;
  }
  .signUp_modal .modal-body {
    padding: 20px 30px;
  }
  .signUp_modal .signUpWallet {
    width: 90px;
    height: 90px;
  }
  .signUp_modal .head_sec {
    width: 95%;
  }
  .signUp_modal .wallet .wallet-img {
    width: 30px;
    height: 30px;
  }
  .signUp_modal .wallet {
    font-size: 14px;
  }
  .cmn_checkbox label {
    font-size: 13px;
  }
  .signUp_modal .already-acc {
    font-size: 14px;
  }
  .signUp_modal .bitdealz-txt {
    font-size: 14px;
  }
  .reviews .review_filter {
    gap: 10px;
  }
  .AddStorepage .removeTime {
    position: unset;
  }
  .AddStorepage .removeTime .removeIcon {
    right: 0px;
    top: 5px;
    left: unset;
  }
}
@media (max-width: 767px) {
  .product_card1 .img_sec .productImg {
    padding: 30px;
  }

  .shop_categories .brand_details .row .col:nth-child(11),
  .shop_categories .brand_details .row .col:nth-child(12) {
    margin-bottom: 0px;
  }

  .specialProducts .SplProd_Tabs .items {
    padding: 10px 10px 0px 10px;
  }
  .Stores_hours .days_list {
    gap: 15px;
  }
  .prod_shippingType .check_list {
    gap: 15px;
  }
}
@media (max-width: 991px) {
  .header .header_content {
    flex-direction: column;
    align-items: start;
    gap: 15px;
    margin-top: 15px;
  }

  .header .search_sec {
    margin-left: unset;
    margin-right: unset;
  }

  .header .nav_customContainer {
    max-width: unset;
  }

  .shop_icon_hvr .dropContent {
    right: -130px;
  }

  .wallet_dropContent {
    right: -110px;
  }

  .header .navbar-toggler {
    box-shadow: none !important;
  }

  .header .snd_nav {
    flex-direction: column;
    align-items: start;
    gap: 15px;
  }

  .langInr_drop_hvr .langInr_drop_content {
    right: unset;
    left: 0px;
  }

  .product_card1 .img_sec {
    /* height: 200px; */
  }

  .header .snd_nav.hide {
    margin-top: -130px;
  }

  .categories_drop_content {
    left: 0px;
    min-width: 240px;
    padding-left: 0px;
  }

  .top_categories .categories_subContent_hvr {
    /* left: 240px; */
  }

  .select-filter-sec-web {
    display: none;
  }

  .select-filter-sec-mbl {
    display: block;
  }
}
@media (max-width: 1199px) {
  .product_summary .btns {
    justify-content: center;
    gap: 20px;
  }

  .product_summary .freeDelivery_eligible {
    font-size: 13px;
  }

  .product_summary .shopMore_btn {
    padding: 6px 20px;
  }

  .product_summary .checkout_btn {
    padding: 6px 20px;
  }
}
@media (max-width: 1399px) {
}
@media (max-width: 1799px) {
}
@media (max-width: 1399px) {
}
@media (max-width: 1799px) {
}
