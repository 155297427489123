:root {
  --black: #000;
  --white: #fff;
  --violet: #8777ed;
  --header_btnBg: #eff1f3;
  --secondary_black: #191919;
  --left_pink: #e3488a;
  --right_pink: #c4509b;
  --left_blue: #4776fe;
  --right_blue: #4ab3fe;
  --left_violet: #6647bf;
  --right_violet: #5a3ab5;
  --left__orange: #fb8553;
  --right_orange: #f27944;
  --dark_orange: #dd6e3d;
  --left_green: #4b9a24;
  --right_green: #9cd947;
  --card_green: #5ba62b;
  --chat_desc: #66657c;
  --chat_msg: #363434;
  --chat_sendMsgBg: #f5f5f5;
  --weight_four: 400;
  --weight_five: 500;
  --weight_six: 600;
  --weight_seven: 700;
  --weight_eight: 800;
  --weight_nine: 900;
  --small_font: 8px;
  --lower_fontsize: 10px;
  --font_eleven: 11px;
  --font_twelve: 12px;
  --font_thirteen: 13px;
  --font_fifteen: 15px;
  --font_sixteen: 16px;
  --font_seventeen: 17px;
  --font_eighteen: 18px;
  --font_twenty: 20px;
  --font_twentyfive: 25px;
  --higher_fontsize: 30px;
  --font_thirtyfive: 35px;
  --font_fourty: 40px;
  --font_fourtyfive: 45px;
  --font_fifty: 50px;
}
/* common css */
.pointer {
  cursor: pointer !important;
}
/* end of common css */
.dash__topCard {
  background-image: url("./Assets/Images/vioCard.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px;
  min-height: 250px;
  /* max-height: 250px; */
}
.dash__topNetworkCard {
  background-image: url("./Assets/Images/networkCard.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px;
  min-height: 250px;
  max-height: 250px;
}
.dashCard__chipImg {
  height: 60px;
  width: 60px;
}
.dashCard__label {
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
}
.dashCard__value {
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.dashCard__coinImg {
  height: 18px;
  width: 18px;
  min-height: 18px;
  min-width: 18px;
  max-height: 18px;
  max-width: 18px;
}
.dashCard__whiteBtn {
  border: 1px solid var(--white);
  outline: none;
  color: var(--violet);
  padding: 5px 40px;
  background-color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
}
.dashCard__singleNetwork {
  border: 1px solid var(--white);
  outline: none;
  background-color: transparent;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
  padding: 5px 8px;
}
.dashCard__singleNetwork.selected {
  border-color: transparent;
  background-color: var(--black);
  color: var(--white);
}
.dashCard__networksHolder {
  max-height: 160px;
  overflow-y: auto;
}

/* dashHeader css */
.dash_header {
  box-shadow: 0px 0px 8px 1px #00000029;
}
.dashHeader__btns {
  border: none;
  outline: none;
  background-color: var(--header_btnBg);
  font-size: var(--font_thirteen);
  padding: 5px 7px;
}
.dashHeader__btnImages {
  height: 18px;
  width: 18px;
  min-height: 18px;
  min-width: 18px;
  max-height: 18px;
  max-width: 18px;
  object-fit: contain;
}
.dashHeader__searchHolder {
  background-color: var(--header_btnBg);
}
.dashHeader__searchHolder input {
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--black);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
  max-width: 100%;
}
.dashHeader__desc {
  color: var(--secondary_black);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_six);
}
/* end of dashHeader css */

/* dashboard css */
.overview__title {
  color: var(--secondary_black);
  font-size: var(--font_seventeen);
  font-weight: var(--weight_five);
}
.escrowCard__label {
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
}
.escrowCard__select__control {
  background-image: linear-gradient(
    to right,
    var(--left_green),
    var(--right_green)
  );
  padding-left: 15px;
  font-size: var(--font_twelve);
  border-radius: 30px !important;
  min-height: 30px !important;
  max-height: 30px !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.escrowCard__small {
  color: var(--white);
  font-size: var(--lower_fontsize);
  font-weight: var(--weight_four);
}
.escrowCard__selectImg {
  height: 15px;
  width: 15px;
  position: absolute;
  left: 5%;
  top: 28%;
  z-index: 2;
}
.escrowCard__select__single-value {
  color: var(--white) !important;
  font-size: var(--lower_fontsize);
}
.market_coinname_select {
  max-width: 85px;
  min-width: 80px;
}
.escrowCard__select__indicator-separator {
  display: none;
}
.escrowCard__select__indicator.escrowCard__select__dropdown-indicator {
  padding: 0 !important;
  padding-right: 3px !important;
}
.overview__selectCompoValue {
  font-size: var(--lower_fontsize);
  color: var(--black);
  font-weight: var(--weight_five);
}
.dashboard__escrowCard {
  background-size: 100% 100% !important;
  background-position: center;
  background-repeat: no-repeat;
}
.escrowCardPairImg {
  height: 15px;
  width: 15px;
}
.escrowCardPair__value {
  color: var(--black);
  font-size: var(--lower_fontsize);
  font-weight: var(--weight_five);
}
.dashboard__escrowCardPair {
  background-color: var(--white);
  width: fit-content;
  position: relative;
  top: -10px;
}
.escrowCard__graphImg {
  max-height: 110px;
}
.escrowCard__select__indicator.escrowCard__select__dropdown-indicator svg {
  fill: var(--white) !important;
  height: 15px !important;
  width: 15px !important;
}
/* .dashboard__escrowCard.dashboard__pinkBg {
  background-image: url("./Assets/Images/pinkBg.png");
}
.dashboard__escrowCard.dashboard__skyBg {
  background-image: url("./Assets/Images/skyBg.png");
}
.dashboard__escrowCard.dashboard__violetBg {
  background-image: url("./Assets/Images/vioCard.png");
}
.dashboard__escrowCard.dashboard__orangeBg {
  background-image: url("./Assets/Images/orangeBg.png");
} */
/* end of dashboard css */
/* wishlist css */
.wishlist__greenBtn {
  background-color: var(--card_green);
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  border: 1px solid var(--card_green);
  outline: none;
  padding: 7px 5px;
}
/* end of wishlist css */
/* chat css */
.chat__searcher {
  border: none;
  outline: none;
  background-color: var(--header_btnBg);
  color: var(--secondary_black);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.chat__shadowBox {
  background-color: var(--white);
  box-shadow: 0px 0px 6px 0px #00000029;
}
.chat__userImg {
  height: 35px;
  width: 35px;
}
.chat__senderName {
  color: var(--secondary_black);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.chat__senderLabel {
  color: var(--dark_orange);
  font-size: var(--lower_fontsize);
  font-weight: var(--weight_five);
}
.chat__senderDesc {
  color: var(--chat_desc);
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
}
.chat__chatTopBar {
  background-color: var(--header_btnBg);
  box-shadow: 0px 0px 6px 0px #00000029;
}
.chat__chatBox {
  background-color: var(--white);
  box-shadow: 0px 0px 6px 0px #00000029;
}
.chat__receivedMsgDesc {
  color: var(--chat_msg);
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
}
.chat__receivedTimer {
  color: var(--chat_msg);
  font-size: var(--small_font);
  font-weight: var(--weight_five);
}
.chat__receivedMsgCont {
  background-color: var(--chat_sendMsgBg);
  width: fit-content;
}
.chat__receivedMsg,
.chat__sendMsg {
  width: fit-content;
  max-width: 85%;
}
.chat__sendMsgCont {
  background-color: var(--card_green);
  width: fit-content;
}
/* end of chat css */

/* start of hariharan css */

@media (min-width: 576px) {
  .dash__topCard {
    background-image: url("./Assets/Images/vioCard.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 30px;
    min-height: 250px;
    max-height: 250px;
  }
  .dash__topNetworkCard {
    background-image: url("./Assets/Images/networkCard.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 30px;
    min-height: 250px;
    max-height: 250px;
  }
}
@media (min-width: 1920px) {
  .dash__topCard {
    background-image: url("./Assets/Images/vioCard.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 30px;
    min-height: 300px;
    max-height: 300px;
  }
  .dash__topNetworkCard {
    background-image: url("./Assets/Images/networkCard.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 30px;
    min-height: 300px;
    max-height: 300px;
  }
}
.escrowCardPairImg{
    height: 15px;
    width: 15px;
}
.escrowCardPair__value{
    color: var(--black);
    font-size: var(--lower_fontsize);
    font-weight: var(--weight_five);
}
.dashboard__escrowCardPair{
    background-color: var(--white);
    width: fit-content;
    position: relative;
    top: -10px;
}
.escrowCard__graphImg{
    max-height: 110px;
}
.escrowCard__select__indicator.escrowCard__select__dropdown-indicator svg{
    fill: var(--white) !important;
    height: 15px !important;
    width: 15px !important;
}
/* .dashboard__escrowCard.dashboard__pinkBg{
    background-image: url('./Assets/Images/pinkBg.png');
}
.dashboard__escrowCard.dashboard__skyBg{
    background-image:  url('./Assets/Images/skyBg.png');;
}
.dashboard__escrowCard.dashboard__violetBg{
    background-image: url('./Assets/Images/vioCard.png');
}
.dashboard__escrowCard.dashboard__orangeBg{
    background-image:  url('./Assets/Images/orangeBg.png');;
} */
/* end of dashboard css */
/* wishlist css */
.wishlist__greenBtn{
    background-color: var(--card_green);
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    border: 1px solid var(--card_green);
    outline: none;
    padding: 7px 5px;
}
/* end of wishlist css */
/* chat css */
.chat__searcher{
    border: none;
    outline: none;
    background-color: var(--header_btnBg);
    color: var(--secondary_black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_four);
}
.chat__shadowBox{
    background-color: var(--white);
    box-shadow: 0px 0px 6px 0px #00000029;
}
.chat__userImg{
    height: 35px;
    width: 35px;
}
.chat__senderName{
    color: var(--secondary_black);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
}
.chat__senderLabel{
    color: var(--dark_orange);
    font-size: var(--lower_fontsize);
    font-weight: var(--weight_five);
}
.chat__senderDesc{
    color: var(--chat_desc);
    font-size: var(--font_twelve);
    font-weight: var(--weight_four);
}
.chat__chatTopBar{
    background-color: var(--header_btnBg);
    box-shadow: 0px 0px 6px 0px #00000029;
}
.chat__chatBox{
    background-color: var(--white);
    box-shadow: 0px 0px 6px 0px #00000029;
}
.chat__receivedMsgDesc{
    color: var(--chat_msg);
    font-size: var(--font_twelve);
    font-weight: var(--weight_four);
}
.chat__receivedTimer{
    color: var(--chat_msg);
    font-size: var(--small_font);
    font-weight: var(--weight_five);
}
.chat__receivedMsgCont{
    background-color: var(--chat_sendMsgBg);
    width: fit-content;
}
.chat__receivedMsg,.chat__sendMsg{
    width: fit-content;
    max-width: 85%;
}
.chat__sendMsgCont{
    background-color: var(--card_green);
    width: fit-content;
}
.chat__msgScroller{
    min-height: 400px;
    max-height: 400px;
    overflow-y: auto;
}
.chat__recentChatter{
    max-height: 100%;
    overflow-y: auto;
}
.chat__addonsHolder{
    background-color: var(--chat_sendMsgBg);
    position: relative;
    bottom: 15px;
}
.chat__msgTyper{
    border: none;
    outline: none;
    min-width: 100%;
    max-width: 100%;
    background-color: transparent;
    color: var(--secondary_black);
    font-size: var(--lower_fontsize);
    font-weight: var(--weight_four);
}
.chat__plugInsBtn{
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--secondary_black);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_five);
    overflow: hidden;
}
.chat__plugInsBtn img{
    height: 12px;
    width: 12px;
}
.chat__greenSendBtn{
    border: none;
    outline: none;
    background-color: var(--card_green);
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
    padding: 4px 8px;
}
.chat__emojiHolder{
    position: absolute;
    bottom: 20%;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}
.chat__emojiHolder .EmojiPickerReact{
    height: 350px !important;
    width: 100% !important;
    min-width: 100%;
    max-width: 100%;
}
.chat__filesUploader{
    position: absolute;
    max-width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
}

/* end of chat css */

/* media query */
@media(width < 768px){
    .chat__shadowBoxScroller{
        max-height: 400px;
        overflow-y: auto;
    }
}
/* end of media query */


@media (min-width: 1200px) and (max-width: 1399px) {
  .dash__topCard {
    min-height: 240px;
    max-height: 240px;
  }
  .dash__topNetworkCard {
    min-height: 240px;
    max-height: 240px;
  }
  .dashCard__whiteBtn {
    padding: 8px 42px;
  }
}