:root {
  --sidebar_xs: 54px;
  --sidebar_lg: 300px;
  --header_height: 70px;
  --darkGrayClr: #191919;
  --whiteClr: #fff;
  --blackClr: #000;
  --greenClr: #5ba62b;
  --greenClr2: #2a6d00;
  --greenClr3: #22b14c;
  --greenClr4: #4e9000;
  --redClr: #dd3d4c;
  --redClr2: #f34841;
  --purpleClr: #5e3eb8;
  --pinkClr: #ce4d95;
  --grayClr: #eff1f3;
  --orangeClr: #ff7f27;
  --orangeClr2: #dd6e3d;
  --yellowClr: #fcb72c;
  --blueClr: #00a2e8;
  --grayClr2: #66657c36;
  --grayClr3: #66657c;
  --grayClr4: #edf2f4;
  --grayClr5: #aeaeae;
  --grayClr6: #616161;
  --grayClr7: #768b9e;
  --grayClr8: #f0f0f0;
  --grayClr9: #eaeaec;
  --side_box_shadow: 0px 0px 6px 0px gray;
  --green_linear: linear-gradient(312deg, #4b9a24 0%, #9cd947 100%);
  --green_linear2: linear-gradient(83deg, #479723 0%, #9cd947 100%);
  --green_linear3: linear-gradient(83deg, #9cd947 0%, #479723 100%);
}

/* start of scrollbar css */

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--greenClr);
  border-radius: 10px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

/* end of scrollbar css */


/* manoj css start */
.dash_mainbar {
  background: #f9fbfc;
}
/* manoj css end */
.red_border_less_btn {
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 15px;
  color: var(--redClr);
}
.container_95 {
  max-width: 95% !important;
}
.img_container_fit {
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
}
.w_150 {
  min-width: 150px;
  width: 150px;
}
.w_180 {
  min-width: 180px;
  width: 180px;
}
.w_200 {
  min-width: 200px;
  width: 200px;
}
.w_250 {
  min-width: 250px;
  width: 250px;
}
.w_300 {
  min-width: 300px;
  width: 300px;
}
.mt_4rem {
  margin-top: 4rem;
}
.fw_black_sm {
  font-weight: 500;
  font-size: 16px;
}
.gray_text_sm {
  color: var(--grayClr6);
  font-size: 13px;
}
.red_text_sm {
  color: var(--redClr2);
}
.strike_gray_text {
  color: var(--grayClr7);
  font-size: 13px;
}
.orange_text_sm {
  color: var(--orangeClr2);
  font-weight: 500;
  font-size: 14px;
}
.p_bold_sm {
  font-weight: 500;
  font-size: 15px;
}

.p_normal_xs {
  font-size: 14px;
  width: 80%;
}
.p_bold_sm_xxs {
  font-weight: 500;
  font-size: 14px;
  color: var(--blackClr);
}
.title_md {
  font-weight: 450;
  font-size: 17px;
}
.title_sm {
  font-weight: 450;
  font-size: 15px;
}
.gray_circle_btn {
  background: var(--grayClr);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.green_btn {
  background: var(--greenClr);
  color: var(--whiteClr);
  border: 0;
  outline: 0;
  font-size: 14px;
  padding: 7px 25px;
  border-radius: 5px;
  height: 40px;
}
.green_btn_sm {
  background: var(--greenClr);
  color: var(--whiteClr);
  border: 0;
  outline: 0;
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
  height: 35px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.gray_rect_btn {
  background: var(--grayClr);
  border-radius: 5px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  padding: 5px;
}
.half_round_btn {
  background: var(--grayClr);
  color: var(--blackClr);
  border: 0;
  outline: 0;
  font-size: 14px;
  padding: 6px 25px;
  border-radius: 20px;
  height: 40px;
}
.half_round_btn.active {
  background: var(--greenClr);
  color: var(--whiteClr);
  border: 0;
  outline: 0;
  font-size: 14px;
  padding: 6px 25px;
  border-radius: 20px;
  height: 40px;
}
.full_width_btn {
  background: var(--grayClr8) !important;
  border: 1px solid var(--grayClr8) !important;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  color: var(--blackClr) !important;
  border-radius: 20px;
}

.full_width_btn.active {
  background: transparent !important;
  border: 1px solid var(--greenClr4) !important;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  color: var(--greenClr4) !important;
  border-radius: 20px;
}

.full_width_btn:hover {
  background: transparent !important;
  border: 1px solid var(--greenClr4) !important;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  color: var(--greenClr4) !important;
  border-radius: 20px;
}

.theme_linear_button {
  background: var(--green_linear2);
  color: var(--whiteClr);
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
  border: none;
  border-radius: 20px;
  font-weight: 500;
}
.theme_linear_button:hover {
  background: var(--green_linear3);
  color: var(--whiteClr);
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
  border: none;
  border-radius: 20px;
  font-weight: 500;
}
.transparent_button {
  background: transparent !important;
  color: var(--blackClr) !important;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
  border: none;
  border-radius: 20px;
  font-size: 15px;
}
.transparent_button:hover {
  color: #4b9a24 !important;
}
.status_badge {
  border: none;
  outline: none;
  color: var(--whiteClr);
  padding: 6px;
  min-width: 100px;
  border-radius: 5px;
  transition: 0.3s;
  white-space: nowrap;
}
.status_badge.green {
  background: var(--greenClr2);
}
.status_badge.pink {
  background: var(--pinkClr);
}
.status_badge.red {
  background: var(--redClr);
}
.status_badge.purple {
  background: var(--purpleClr);
}
.status_badge.purple {
  background: var(--purpleClr);
}
.status_badge.green2 {
  background: var(--greenClr3);
}
.status_badge.green3 {
  background: var(--greenClr);
}
.status_badge.green3:hover,
.status_badge.greeninvert:hover {
  transform: translateY(-5px);
}
.status_badge.greeninvert {
  background: var(--grayClr);
  color: var(--greenClr);
}
.status_badge.orange {
  background: var(--orangeClr);
}
.status_badge.blue {
  background: var(--blueClr);
}
.status_badge.yellow {
  background: var(--yellowClr);
  color: var(--blackClr);
}
/* end of common css */

/* start of sidebar css */

.dash_sidebar {
  position: fixed;
  top: 0;
  left: 0;
  background: var(--whiteClr);
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  z-index: 10;
  transition: 0.3s;
  border-bottom-right-radius: 20px;
}
.dash_sidebar_content {
  transition: 0.3s;
  width: var(--sidebar_xs);
  box-shadow: var(--side_box_shadow);
  border-bottom-right-radius: 20px;
}
.dash_sidebar_content.active {
  width: var(--sidebar_lg);
}
.sidebar_header {
  background: var(--darkGrayClr);
  height: var(--header_height);
  border-bottom-right-radius: 20px;
}

.sidebar_logo {
  width: 150px;
}
.sidebar_controller_button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  outline: none;
  background: var(--green_linear);
}
.sidebar_body {
  height: calc(100vh - var(--header_height));
  border-bottom-right-radius: 20px;
}
.sidebar_ul {
  max-height: 70vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

.sidebar_navlink .sidebar__linkOnly {
  padding-top: 10px;
  padding-bottom: 10px;
}
.sidebar_navlink .sidebar__linkOnly img {
  width: 18px;
}
.sidebar_navlink .sidebar__linkOnly .sidebar_name {
  color: var(--blackClr);
  font-size: 15px;
}
.sidebar_navlink.active .sidebar__linkOnly {
  background-color: var(--greenClr) !important;
  border-radius: 30px;
}
.sidebar_navlink.active .sidebar__linkOnly img {
  filter: brightness(99);
}
.sidebar_navlink.active .sidebar__linkOnly .sidebar_name {
  color: var(--whiteClr);
}
.sidebar_navlink:hover .sidebar__linkOnly {
  background-color: var(--greenClr) !important;
  border-radius: 30px;
}
.sidebar_navlink:hover .sidebar__linkOnly img {
  filter: brightness(99);
}
.sidebar_navlink:hover .sidebar__linkOnly .sidebar_name {
  color: var(--whiteClr);
}
/* .sidebar_navlink:hover .sidebar__greenLine {
  background-color: var(--greenClr);
  border-radius: 30px;
} */
.sidebar_navlink .sidebar__greenLine {
  position: absolute;
  height: 42.5px;
  width: 5px;
  background-color: transparent;
  left: 0;
  top: 0;
}
.sidebar_navlink.active .sidebar__greenLine {
  position: absolute;
  border-radius: 5px;
  height: 42.5px;
  width: 5px;
  background-color: var(--greenClr);
}

.sidebar_navlink:hover .sidebar__greenLine {
  background-color: var(--greenClr);
}

/* end of sidebar css */

/* start of header css */

.dash_header {
  height: var(--header_height);
  background: var(--whiteClr);
  position: fixed;
  width: calc(100% - var(--sidebar_xs));
  margin-left: var(--sidebar_xs);
  transition: 0.3s;
  top: 0;
  left: 0;
  z-index: 9;
}
.dashHeader__searchHolder {
  max-width: 320px;
}

/* end of header css */

/* start of offcanva css */

.custom_canva.offcanvas.offcanvas-top {
  height: 60vh !important;
}

.custom_canva .offcanvas-header {
  height: var(--header_height) !important;
  background: var(--darkGrayClr);
}
.custom_canva .offcanvas-header .btn-close {
  display: none;
}
/* end of offcanva css */

/* start of mainbar css */

.dash_mainbar {
  width: 100%;
  transition: 0.3s;
  padding-top: var(--header_height);
}
.width_full {
  width: calc(100% - var(--sidebar_xs));
  margin-left: var(--sidebar_xs);
  transition: 0.3s;
}
.width_less {
  width: calc(100% - var(--sidebar_xs));
  margin-left: var(--sidebar_xs);
  transition: 0.3s;
}
.table_card {
  border: 1px solid var(--grayClr2);
  border-radius: 10px;
}

.gray_input {
  background: var(--grayClr);
  width: 100%;
  max-width: 230px;
  border: none;
  outline: none;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  font-size: 13px;
}

/* end of mainbar css */

/* start of react data table css */

.custom_table .table.table-bordered.table-striped thead tr {
  border: none;
  border-top: 1px solid var(--grayClr2);
}
.custom_table .table.table-bordered.table-striped tbody tr {
  border: none;
  background: none;
}
.custom_table .table.table-bordered.table-striped thead tr th {
  background: var(--grayClr);
  color: var(--grayClr3);
  font-weight: 450;
  font-size: 14px;
  padding: 15px 20px;
  border: none;
  vertical-align: middle;
}
.custom_table .table.table-bordered.table-striped tbody tr td {
  background: none;
  color: var(--blackClr);
  vertical-align: middle;
  font-size: 14px;
  padding: 20px;
  border: none;
}
.custom_table .table > :not(caption) > * > * {
  box-shadow: none !important;
}
.custom_table .table-body.asrt-table-body {
  margin-bottom: 16px;
  overflow-x: scroll;
  margin-top: 0px !important;
}
.custom_table .as-react-table {
  overflow: hidden;
}
.table_image_wrapper_sm {
  min-width: 30px;
  width: 30px;
  max-width: 30px;
  min-height: 30px;
  height: 30px;
  max-height: 30px;
}
.table_image_wrapper_sm img {
  object-fit: contain;
}
.table_image_wrapper_xs {
  min-width: 16px;
  width: 16px;
  max-width: 16px;
  min-height: 16px;
  height: 16px;
  max-height: 16px;
}
.table_image_wrapper_xs img {
  object-fit: contain;
}
/* end of react data table css */

.coupon_card {
  border: 4px solid var(--grayClr4);
  /* max-width: 350px; */
}
.coupon_img_wraper {
  min-width: 90px;
  width: 90px;
  max-width: 90px;
  min-height: 90px;
  height: 90px;
  max-height: 90px;
  overflow: hidden;
}
.coupon_img_wraper img {
  object-fit: contain;
}
.coupon_middle {
  width: 28px;
}
.coupon_vertical {
  height: 100%;
  border-left: 1px dashed var(--grayClr5);
  position: relative;
  left: 50%;
}
.cut_lottie {
  bottom: 0;
  left: 1px;
  animation: cutter 4s linear infinite;
}
@keyframes cutter {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 50%;
  }
  100% {
    bottom: 85%;
  }
}
.coupon_right {
  padding: 10px 10px;
}
.coupon_body_card {
  background: var(--whiteClr);
  box-shadow: var(--side_box_shadow);
  border-radius: 5px;
  margin-top: 30px;
}
.wishlist_card {
  max-width: 230px;
}
.wishlist_top {
  background: var(--grayClr4);
  padding: 10px;
}
.wish_image_wrap {
  min-width: 100px;
  width: 100px;
  max-width: 100px;
  min-height: 100px;
  height: 100px;
  max-height: 100px;
}

.wish_image_wrap img {
  object-fit: contain;
}

.card_sec .feature_product_card {
  padding: 10px !important;
}

.mdl_max_wid .modal-content {
  max-width: 450px !important;
}
.qr_wraper {
  min-width: 150px;
  width: 150px;
  max-width: 150px;
  min-height: 150px;
  height: 150px;
  max-height: 150px;
  overflow: hidden;
  /* background: gray; */
}
.qr_wraper img {
  width: 100%;
  height: 100%;
}

.secondary_modal .modal-body {
  padding: 0px;
}
.sm_mdl_header {
  border-bottom: 1px solid var(--grayClr9);
}
.sm_qr_wrapper {
  min-width: 240px;
  width: 240px;
  max-width: 240px;
  min-height: 240px;
  height: 240px;
  max-height: 240px;
  box-shadow: 0px 0px 4px 0px gray;
  border-radius: 5px;
}
.sm_qr_wrapper img {
  object-fit: contain;
}
.address_card {
  min-width: 240px;
  width: 240px;
  max-width: 240px;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 0px gray;
}




/* manoj css start */
.addresspage .form-label {
  color: var(--grayClr6);
  font-size: 14px;
}

.addresspage .form-label .spanstar {
  color: var(--redClr);
}

.addresspage input.form-control {
  background: var(--grayClr);
  box-shadow: none;
  outline: 0;
  border: none;
}

.addresspage input.form-control:focus {
  box-shadow: none;
  outline: 0;
  border: none;
}

.addresspage .dropstyle {
  width: 100%;
}

.addresspage .dropstyle button {
  background: var(--grayClr);
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--blackClr);
}

.addresspage .dropstyle button:focus {
  background: var(--grayClr);
}

.addresspage .dropstyle .dropdown-menu {
  width: 100%;
}

.addresspage .cmn_linearBtn {
  background: transparent
    linear-gradient(83deg, var(--green-shade) 0%, var(--green2) 100%) 0% 0%
    no-repeat padding-box;
  color: var(--white);
  outline: none;
  box-shadow: none;
  border: 1px solid var(--green-shade) !important;
  width: 150px;
}

.addresspage .btnrow {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: row;
}

.addresspage .cmn_linearBtn:hover {
  background: transparent
    linear-gradient(83deg, var(--green2) 0%, var(--green-shade) 100%);
  border: 1px solid var(--green-shade) !important;
  color: var(--white);
}

.addresspage .cmn_cancel {
  background: var(--grayClr) no-repeat padding-box;
  color: var(--black);
  outline: none;
  box-shadow: none;
  border: 1px solid var(--grayClr) !important;
  width: 150px;
}

.addresspage .cmn_cancel:hover {
  background: var(--black) !important;
  border: 1px solid var(--black) !important;
  color: var(--white);
}

.allnotify_page .notifycard {
  background: var(--white) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 3px 1px var(--black1);
  /* min-height: 450px; */
  max-height: 450px;
  overflow: auto;
}

.allnotify_page .notifycard ul {
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 0px;
}

.allnotify_page .notifycard ul li {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.allnotify_page .notifycard ul li:last-child {
  margin-bottom: 0px;
}
.allnotify_page .notifycard ul li .pfimg {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
}

.allnotify_page .notifycard ul .notifydesc {
  color: var(--darkGrayClr);
  font-size: 14px;
  margin-bottom: 5px;
}

.allnotify_page .notifycard ul .notifydate {
  color: var(--grayClr7);
  font-size: 12px;
  margin-bottom: 0px;
}
.accounts_page .tabinnercontent {
  border: 1px solid var(--grayClr2);
  border-radius: 10px;
}
.accounts_page .tabinnercontent .innerheadsec {
  background: var(--grayClr);
  padding: 10px 20px;
  border-radius: 10px;
  border: 0.5px solid #66657c;
  display: flex;
  align-items: center;
  gap: 10px;
}
.accounts_page .tabinnercontent .innerheadsec .head {
  font-size: 14px;
  margin-bottom: 0px;
}
.accounts_page .tabinnercontent .innerheadsec .headimg {
  width: 35px;
  height: 35px;
  object-fit: cover;
}
.accounts_page .tabinnercontent .innerbtmsec {
  padding: 20px;
}
.accounts_page .tabinnercontent .innerbtmsec .pf_details .userrow {
  display: flex;
  align-items: center;
  gap: 20px;
}

.accounts_page .tabinnercontent .innerbtmsec .cmn_linearBtn {
  background: transparent
    linear-gradient(83deg, var(--green-shade) 0%, var(--green2) 100%) 0% 0%
    no-repeat padding-box;
  color: var(--white) !important;
  width: 120px;
}
.accounts_page .tabinnercontent .innerbtmsec .cmn_linearBtn:hover {
  background: transparent
    linear-gradient(83deg, var(--green2) 0%, var(--green-shade) 100%);
  color: var(--white) !important;
}
.accounts_page .tabinnercontent .innerbtmsec input.form-control {
  background: var(--grayClr);
  box-shadow: none;
  outline: 0;
  border: 1px solid #dcdcdc;
  font-size: 13px;
}

.accounts_page .tabinnercontent .innerbtmsec input.form-control:focus {
  box-shadow: none;
  outline: 0;
  border: 1px solid #dcdcdc;
}

/* manoj css end */

/* manoj css start */
.accounts_page .tabinnercontent .currency_sec .currency_card {
  background: var(--white);
  border-radius: 5px;
}
.accounts_page .tabinnercontent .currency_sec .currency_card .headsec {
  background: var(--black);
  color: var(--white);
  text-align: center;
  padding: 10px;
  border-radius: 5px;
}
.accounts_page .tabinnercontent .currency_sec .currency_card .headsec p {
  margin-bottom: 0px;
  font-size: 14px;
}
.accounts_page
  .tabinnercontent
  .currency_sec
  .currency_card
  .midsec
  .input-group-text {
  background: var(--grayClr);
  border-right: none;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.accounts_page .tabinnercontent .currency_sec .currency_card .midsec {
  padding: 25px;
}
.accounts_page
  .tabinnercontent
  .currency_sec
  .currency_card
  .midsec
  input.form-control {
  border-left: none;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  background: var(--grayClr);
}
.accounts_page
  .tabinnercontent
  .currency_sec
  .currency_card
  .midsec
  input.form-control::placeholder {
  color: var(--grayClr7);
  font-size: 14px;
}
.accounts_page .tabinnercontent .currency_sec .currency_card .cmn_linearBtn {
  width: 100%;
}
.accounts_page .tabinnercontent .currency_sec .currency_card .midsec .listsec {
  list-style: none;
  padding-left: 0px;
  height: 180px;
}
.accounts_page
  .tabinnercontent
  .currency_sec
  .currency_card
  .midsec
  .listsec
  li {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.accounts_page
  .tabinnercontent
  .currency_sec
  .currency_card
  .midsec
  .listsec
  .leftsec {
  display: flex;
  gap: 10px;
  align-items: center;
}
.accounts_page
  .tabinnercontent
  .currency_sec
  .currency_card
  .midsec
  .listsec
  .leftsec
  .flagimg {
  width: 35px;
  height: 35px;
  object-fit: contain;
}
.accounts_page
  .tabinnercontent
  .currency_sec
  .currency_card
  .midsec
  .listsec
  .leftsec
  .desccoin {
  margin-bottom: 0px;
  font-size: 14px;
}
.accounts_page .tabinnercontent .currency_sec .cur_row {
  justify-content: center;
}
.accounts_page .tabinnercontent .currency_sec .topselectsec {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.account__subTitle {
  font-weight: 600;
  color: var(--black);
  font-size: 20px;
  text-align: center;
  margin-bottom: 0px;
}
/* manoj css end */


/* media */

@media (min-width: 576px) {
  .card_sec .feature_product_card {
    padding: 20px !important;
  }

  .coupon_img_wraper {
    min-width: 130px;
    width: 130px;
    max-width: 130px;
    min-height: 130px;
    height: 130px;
    max-height: 130px;
  }
  .title_md {
    font-size: 19px;
  }
}

@media (min-width: 1200px) {
  .dash_header {
    width: calc(100% - var(--sidebar_lg));
    margin-left: var(--sidebar_lg);
  }
  .width_less {
    width: calc(100% - var(--sidebar_lg));
    margin-left: var(--sidebar_lg);
    transition: 0.3s;
  }
  .width_full {
    width: calc(100% - var(--sidebar_xs));
    margin-left: var(--sidebar_xs);
    transition: 0.3s;
  }
  .wishlist_card {
    max-width: 250px;
  }
}
@media (min-width: 1400px) {
  .coupon_img_wraper {
    min-width: 120px;
    width: 120px;
    max-width: 120px;
    min-height: 120px;
    height: 120px;
    max-height: 120px;
  }
  .fw_black_sm {
    font-weight: 500;
    font-size: 15px;
  }
  .gray_text_sm {
    color: var(--grayClr6);
    font-size: 12px;
  }
  .coupon_right {
    padding: 10px 3px;
  }
  .wishlist_card {
    max-width: 280px;
  }
  .wish_image_wrap {
    min-width: 160px;
    width: 160px;
    max-width: 160px;
    min-height: 160px;
    height: 160px;
    max-height: 160px;
  }
}
@media (min-width: 1600px) {
  .coupon_img_wraper {
    min-width: 130px;
    width: 130px;
    max-width: 130px;
    min-height: 130px;
    height: 130px;
    max-height: 130px;
  }
  .fw_black_sm {
    font-weight: 500;
    font-size: 16px;
  }
  .gray_text_sm {
    color: var(--grayClr6);
    font-size: 13px;
  }
  .coupon_right {
    padding: 10px 3px;
  }
  .wish_image_wrap {
    min-width: 170px;
    width: 170px;
    max-width: 170px;
    min-height: 170px;
    height: 170px;
    max-height: 170px;
  }
  .wishlist_card {
    max-width: 290px;
  }
}

@media (min-width: 1800px) {
  .wishlist_card {
    max-width: 320px;
  }
}

@media (min-width: 1920px) {
  .col-xxxl-1 {
    flex: 0 0 8.333333% !important;
    max-width: 8.333333% !important;
  }

  .col-xxxl-2 {
    flex: 0 0 16.666667% !important;
    max-width: 16.666667% !important;
  }
  .col-xxxl-2_5 {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }

  .col-xxxl-3 {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }

  .col-xxxl-4 {
    flex: 0 0 33.333333% !important;
    max-width: 33.333333% !important;
  }

  .col-xxxl-5 {
    flex: 0 0 41.666667% !important;
    max-width: 41.666667% !important;
  }

  .col-xxxl-6 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .col-xxxl-7 {
    flex: 0 0 58.333333% !important;
    max-width: 58.333333% !important;
  }

  .col-xxxl-8 {
    flex: 0 0 66.666667% !important;
    max-width: 66.666667% !important;
  }

  .col-xxxl-9 {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }

  .col-xxxl-10 {
    flex: 0 0 83.333333% !important;
    max-width: 83.333333% !important;
  }

  .col-xxxl-11 {
    flex: 0 0 91.666667% !important;
    max-width: 91.666667% !important;
  }

  .col-xxxl-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .wishlist_card {
    max-width: 320px;
  }
  .wish_image_wrap {
    min-width: 160px;
    width: 160px;
    max-width: 160px;
    min-height: 160px;
    height: 160px;
    max-height: 160px;
  }
}

@media (max-width: 450px) {
  .coupon_card {
    display: flex;
    flex-direction: column !important;
  }
  .coupon_middle {
    width: 100%;
    height: 28px;
  }
  .coupon_vertical {
    border-left: 0px;
    border-bottom: 1px dashed var(--grayClr5);
    left: 0%;
    top: -50%;
  }
  .cut_lottie {
    transform: rotate(90deg);
    bottom: 0;
    animation: cutter 4s linear infinite;
  }
  @keyframes cutter {
    0% {
      left: 0;
    }
    50% {
      left: 50%;
    }
    100% {
      left: 93%;
    }
  }
}
